import { useCallback, useEffect, useState } from 'react';
import { OAImage, OASwipeableDrawer, OATextField } from '../../components';
import OAAutoCompleteField from '../../components/basic/OAAutoComplete';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from '../../store';
import { debounce } from 'lodash';
import {
  clearAirportSuggestions,
  getAirportSearchState,
  setAirportSuggestions,
} from '../../store/slices/airportSearchSlice';
import { useAirportDataQuery, useAirportSearchMutation } from '../../services/airportDataApi';
import posthog from 'posthog-js';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { theme } from '../../config';

interface SearchAirportsProps {
  label: string;
  title: string;
  fieldId: string;
  value: any;
  onSelect: any;
  isEditing?: boolean;
  localTravelDetail: any;
  orgCode?: string | undefined;
  setScrollTop: any;
}

const SearchAirports = ({
  label,
  title,
  fieldId,
  value,
  onSelect,
  localTravelDetail,
  orgCode,
  setScrollTop,
}: SearchAirportsProps) => {
  const dispatch = useDispatch();
  const { airportSuggestions } = useSelector(getAirportSearchState);
  const [airportSearch] = useAirportSearchMutation();

  const { data } = useAirportDataQuery({});
  const [inputValue, setInputValue] = useState('');
  const [startTime, setStartTime] = useState(Date.now());

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const toggleDrawer = () => {
    setScrollTop && setScrollTop(false);
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Home',
        ctaAction: 'CitySearch',
        screenDuration: totalScreenDuration?.toString(),
        UserAction: {
          userAction: `User has clicked the ${
            fieldId == 'departure' ? 'Departure' : 'Arrival'
          } Text Field`,
        },
      },
      posthog
    );
    setIsOpen(!isOpen);
    setInputValue('');
    if (isOpen) {
      dispatch(clearAirportSuggestions());
    }
  };

  const toggleDrawerClose = () => {
    setIsOpen(false);
    setInputValue('');
    if (isOpen) {
      dispatch(clearAirportSuggestions());
    }
    setScrollTop && setScrollTop(true);
  };
  const handleSelect = (airport: any) => {
    const value = {
      [label?.toLocaleLowerCase()]: {
        iata: airport?.iata,
        name: airport?.name,
        city: airport?.city,
      },
    };

    onSelect({
      ...localTravelDetail,
      ...value,
    });
    setIsOpen(false);
  };

  const debouncedFetchAirportSuggestions = useCallback(
    debounce(async (value) => {
      if (value.trim() !== '') {
        await airportSearch(value)
          .unwrap()
          .then((res: any) => {
            dispatch(setAirportSuggestions(res?.data));
          });
      }
    }, 200),
    [dispatch]
  );

  const handleFetchAirportSuggestions = (value: any) => {
    debouncedFetchAirportSuggestions(value);
  };

  const labelContent = (orgCode: string | undefined) => {
    return (
      <Box id={label} display="flex" alignItems="center" sx={{ pointerEvents: 'none' }}>
        {orgCode !== 'ZOLVE' &&
          (fieldId === 'departure' ? (
            <OAImage src="flight-take-off.svg" alt="Take Off" folder="icons" sx={{ mr: '10px' }} />
          ) : (
            <OAImage src="flight-land.svg" alt="Land" folder="icons" sx={{ mr: '10px' }} />
          ))}
        {label}
      </Box>
    );
  };

  const searchAirportLabel = (orgCode?: string) => {
    return (
      <Box display="flex" alignItems="center">
        {orgCode !== 'ZOLVE' && <OAImage src="location.svg" alt="location" folder="icons" />}
        <Typography
          ml={orgCode !== 'ZOLVE' ? '10px' : '0px'}
          variant="body2"
          color="text.secondary"
        >
          Search for city name or airport code
        </Typography>
      </Box>
    );
  };

  const parts = value?.split(' - ');
  return (
    <>
      <OATextField
        id={label}
        key={value}
        label={labelContent(orgCode)}
        variant={orgCode === 'ZOLVE' ? 'outlined' : 'filled'}
        fullWidth
        focused={isOpen}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <Box display="flex" paddingTop={orgCode === 'ZOLVE' ? '0px' : '16px'} width="100%">
              <Typography
                variant="body1"
                sx={{
                  fontSize: orgCode === 'ZOLVE' ? '16px' : '14px',
                  fontWeight: orgCode === 'ZOLVE' ? 600 : 400,
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                }}
              >
                {`${parts.slice(0, 2).join(' - ')}`}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '12px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color: theme.palette.grey[800],
                  paddingLeft: '10px',
                }}
              >
                {parts[2]}
              </Typography>
            </Box>
          ),
          sx: {
            '& .MuiInputBase-input': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          },
        }}
        onClick={toggleDrawer}
      />
      <OASwipeableDrawer
        title={title}
        open={isOpen}
        onClose={toggleDrawerClose}
        onOpen={toggleDrawer}
        height={96}
        isContainer={false}
        disableScrollLock
      >
        <OAAutoCompleteField
          label={searchAirportLabel(orgCode)}
          fieldId={fieldId}
          sx={{ width: '100%' }}
          onSelect={handleSelect}
          airportSuggestions={airportSuggestions}
          popularSearches={data?.data?.popularSearches}
          fetchAirportSuggestions={handleFetchAirportSuggestions}
          inputValue={inputValue}
          setInputValue={setInputValue}
          isOpen={isOpen}
          orgCode={orgCode}
        />
      </OASwipeableDrawer>
    </>
  );
};

export default SearchAirports;
