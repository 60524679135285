import { useEffect, useState } from 'react';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { OAChip, OACoinBanner, OAImage } from '../../components';
import { format, formatISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { bookingStatusLabels } from '../../constants';
import { displayDateTime } from '../../utils';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { maxBy, minBy } from 'lodash';
import { useSelector } from '../../store';
import { getAuthInfo } from '../../store/slices/authSlice';

interface BookingCardProps {
  data: any;
  selectedBookingsTab?: string;
}

const BookingCard = (props: BookingCardProps) => {
  const posthog = usePostHog();
  const { user } = useSelector(getAuthInfo);
  const navigate = useNavigate();
  const { data } = props;
  const orgCode = user?.organizationCode;
  const [startTime, setStartTime] = useState(Date.now());

  const url = window.location.href;
  const totalTravelers =
    data?.travellerCount?.adult + data?.travellerCount?.child + data?.travellerCount?.infant;
  const isCompleted =
    data?.bookingStatus === 'BOOKING_CONFIRMED' && data?.orderCurrentStatus === 'COMPLETED';
  const bookingStatus = isCompleted ? 'COMPLETED' : data?.bookingStatus;
  let status: any = bookingStatusLabels?.find((obj: any) => bookingStatus === obj?.value);

  const onWardFlights = data?.flights?.find((flight: any) => flight?.departureType === 'ONWARD');

  let departurePlace = null,
    arrivalPlace = null;
  if (onWardFlights) {
    const minObj: any = minBy(onWardFlights?.segments, 'segmentId');
    const maxObj: any = maxBy(onWardFlights?.segments, 'segmentId');
    departurePlace = minObj?.departure?.airport?.city;
    arrivalPlace = maxObj?.arrival?.airport?.city;
  }

  let nonFilteredStatus =
    !status &&
    data?.bookingStatus
      ?.split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

  let tripType = data?.tripType
    ?.split('_')
    ?.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(' ');

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  return (
    <Box
      onClick={() => {
        const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

        eventsTracker(
          {
            flowName: 'Flight',
            screenName: 'Trips',
            ctaAction: 'Trip',
            screenDuration: totalScreenDuration?.toString(),
            extraData: {
              from: data?.flightSearchInfo?.from,
              to: data?.flightSearchInfo?.to,
              departureDate: data?.tripStart,
              numberOfTravellers: totalTravelers,
              class: data?.cabinType,
              type: props?.selectedBookingsTab,
            },
          },
          posthog
        );
        url?.includes('?review')
          ? navigate(`/booking-status/${data?.id}?review`)
          : navigate(`/booking-status/${data?.id}`);
      }}
      sx={{ borderBottom: '4px solid #F4F6F5', p: '16px 20px' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          {status?.label || nonFilteredStatus ? (
            <OAChip
              size="small"
              label={status?.label ?? nonFilteredStatus}
              clickable={false}
              variant="filled"
              color={status?.type ?? 'default'}
              sx={{
                mb: '4px',
                minHeight: '16px',
                '& .MuiChip-label': {
                  fontSize: '0.75rem',
                  fontWeight: orgCode === 'ZOLVE' ? 400 : 600,
                },
              }}
            />
          ) : (
            <Skeleton variant="rectangular" height={'16px'} width={'auto'} />
          )}
          <Box display="flex" alignItems="center">
            {data?.flights?.[0]?.segments?.[0]?.departure?.airport?.city ? (
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {departurePlace}
              </Typography>
            ) : (
              <Skeleton variant="rectangular" height={'16px'} width={'auto'} />
            )}
            {tripType === 'One Way' ? (
              <OAImage src="one-way-arrow.svg" folder="icons" alt="one way" sx={{ mx: '8px' }} />
            ) : (
              <OAImage src="two-way-arrow.svg" folder="icons" alt="two way" sx={{ m: '0 8px' }} />
            )}
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {arrivalPlace}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="body3" sx={{ color: 'text.secondary' }}>
              {tripType === 'One Way'
                ? `${format(data?.tripStartDate, 'd MMM')}`
                : `${format(data?.tripStartDate, 'd MMM')} - ${format(
                    data?.tripEndDate,
                    'd MMM'
                  )}`}{' '}
              &bull; {tripType}
              {data?.travellers?.length > 0 &&
                ` • ${data?.travellers?.[0]?.firstName} ${
                  data?.travellers?.length > 1 ? '+' : ''
                } ${data?.travellers?.length > 1 ? data?.travellers?.length - 1 : ''}`}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Booked on {displayDateTime(formatISO(data?.createdAt))}
            </Typography>
          </Box>
        </Box>
        <Box>
          <IconButton aria-label="close">
            <ChevronRightIcon
              sx={{ color: 'text.secondary' }}
              onClick={() => navigate(`/booking-status/${data?.id}`)}
            />
          </IconButton>
        </Box>
      </Box>

      {data?.paymentSummary?.earnedCoin > 0 &&
        ['BOOKING_IN_PROGRESS', 'BOOKING_CONFIRMED', 'COMPLETED'].includes(data?.bookingStatus) && (
          <OACoinBanner
            title={
              data?.bookingStatus === 'BOOKING_CONFIRMED' &&
              data?.orderCurrentStatus === 'COMPLETED'
                ? 'Niyo coins earned on this trip'
                : 'Niyo coins to be earned on this trip'
            }
            subTitle={data?.paymentSummary?.earnedCoin}
            isCurrency={false}
            sx={{ mt: '10px' }}
          />
        )}
    </Box>
  );
};

export default BookingCard;
