import { Box, Card, Step, StepLabel, Stepper, Typography } from '@mui/material';

const StatusCircle = ({ color }: { color: string }) => (
  <Box
    sx={{
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: color,
    }}
  />
);

const HOWITWORK = [
  'You request for cancellation',
  'Our support team reaches out to you',
  'You confirm the cancellation',
  'Cancellation for booking is initiated',
];

const RefundHowItWork = () => {
  return (
    <Card>
      <Box>
        <Stepper activeStep={0} orientation="vertical">
          {HOWITWORK?.map((obj, index) => (
            <Step key={index}>
              {' '}
              <StepLabel
                StepIconProps={{ style: { color: 'green', fontSize: '12px' } }}
                icon={<StatusCircle color="#49A27A" />}
              >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2">{obj}</Typography>
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Card>
  );
};

export default RefundHowItWork;
