import React from 'react';
import { Box, Button, ButtonGroup, Card, Typography, styled } from '@mui/material';
import { OAImage } from './OAImage';
import { CheckIcon, MinusIcon, NonVegIcon, PlusIcon, VegIcon } from './SVG';

// Define an interface for the props you expect
interface StyledButtonProps {
  maxWidth?: string;
}

// Use the interface and apply the maxWidth in the styling
const StyledButton = styled(Button)<StyledButtonProps>(({ maxWidth }) => ({
  width: '115px',
  backgroundColor: 'white !important',
  color: 'black !important',
  border: '1px solid black',
  maxWidth: maxWidth, // Apply the maxWidth from props
  '&:hover': {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'none !important',
  },
  '&:disabled': {
    backgroundColor: 'white',
    color: 'black',
     boxShadow: 'none !important',
  },
}));

interface ItemCardProps {
  cardType: 'baggage' | 'meal';
  code: string;
  description: string;
  price: number;
  mealType?: 'Veg' | 'Non-Veg';
  baggageType?: 'CHECKIN_BAGGAGE';
  paxListType: string[];
  refundable: boolean;
  travellersCount: number;
  onAdd: () => void;
  onRemove: () => void;
  isSelected: boolean;
  selectedCount: number;
}

const ItemCard: React.FC<ItemCardProps> = ({
  cardType,
  code,
  description,
  price,
  mealType,
  baggageType,
  travellersCount,
  onAdd,
  onRemove,
  isSelected,
  selectedCount,
}) => {
  return (
    <Card>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" mr="16px">
          <Box sx={{ position: 'relative' }} width='60px' height='60px'>
            <OAImage
              src={cardType === 'meal' ? 'meals-placeholder.svg' : 'baggage-placeholder.svg'}
              alt="meal"
              sx={{ mr: '5px', height: '60px', width: '60px', maxWidth: '60px' }}
            />

            {mealType && (
              <Box sx={{ position: 'absolute', bottom: 0, right: '6px' }}>
                {mealType === 'Non-Veg' ? <NonVegIcon /> : <VegIcon />}
              </Box>
            )}
          </Box>
          <Box ml="8px">
            <Typography variant="body2">{description}</Typography>
            <Typography mt="8px" fontWeight={600}>
              ₹{price?.toLocaleString('en-IN')}
            </Typography>
          </Box>
        </Box>
        <Box>
          {travellersCount === 1 ? (
            <StyledButton
              variant="contained"
              size="small"
              onClick={isSelected && selectedCount === 1 ? onRemove : onAdd}
              startIcon={isSelected && selectedCount === 1 ? <CheckIcon /> : null}
              sx={{bgcolor: 'white !important'}}
              disableRipple
            >
              {isSelected && selectedCount === 1 ? 'Added' : 'Add'}
            </StyledButton>
          ) : selectedCount > 0 && travellersCount > 1 ? (
            <ButtonGroup size="small" sx={{ width: '115px' }}>
              <StyledButton
                size="small"
                variant="contained"
                onClick={onRemove}
                disabled={selectedCount === 0}
                maxWidth="32px"
                sx={{borderRight: 'none'}}
                disableRipple
              >
                <MinusIcon />
              </StyledButton>
              <StyledButton size="small" 
                sx={{borderRight: 'none', borderLeft: 'none'}}
              variant="contained" maxWidth="30px">
                {selectedCount}
              </StyledButton>
              <StyledButton
                size="small"
                variant="contained"
                onClick={onAdd}
                disabled={selectedCount >= travellersCount}
                maxWidth="32px"
                sx={{borderLeft: 'none'}}
                disableRipple
              >
                <PlusIcon />
              </StyledButton>
            </ButtonGroup>
          ) : (
            <StyledButton 
              disableRipple
             variant="contained" size="small" onClick={onAdd}>
              Add
            </StyledButton>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default ItemCard;
