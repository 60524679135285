// checkFlights.ts
import { addMinutes, isAfter, isBefore, parse } from 'date-fns';

export const checkFlights = (
  onwardStart: any,
  onwardEnd: any,
  returnStart: any,
  returnEnd: any
) => {
  let minReturnStart = addMinutes(onwardEnd, 60);
  if (isAfter(onwardEnd, returnStart) || isBefore(returnStart, onwardEnd)) {
    return {
      error: 'Selected flights are overlapping. Please change the selection',
    };
  } else if (isAfter(onwardStart, returnEnd)) {
    return {
      error: 'Selected flights are overlapping. Please change the selection',
    };
  } else if (isBefore(returnStart, minReturnStart)) {
    return {
      error: 'Selected flights have less than 60 mins between onward/return journey. Please change the selection',
    };
  } else {
    return null;
  }
};