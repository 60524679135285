import { FC, useState } from 'react';
import { Box, Container } from '@mui/material';
import Sort from './Sort';
import Filter from './Filter';
import { OAChip, OALoadingOverlay } from '../../../components';
import { getFilterState, getSearchFlightState } from '../../../store/slices/searchFlightSlice';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

interface FilterAndSortProps {
  filterData: any;
  flightsCount: any;
  countOfStops: any;
  countOfAirports: any;
  countOfDeparture: any;
  countOfReturnDeparture: any;
  currentSortTab: number;
  setFilterData: (values: any) => void;
  onClickSort: (value: string) => void;
  onClickClearSort: () => void;
  onClickFilter: (filterData: any) => void;
  onClickClearFilter: () => void;
  setCurrentSortTab: (value: number) => void;
  orgCode: string;
}
const FilterAndSort: FC<FilterAndSortProps> = ({
  filterData,
  flightsCount,
  countOfStops,
  countOfAirports,
  countOfDeparture,
  countOfReturnDeparture,
  currentSortTab,
  setFilterData,
  onClickSort,
  onClickClearSort,
  onClickFilter,
  onClickClearFilter,
  setCurrentSortTab,
  orgCode,
}) => {
  const { data } = useSelector(getSearchFlightState);
  const filterValue = useSelector(getFilterState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleNonStop = () => {
    setIsLoading(true);
    const updatedFilter = { ...filterValue };
    const hasStops = Array.isArray(updatedFilter.stops);
    const includesNonStop = hasStops && updatedFilter.stops.includes('0');

    if (includesNonStop) {
      // Remove '0' if it exists
      updatedFilter.stops = updatedFilter.stops.filter((stop: any) => stop !== '0');
      // Delete the stops key if it's empty
      if (updatedFilter.stops.length === 0) {
        delete updatedFilter.stops;
      }
    } else {
      // Add '0' to stops or create stops with ['0']
      updatedFilter.stops = hasStops ? [...updatedFilter.stops, '0'] : ['0'];
    }

    // If updatedFilter is empty after modification, clear filter
    // Otherwise, apply the updated filter
    isEmpty(updatedFilter) ? onClickClearFilter() : onClickFilter(updatedFilter);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <>
      {isLoading && <OALoadingOverlay />}
      <Container sx={{ py: '8px', overflow: 'hidden', px: 0, borderBottom: '1px solid #E2E2E2' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            overflowX: 'auto',
            msOverflowStyle: 'none', // Correct syntax for the vendor-prefixed CSS properties
            scrollbarWidth: 'none', // Firefox
            '&::-webkit-scrollbar': {
              display: 'none', // Chrome, Safari, Opera
            },
          }}
        >
          <Box mr="8px">
            <Sort
              currentSortTab={currentSortTab}
              setCurrentSortTab={setCurrentSortTab}
              onClick={onClickSort}
              onClickClearSort={onClickClearSort}
              orgCode={orgCode}
            />
          </Box>
          <Box mr="8px">
            <Filter
              filterData={filterData}
              setFilterData={setFilterData}
              onClick={onClickFilter}
              onClickClearFilter={onClickClearFilter}
              filtersCount={flightsCount}
              countOfStops={countOfStops}
              countOfAirports={countOfAirports}
              orgCode={orgCode}
            />
          </Box>
          <OAChip
            label="Non-stop"
            sx={{
              bgcolor:
                orgCode === 'ZOLVE'
                  ? filterValue?.stops?.includes('0')
                    ? '#141414 !important'
                    : 'white !important'
                  : 'primary.light',
              color:
                orgCode === 'ZOLVE'
                  ? filterValue?.stops?.includes('0')
                    ? 'white'
                    : '#757575'
                  : 'black',
              borderColor:
                orgCode === 'ZOLVE'
                  ? filterValue?.stops?.includes('0')
                    ? 'black'
                    : '#E2E2E2'
                  : 'primary.main',
            }}
            onClick={toggleNonStop}
            selected={filterValue?.stops?.includes('0')}
            disabled={data?.meta && data?.results?.onward?.length > 0 ? false : true}
          />
        </Box>
      </Container>
    </>
  );
};

export default FilterAndSort;
