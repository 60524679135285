import { Box, Typography } from '@mui/material';
const iso3311a2 = require('iso-3166-1-alpha-2');

const AirportSuggestions = ({ index, handleSuggestionClick, suggestion }: any) => {
  return (
    <Box
      key={index}
      sx={{ display: 'flex', alignItems: 'center', py: '12px', mb: '10px' }}
      onClick={() => handleSuggestionClick(suggestion)}
    >
      <Typography
        variant="body3"
        sx={(theme) => ({
          bgcolor: theme.palette.primary.light,
          mr: '8px',
          borderRadius: '4px',
          textAlign: 'center',
          padding: '4px 6px',
          border: `1px solid ${theme.palette.primary.main}`,
          width: '30px',
          fontWeight: 600,
        })}
      >
        {suggestion?.iata}
      </Typography>
      <Box>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {suggestion?.city}, {iso3311a2?.getCountry(suggestion?.country)}
        </Typography>
        <Typography
          variant="body3"
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: 'text.secondary',
          }}
        >
          {suggestion?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default AirportSuggestions;
