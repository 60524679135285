import { Box, Typography } from '@mui/material';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';

interface FloatingLayoverProps {
  segLayovers: any;
}

const FloatingLayover: React.FC<FloatingLayoverProps> = ({ segLayovers }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      position="absolute"
      zIndex={9}
      bottom="-40%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        borderRadius="22px"
        bgcolor="#F4F6F5"
        p="5px 15px"
        border="1px solid #DEE2E1"
      >
        <AirlineStopsIcon
          fontSize="small"
          sx={{ mr: '4px', color: '#5A6068', height: '16px', width: '16px' }}
        />
        <Typography variant="body3" color="#5A6068">
          Layover &bull; {segLayovers?.duration?.display}
        </Typography>
      </Box>
    </Box>
  );
};

export default FloatingLayover;
