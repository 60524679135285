import Slider, { LazyLoadTypes } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Typography } from '@mui/material';
import { useSelector } from '../../store';
import { getAuthInfo } from '../../store/slices/authSlice';
import { getFeatureFlag } from '../../store/slices/featuresSlice';

const BASE_URL_BANNER = 'https://oa-flight-assets.s3.ap-south-1.amazonaws.com/offer';

const OffersBenefits = () => {
  const { campaigns } = useSelector(getAuthInfo);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);
  const eligibleCampaigns = campaigns?.eligibleCampaigns;
  const campaignRewardType = eligibleCampaigns?.[0]?.campaignRewardType;

  const SETTING = {
    dots: ['NXREWARD', 'FIXED']?.includes(campaignRewardType) && burnCoinsFeat ? true : false,
    infinite: ['NXREWARD', 'FIXED']?.includes(campaignRewardType) && burnCoinsFeat ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  };

  return (
    <Box sx={{ mt: '30px', overflowX: 'hidden' }}>
      <Typography variant="body1" sx={{ fontWeight: 600, mb: '20px', ml: '20px' }}>
        Offers & benefits
      </Typography>
      <Box className="slider-container" sx={{ mb: '30px' }}>
        <Slider {...SETTING}>
          {burnCoinsFeat && <CustomSlide banner="burn-75p.svg" />}
          {campaignRewardType === 'NXREWARD' && <CustomSlide banner="earn-30p.svg" />}
          {campaignRewardType === 'FIXED' && <CustomSlide banner="earn-10k.svg" />}
        </Slider>
      </Box>
    </Box>
  );
};

export default OffersBenefits;

const CustomSlide = ({ banner }: any) => {
  return (
    <Box sx={{ mx: '20px' }}>
      <img
        src={`${BASE_URL_BANNER}/${banner}`}
        style={{
          width: '100%',
          display: 'block',
          height: 'auto',
        }}
        alt="banner"
      />
    </Box>
  );
};
