import { FC } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { formatToINR } from '../utils';
import { OAImage } from './OAImage';

interface OACoinBannerProps {
  title: string;
  subTitle: number;
  isCurrency: boolean;
  sx?: SxProps;
  color?: 'primary' | 'yellow' | 'error';
}
export const OACoinBanner: FC<OACoinBannerProps> = ({
  title,
  subTitle,
  isCurrency,
  sx,
  color = 'yellow',
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        bgcolor: `${color}.light`,
        p: '5px 8px',
        borderRadius: '5px',
        ...sx,
      }}
    >
      <Typography variant="body3" sx={{ color: 'text.primary' }}>
        {title}
      </Typography>
      <Box display="flex" alignItems="center">
        {!isCurrency && (
          <OAImage
            src="3xNiyo_coin.png"
            alt="coins"
            sx={{ mx: '5px', height: '18px', width: '18px' }}
          />
        )}
        <Typography variant="body3" sx={{ fontWeight: 600, color: 'text.primary' }}>
          {formatToINR(subTitle, isCurrency)}
        </Typography>
      </Box>
    </Box>
  );
};
