import { FC, useEffect, useState } from 'react';
import OATabs from './components/OATabs';
import { Box } from '@mui/material';
import BottomBar from './components/BottomBar';
import { usePostHog } from 'posthog-js/react';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { getCurrentTabString } from '../../utils';

interface OASMBProps {
  selectedTab: 'Seat' | 'Meal' | 'Baggage';
  tabValue: number;
  setTabValue: (value: number) => void;
  smbData: any;
  totalTravellers: number;
  updateSmbAction: any;
  isDiscountApplied: boolean;
  totalSelectedFlightPrice: number;
  discountPrice: number;
  selectedSmbData: any;
  segmentBasedTabs: any;
  selectedSegment: { from: string; to: string };
  setSelectedSegment: any;
  onClickHandler: () => void;
}

const OASMB: FC<OASMBProps> = ({
  tabValue,
  setTabValue,
  smbData,
  totalTravellers,
  updateSmbAction,
  isDiscountApplied,
  totalSelectedFlightPrice,
  discountPrice,
  selectedSmbData,
  segmentBasedTabs,
  selectedSegment,
  setSelectedSegment,
  onClickHandler,
}) => {
  const posthog = usePostHog();
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleContinue = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SMB',
        ctaAction: getCurrentTabString(tabValue) + 'Continue',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );

    if (tabValue === 3) {
      onClickHandler();
    } else {
      const newTabValue = (currentTabValue: number) => {
        if (currentTabValue === 1) {
          return 2;
        } else if (currentTabValue === 2) {
          return 3;
        } else {
          return 1;
        }
      };
      setTabValue(newTabValue(tabValue));
    }
  };

  const totalPrice = totalSelectedFlightPrice;

  return (
    <>
      <Box>
        <OATabs
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          setTabValue={setTabValue}
          smbData={smbData}
          updateSmbAction={updateSmbAction}
          selectedSmbData={selectedSmbData}
          totalTravellers={totalTravellers}
          selectedSegment={selectedSegment}
          setSelectedSegment={setSelectedSegment}
          segmentBasedTabs={segmentBasedTabs}
        />
      </Box>
      <BottomBar
        totalPrice={totalPrice}
        addOnType={tabValue === 1 ? 'Seat' : tabValue === 2 ? 'Meal' : 'Baggage'}
        totalAddOnCount={totalTravellers}
        smbData={selectedSmbData}
        discountPrice={discountPrice}
        isDiscountApplied={isDiscountApplied}
        onClick={handleContinue}
        selectedSegment={selectedSegment}
      />
    </>
  );
};

export default OASMB;
