export function formatDate(dateStr: string): string {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };

  return new Intl.DateTimeFormat('en-IN', options)?.format(date)?.replace(',', '');
}

export function displayDateTime(dateStr: string): string {
  const date = new Date(dateStr);
  const day = date?.getDate()?.toString()?.padStart(2, '0');
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date?.getMonth()];
  const year = date?.getFullYear();
  const hour = date?.getHours()?.toString()?.padStart(2, '0');
  const minute = date?.getMinutes()?.toString()?.padStart(2, '0');

  return `${day} ${month}, ${year}, ${hour}:${minute}`;
}