import React, { useEffect } from 'react';
import { Box, Container, Divider, Skeleton, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from '../../../store';
import {
  setCancellationData,
  setSelectedCancelReason,
} from '../../../store/slices/cancellationSlice';
import { useCancellationInfoQuery } from '../../../services/bookingsApi';
import { OAEmpty } from '../../../components';

const CancellationReasons = ({ bookingData, orgCode }: any) => {
  const navigate = useNavigate();
  const { data, isError } = useCancellationInfoQuery<any>(bookingData?.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.data) {
      dispatch(setCancellationData(data.data));
    }
  }, [data, dispatch]);

  const handleSelectCancelReason = (reason: any) => {
    dispatch(setSelectedCancelReason(reason));
    navigate('cancel');
  };

  const renderSkeletons = () => (
    <>
      {Array.from(new Array(4)).map((_, index) => (
        <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Skeleton variant="text" width="100%" height="30px" />
        </Box>
      ))}
    </>
  );

  const renderError = () => <OAEmpty bodytext="Uh-oh..something went wrong" orgCode={orgCode} />;

  return (
    <Container sx={{ my: '20px' }}>
      {isError
        ? renderError()
        : !data?.data?.reasons
        ? renderSkeletons()
        : data.data.reasons.map((reason: any, index: number) => (
            <React.Fragment key={index}>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                onClick={() => handleSelectCancelReason(reason)}
              >
                <Typography>{reason.display}</Typography>
                <ChevronRightIcon
                  fontSize="small"
                  sx={{ color: 'secondary.main', fontWeight: 300 }}
                />
              </Box>
              {index !== data.data.reasons.length - 1 && (
                <Divider sx={{ borderBottomWidth: '4px', borderColor: '#F4F6F5', my: '20px' }} />
              )}
            </React.Fragment>
          ))}
    </Container>
  );
};

export default CancellationReasons;
