import Lottie from 'react-lottie';
import animationData from './zolve_plane_animation.json';
import { Box, Typography } from '@mui/material';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const OALoadingSearchLottie = () => {
  return (
    <Box
      sx={{
        position: 'absolute', // Make the box position absolute
        top: '50%', // Set top to 50% of the parent
        left: '50%', // Center align horizontally
        transform: 'translate(-50%, -50%)', // Adjust position to truly center
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Lottie options={defaultOptions} height="auto" width="100%" />
      <Typography variant="body2" sx={{ fontWeight: 600, mt: '8px' }}>
        Fasten your seatbelts
      </Typography>
      <Typography variant="body3" sx={{ color: 'text.secondary', mt: '2px' }}>
        Landing best deals for you!
      </Typography>
    </Box>
  );
};
