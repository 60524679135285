
export interface EventTracking {
  flowName: string;
  screenName: string;
  ctaAction: string;
  screenDuration: number;
  otherData?: Record<string, any>;
}

export const HANDLER_NAMES = {
  GET_NIYO_COINS: "eventCoins",
  OPEN_PAYMENT: "eventPaymentGateway",
  REDEEM_COINS: "eventRedeemCoins",
  EARN_COINS: "eventEarnCoins",
  COIN_TO_RUPEE: "eventCoinToRupee",
  RUPEE_TO_COIN: "eventRupeeToCoin",
  USER_LOGIN: "eventLogin",
  LOCATION: "eventLocation",
  FRESH_CHAT: "eventFreshChat",
  NIYO_OFFERS: "eventOffers",
  REWARD_SCREEN: 'eventOpenRewardScreen',
  PAYMENT_CALLBACK: 'eventPaymentCallback',
  OPEN_URL: 'eventOpenUrl',
  CT_EVENT_TRACKING: 'eventCtEventTracking',
  REFRESH_TOKEN:'eventRefreshToken',
  CANCEL_CURRENT_TRANSACTION: 'eventCancelCurrentTransaction',
  EVENT_LOG_ERROR: 'eventLogError',
  DOWNLOAD_FILE: 'eventDownload',
  INITIALISE_REDIRECT : 'eventInitialRedirect',
  RETURN_TEXT: 'returnText', // Added for Swift bridge
  DEVICE_INFO: 'eventDeviceInfo'
};


function createHandler(eventName: string) {
  return (data: any = {}) => {
    if (window.flutter_inappwebview) {
      return window.flutter_inappwebview
        .callHandler(eventName, data)
        .then((response: any) => {
          console.log(`Received from Dart for ${eventName}: `, JSON.stringify(response));
          return response;
        })
        .catch((error: any) => {
          console.error(`Error calling ${eventName} handler:`, error);
        });
    } else if (window.webkit?.messageHandlers?.iosHandler) {
      console.log('iOS handler detected');
      return new Promise((resolve, reject) => {
        const responseId = Date.now().toString(); // Generate a unique response ID
        const message = {
          eventName: eventName,
          data: data,
          responseId: responseId,
        };
        window.webkit?.messageHandlers.iosHandler.postMessage(JSON.stringify(message));

        const handleMessage = (event: MessageEvent) => {
          const { eventName: responseEventName, data: responseData, responseId: receivedResponseId } = event.data;
          if (responseEventName === `${eventName}Response` && receivedResponseId === responseId) {
            console.log("ios response"+responseData)
            window.removeEventListener('message', handleMessage);
            resolve(responseData);
          }
        };
        window.addEventListener('message', handleMessage);
      });
    }
    else if (window.androidHandler) {
      console.log('Android handler detected v2');
      return new Promise((resolve, reject) => {
        const responseId = Date.now().toString(); // Generate a unique response ID
        const message = {
          eventName: eventName,
          data: data,
          responseId: responseId,
        };
        window?.androidHandler?.handleEvent(JSON.stringify(message));

        const handleMessage = (event: MessageEvent) => {
          const { eventName: responseEventName, data: responseData, responseId: receivedResponseId } = JSON.parse(event.data);
          if (responseEventName === `${eventName}Response` && receivedResponseId === responseId) {
            console.log("Android response:", responseData);
            window.removeEventListener('message', handleMessage);
            resolve(responseData);
          }
        };
        window.addEventListener('message', handleMessage);
      });
    }
    else {
      console.error("Neither flutter_inappwebview nor iosHandler is available.");
      return Promise.reject("No handler is available.");
    }
  };
}

declare global {
  interface Window {
    flutter_inappwebview: {
      callHandler: (handlerName: string, ...args: any[]) => Promise<any>;
    };

    webkit?: {
      messageHandlers: {
        iosHandler: {
          postMessage(message: string):Promise<any>
        };
      };
    };

    androidHandler?: {
      handleEvent(message: string):Promise<any>
    };

    handleBackButton: () => void;
    oaBack: () => void;
    // Flutter event handlers
    niyo_coins: () => Promise<any>;
    niyo_user_login: () => Promise<any>;
    back: () => Promise<any>;
    niyo_paymentGateway: (data: any) => Promise<any>;
    niyo_redeemCoins: () => Promise<any>;
    niyo_earnCoins: () => Promise<any>;
    niyo_coinToRupee: () => Promise<any>;
    niyo_rupeeToCoin: () => Promise<any>;
    niyo_location: () => Promise<any>;
    niyo_fresh_chat: (data: any) => void;
    niyo_offers: () => Promise<any>;
    niyo_reward_screen: () => void;
    oa_payment_callback: (data: any) => void;
    oa_client_redirect_to: (data: any) => void;
    niyo_open_url: (url: string) => void;
    niyo_ct_event_tracking: (data: any) => void;
    niyo_refresh_token: () => Promise<any>;
    niyo_cancel_current_transaction: () => void;
    niyo_event_log_error: (data: any) => void;
    niyo_download: (url: string) => void;
    niyo_initial_redirect: () => void;
    niyo_return_text: (text: String) => Promise<any>;
    niyo_deviceInfo: () => Promise<any>;
  }
}


const windowFunctions = {
  //implement this function in flutter
  niyo_coins: createHandler(HANDLER_NAMES.GET_NIYO_COINS),
  niyo_paymentGateway: createHandler(HANDLER_NAMES.OPEN_PAYMENT),
  niyo_redeemCoins: createHandler(HANDLER_NAMES.REDEEM_COINS),
  niyo_earnCoins: createHandler(HANDLER_NAMES.EARN_COINS),
  niyo_coinToRupee: createHandler(HANDLER_NAMES.COIN_TO_RUPEE),
  niyo_rupeeToCoin: createHandler(HANDLER_NAMES.RUPEE_TO_COIN),
  niyo_user_login: createHandler(HANDLER_NAMES.USER_LOGIN),
  niyo_location: createHandler(HANDLER_NAMES.LOCATION),
  niyo_fresh_chat: createHandler(HANDLER_NAMES.FRESH_CHAT),
  niyo_offers: createHandler(HANDLER_NAMES.NIYO_OFFERS),
  niyo_reward_screen: createHandler(HANDLER_NAMES.REWARD_SCREEN),
  niyo_open_url: createHandler(HANDLER_NAMES.OPEN_URL),
  niyo_ct_event_tracking: createHandler(HANDLER_NAMES.CT_EVENT_TRACKING),
  niyo_refresh_token: createHandler(HANDLER_NAMES.REFRESH_TOKEN),
  niyo_cancel_current_transaction: createHandler(HANDLER_NAMES.CANCEL_CURRENT_TRANSACTION),
  niyo_event_log_error: createHandler(HANDLER_NAMES.EVENT_LOG_ERROR),
  niyo_download: createHandler(HANDLER_NAMES.DOWNLOAD_FILE),
  niyo_initial_redirect: createHandler(HANDLER_NAMES.INITIALISE_REDIRECT),
  niyo_return_text: createHandler(HANDLER_NAMES.RETURN_TEXT), // Added for Swift bridge
  niyo_deviceInfo: createHandler(HANDLER_NAMES.DEVICE_INFO),
  back: createHandler("back"),
};
export const initializeWindowFunctions = (): void => {
  console.log('Window functions initalization.');
  Object.assign(window, windowFunctions);
  console.log('Window functions init complete.');
  // for testing only
  // windowFunctions?.niyo_return_text('hello new')
  //   .then((response: any) => {
  //     console.log('Received response from Swift:', response);
  //     // Handle the response as needed
  //   })
  //   .catch((error: any) => {
  //     console.error('Error calling returnText handler:', error);
  //   });
};

export const cleanupWindowFunctions = (): void => {
  Object.keys(windowFunctions).forEach((func) => {
    delete window[func as keyof Window];
  });
};