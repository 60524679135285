import { isMobileWebview } from './isMobileWebview';

export const eventLogError = (eventData: any, posthogEventData: any, PostHog: any) => {
  if (
    isMobileWebview() &&
    window?.niyo_event_log_error &&
    typeof window.niyo_event_log_error === 'function'
  ) {
    const stringifiedEventData = JSON.stringify(eventData);
    console.log('Error Log!', stringifiedEventData);
    window.niyo_event_log_error(stringifiedEventData);
    if (window.location.href.includes('goniyo.com')) {
      PostHog.capture(
        posthogEventData?.screenName + '_' + posthogEventData?.ctaAction,
        posthogEventData
      );
    }
  }
};
