import { Box, Card, Typography } from '@mui/material';
import { OAButton } from '../basic/OAButton';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { OAImage } from '../OAImage';
import { useDispatch, useSelector } from '../../store';
import { setMessage } from '../../store/slices/snackbarSlice';
import { getAuthInfo } from '../../store/slices/authSlice';

const PnrDisplay = ({ pnrDetails }: any) => {
  const dispatch = useDispatch();
  const { user } = useSelector(getAuthInfo);
  const posthog = usePostHog();
  const [startTime, setStartTime] = useState(Date.now());
  const orgCode = user?.organizationCode;
  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const copyToClipboard = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Booking',
        ctaAction: 'copy PNR',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );

    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(pnrDetails);
      dispatch(setMessage('Copied to clipboard'));
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = pnrDetails;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        dispatch(setMessage('Copied to clipboard'));
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }

      document.body.removeChild(textArea);
    }
  };
  return (
    <>
      {pnrDetails && pnrDetails !== '' ? (
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography variant="h3" sx={{ fontSize: '16px' }}>
              {pnrDetails}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '12px' }}>
              PNR
            </Typography>
          </Box>
          <OAButton
            onClick={copyToClipboard}
            size="small"
            sx={{
              color: '#fff',
              backgroundColor: orgCode === 'ZOLVE' ? 'primary.main' : '#0A1017',
              '&&:hover': {
                backgroundColor: orgCode === 'ZOLVE' ? 'primary.main' : '#0A1017',
              },
              '&&:focus': {
                backgroundColor: orgCode === 'ZOLVE' ? 'primary.main' : '#0A1017',
              },
              display: 'flex',
              width: 'fit-content',
            }}
          >
            <Box display="flex" alignItems="center">
              <OAImage src="copy.svg" folder="icons" alt="copy" />
              <Typography variant="body3" sx={{ color: '#fff', paddingLeft: '2px' }}>
                Copy
              </Typography>
            </Box>
          </OAButton>
        </Card>
      ) : (
        <Box sx={{ paddingTop: '16px', paddingBottom: '12px' }}>
          <Typography variant="body3" my="20px" textAlign="center" color="gray">
            Please wait while we generate your PNR
          </Typography>
        </Box>
      )}
    </>
  );
};

export default PnrDisplay;
