import { OAChip, OAImage } from '../../components';
import { Box, Skeleton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCoins } from '../../store/slices/userInfoSlice';
import { formatToINR, isMobileWebview, parseObject } from '../../utils';
import { useEffect, useState } from 'react';
import { getAuthInfo } from '../../store/slices/authSlice';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { TEST_COINS_WEB } from '../../constants';

export const HeaderActionFlight = () => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, token } = useSelector(getAuthInfo);
  const { burnCoinsFeat, earnCoinsFeat } = useSelector(getFeatureFlag);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [coinsLocal, setCoinsLocal] = useState<number>(0);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        if (isMobileWebview() && window?.niyo_coins && typeof window.niyo_coins === 'function') {
          const res = await window.niyo_coins();
          const resParse = parseObject(res);
          const coinsBalance = resParse?.coinsBalance ?? 0;
          setCoinsLocal(coinsBalance);
          dispatch(setCoins(burnCoinsFeat ? coinsBalance : 0));
        } else {
          setCoinsLocal(TEST_COINS_WEB);
          dispatch(setCoins(TEST_COINS_WEB));
        }
      } catch (error) {
        setCoinsLocal(0);
        dispatch(setCoins(0));
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoins();
  }, []);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleOpenRewardScreen = () => {
    try {
      if (
        isMobileWebview() &&
        window?.niyo_reward_screen &&
        typeof window.niyo_reward_screen === 'function'
      ) {
        window.niyo_reward_screen();
      } else {
        console.log('only for mobile');
      }
    } catch (error) {
      console.error('Failed to fetch coins:', error);
    } finally {
      console.error('Failed to fetch coins:');
    }
  };

  const isDisabled = !token && !user?.id;

  const chipStyle = {
    px: 0,
    minHeight: '40px',
  };

  const handleMyTrips = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Home',
        ctaAction: 'Trips',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    navigate('/my-bookings');
  };
  const orgCode = user?.organizationCode;

  return (
    <>
      {burnCoinsFeat && isLoading ? (
        <Skeleton
          variant="rectangular"
          height={40}
          width={55}
          sx={{ borderRadius: '18px', mr: '10px' }}
        />
      ) : (
        burnCoinsFeat && (
          <OAChip
            label={
              <Box display="flex" alignItems="center" sx={{ px: '6px' }}>
                <OAImage src="niyo-coin.svg" alt="coins" sx={{ height: '20px', width: '20px' }} />
                <Typography variant="body2" sx={{ ml: '4px' }}>
                  {formatToINR(coinsLocal, false)}
                </Typography>
              </Box>
            }
            sx={{ ...chipStyle, mr: '10px', backgroundColor: 'white !important' }}
            disabled={isDisabled}
            onClick={handleOpenRewardScreen}
          />
        )
      )}
      <OAChip
        label={
          <Box
            display="flex"
            flexDirection={orgCode === 'ZOLVE' ? 'row-reverse' : 'row'}
            alignItems="center"
            sx={{
              pl: orgCode === 'ZOLVE' ? '6px' : '1px',
              pr: orgCode === 'ZOLVE' ? '1px' : '6px',
            }}
          >
            <OAImage
              src="mytrips.svg"
              folder={orgCode === 'ZOLVE' ? 'zolveIcons' : 'icons'}
              alt="trip"
              sx={{ height: '30px', width: '30px', borderColor: '1px solid #EEEEEE' }}
            />
            <Typography
              fontWeight={orgCode === 'ZOLVE' ? 600 : 400}
              variant="body2"
              sx={{
                ml: orgCode === 'ZOLVE' ? '1px' : '4px',
                mr: orgCode === 'ZOLVE' ? '4px' : '1px',
              }}
            >
              My Trips
            </Typography>
          </Box>
        }
        sx={{ ...chipStyle, mr: '0', backgroundColor: orgCode === 'ZOLVE' ? '#F6F6F6' : 'white' }}
        disabled={isDisabled}
        onClick={handleMyTrips}
      />
    </>
  );
};
