import { FC } from 'react';
import { Box, Container, Divider, Skeleton, Typography } from '@mui/material';
import { ITraveller } from '../../../type';

interface TravellerDetailsProps {
  traveller: ITraveller[];
  isLoading: boolean;
}
const TravellerDetails: FC<TravellerDetailsProps> = ({ traveller, isLoading }) => {
  return (
    <>
      <Container sx={{ pt: '20px' }}>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          Traveller
        </Typography>
        {isLoading ? (
          <>
            <Skeleton variant="text" sx={{ fontSize: '16px', width: '100%', py: '8px' }} />
            <Skeleton variant="text" sx={{ fontSize: '16px', width: '100%', py: '8px' }} />
            <Skeleton variant="text" sx={{ fontSize: '16px', width: '100%', py: '8px' }} />
          </>
        ) : (
          traveller?.map((t: ITraveller) => (
            <Box key={t.id} py="8px">
              <Typography variant="body2">
                {t?.fullName}, {t?.age}
              </Typography>
              {t?.studentId && (
                <Typography variant="body3" color="#757575">
                  Student ID: {t?.studentId}
                </Typography>
              )}
            </Box>
          ))
        )}
      </Container>
      <Divider sx={{ borderBottomWidth: '4px', borderColor: '#F4F6F5', my: '20px' }} />
    </>
  );
};

export default TravellerDetails;
