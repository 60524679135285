import { Box, Container, Divider, Skeleton, Typography } from '@mui/material';
import { OAButton, OACheckbox, OAImage } from '../../components';
import { useDispatch, useSelector } from '../../store';
import { getUserInputState } from '../../store/slices/userInputSlice';
import { getFlightOrderState } from '../../store/slices/flightOrderSlice';
import { setMessage } from '../../store/slices/snackbarSlice';
import { useGetTravellersQuery } from '../../services/travellersApi';
import { useCallback, useEffect, useState } from 'react';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';

interface ListProps {
  selectedTravelers: any;
  setSelectedTravelers: any;
  toggleDrawer: () => void;
  setSelectedTraveller: (traveller: any) => void;
  setFieldErrors: any;
  orgCode?: string;
}

const List = ({
  selectedTravelers,
  setSelectedTravelers,
  toggleDrawer,
  setSelectedTraveller,
  setFieldErrors,
  orgCode,
}: ListProps) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const { travelDetail } = useSelector(getUserInputState);
  const { data: travellers, isLoading: loading } = useGetTravellersQuery({});
  const { flightData } = useSelector(getFlightOrderState);
  const [startTime, setStartTime] = useState(Date.now());

  const totalCount =
    flightData?.travellerCount?.adult +
      flightData?.travellerCount?.child +
      flightData?.travellerCount?.infant ||
    travelDetail?.travellerCount?.adult +
      travelDetail?.travellerCount?.child +
      travelDetail?.travellerCount?.infant;

  const getCurrentSelectionCounts = () => {
    return selectedTravelers?.reduce(
      (acc: any, id: string) => {
        const found = travellers?.data?.find((t: any) => t?.id === id);
        if (found) {
          acc[found?.type] = (acc[found?.type] || 0) + 1;
        }
        return acc;
      },
      { ADULT: 0, CHILD: 0, INFANT: 0 }
    );
  };

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleToggleTravellerSelection = useCallback(
    (traveller: any) => {
      const currentTraveller = travellers?.data?.find((t: any) => t?.id === traveller?.id);
      if (!currentTraveller) return;

      if (
        flightData?.flightSearchInfo?.fareGroup === 'STUDENT' &&
        (currentTraveller?.type === 'CHILD' || currentTraveller?.type === 'INFANT')
      ) {
        dispatch(setMessage('Student fares are available for adult passengers only.'));
        return;
      }

      let errors = {};

      if (flightData?.flightSearchInfo?.fareGroup === 'STUDENT' && !currentTraveller?.studentId) {
        errors = {
          ...errors,
          studentId: 'Student ID is required',
        };
      }

      if (flightData?.international && !currentTraveller?.passport) {
        errors = {
          ...errors,
          passportNumber: 'Passport number is required',
          passportExpiry: 'Passport expiry date is required',
          passportIssueDate: 'Passport issue date is required',
        };
      }

      if (Object.keys(errors).length > 0) {
        setSelectedTraveller(traveller);
        setFieldErrors(errors);
        toggleDrawer();
      } else {
        const currentSelectionCounts = getCurrentSelectionCounts();

        const allowedCounts: any = {
          ADULT: flightData?.travellerCount?.adult || travelDetail?.travellerCount?.adult,
          CHILD: flightData?.travellerCount?.child || travelDetail?.travellerCount?.child,
          INFANT: flightData?.travellerCount?.infant || travelDetail?.travellerCount?.infant,
        };

        if (selectedTravelers?.includes(traveller?.id)) {
          // if already selected, allow deselection
          setSelectedTravelers(selectedTravelers?.filter((id: any) => id !== traveller?.id));
        } else {
          // checking if adding this traveler would exceed the allowed count for their type
          if (
            currentSelectionCounts[currentTraveller?.type] < allowedCounts[currentTraveller?.type]
          ) {
            setSelectedTravelers([...selectedTravelers, traveller?.id]);
          } else {
            dispatch(
              setMessage(`Cannot select more ${currentTraveller.type.toLowerCase()}s than allowed.`)
            );
          }
        }
      }
    },
    [
      selectedTravelers,
      setSelectedTravelers,
      toggleDrawer,
      setSelectedTraveller,
      travellers,
      flightData,
      dispatch,
    ]
  );

  const handleAddTraveller = useCallback(() => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Confirm',
        ctaAction: 'Addtraveller',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    if (totalCount !== selectedTravelers?.length && totalCount > selectedTravelers?.length) {
      toggleDrawer();
    } else {
      dispatch(setMessage('You have already selected all the travellers'));
    }
  }, [totalCount, selectedTravelers, toggleDrawer, dispatch]);

  return (
    <>
      <Container sx={{ mt: '16px' }}>
        <Box display="flex" alignItems="end" mb="4px">
          <Typography variant="body1" sx={{ mr: '6px' }} fontWeight={500}>
            Travellers
          </Typography>
        </Box>
        <Box mb="12px">
          <Typography variant="body3" sx={{ color: 'grey.800' }}>
            ({travellers?.data?.length > 0 ? 'Select' : 'Add'}:{' '}
            {travelDetail?.travellerCount?.adult
              ? `${travelDetail.travellerCount.adult} Adult${
                  travelDetail.travellerCount.adult > 1 ? 's' : ''
                }`
              : ''}
            {travelDetail?.travellerCount?.child
              ? ` • ${travelDetail.travellerCount.child} Child${
                  travelDetail.travellerCount.child > 1 ? 'ren' : ''
                }`
              : ''}
            {travelDetail?.travellerCount?.infant
              ? ` • ${travelDetail.travellerCount.infant} Infant${
                  travelDetail.travellerCount.infant > 1 ? 's' : ''
                }`
              : ''}
            )
          </Typography>
        </Box>
        {travellers?.data?.length > 0 ? (
          travellers?.data?.map((traveller: any, index: number) => (
            <>
              <OACheckbox
                key={traveller?.id}
                label={`${traveller?.firstName} ${traveller?.lastName}, ${traveller?.age}`}
                checked={selectedTravelers?.includes(traveller?.id)}
                onChange={() => handleToggleTravellerSelection(traveller)}
                onEdit={() => {
                  setSelectedTraveller(traveller);
                  toggleDrawer();
                }}
                orgCode={orgCode}
              />
              {index !== travellers?.data?.length - 1 && (
                <Divider sx={{ my: '8px', color: '#009E82' }} />
              )}
            </>
          ))
        ) : (
          <Box mt="16px">
            {loading && (
              <>
                {' '}
                <Skeleton sx={{ mb: '16px' }} />
                <Skeleton sx={{ mb: '16px' }} />
                <Skeleton sx={{ mb: '16px' }} />
                <Skeleton />
              </>
            )}
          </Box>
        )}
      </Container>
      <Box
        display="flex"
        onClick={handleAddTraveller}
        justifyContent="center"
        sx={{ bgcolor: orgCode === 'ZOLVE' ? 'revert' : '#F4F6F5', mt: '20px', px: '16px' }}
      >
        <OAButton
          variant={orgCode === 'ZOLVE' ? 'outlined' : 'text'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderColor: '#E2E2E2',
          }}
          fullWidth
        >
          <Box display="flex" alignItems="center">
            <OAImage
              src="add.svg"
              folder={orgCode === 'ZOLVE' ? 'zolveIcons' : 'icons'}
              alt="copy"
            />
            <Typography color="primary.color" variant="body2" ml="8px" sx={{ fontWeight: 600 }}>
              Add traveller
            </Typography>
          </Box>
        </OAButton>
      </Box>
    </>
  );
};

export default List;
