import { store } from '../store';
import { setFeatureFlag } from '../store/slices/featuresSlice';

type OrgCode = 'ZOLVE' | 'DEFAULT';

interface FeatureFlags {
  burnCoinsFeat: boolean;
  earnCoinsFeat: boolean;
  fareCalendarFeat: boolean;
  exclusiveFare: boolean;
  chatbotSupport: boolean;
}

// Map of organization codes to their specific feature flag settings
const orgFeatureFlags: Record<OrgCode, FeatureFlags> = {
  ZOLVE: {
    burnCoinsFeat: false,
    earnCoinsFeat: false,
    fareCalendarFeat: true,
    exclusiveFare: false,
    chatbotSupport: true,
  },
  DEFAULT: {
    burnCoinsFeat: true,
    earnCoinsFeat: true,
    fareCalendarFeat: true,
    exclusiveFare: false,
    chatbotSupport: true,
  },
};

export const initFeatureFlags = (orgCode: OrgCode) => {
  const featureSettings = orgFeatureFlags[orgCode || 'DEFAULT'];
  store.dispatch(setFeatureFlag(featureSettings));
};
