import { authApi } from '../services/authApi';
import { store } from '../store';
import { setCredentials } from '../store/slices/authSlice';

export const userLogin = async (reqObj: any) => {
  console.log('reqObj', reqObj);

  try {
    if (reqObj?.id && reqObj?.mobile && reqObj?.token) {
      if (reqObj?.organizationId && reqObj?.organizationCode) {
        let userTemp = {
          organizationId: reqObj?.organizationId,
          organizationCode: reqObj?.organizationCode,
        };
        const cred = {
          isLoggedIn: true,
          user: userTemp,
          token: reqObj?.token || null,
        };
        store.dispatch(setCredentials(cred));
      }
      const resultAction: any = await store.dispatch(authApi.endpoints.createUser.initiate(reqObj));
      if (resultAction?.data?.httpStatus === 200) {
        let user = resultAction?.data?.data;
        if (reqObj?.organizationId && reqObj?.organizationCode) {
          user = {
            ...user,
            organizationId: reqObj?.organizationId,
            organizationCode: reqObj?.organizationCode,
          };
        }
        const cred = {
          isLoggedIn: true,
          user: user,
          token: reqObj?.token || null,
        };
        store.dispatch(setCredentials(cred));
        return { success: true, message: 'Login successful', data: cred.user };
      } else {
        return {
          success: false,
          message: 'User API failed',
          httpStatus: resultAction?.data?.httpStatus,
        };
      }
    } else {
      console.error(`login field missing!!` + JSON.stringify(reqObj));
      return { success: false, message: 'Required fields are missing' };
    }
  } catch (error: any) {
    // Error handling code
    console.error('user API catch', error);
    return { success: false, message: 'An error occurred', error: error };
  }
};
