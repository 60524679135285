import { Box } from '@mui/material';
import { OALoadingLottie } from './OALoadingLottie';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from '../store';
import { getAuthInfo } from '../store/slices/authSlice';

export const OALoadingOverlay = () => {
  const { user } = useSelector(getAuthInfo);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: '#FAFAFA4D',
        zIndex: 9999,
      }}
    >
      {user?.organizationCode === 'ZOLVE' ? <CircularProgress /> : <OALoadingLottie />}
    </Box>
  );
};
