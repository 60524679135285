import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi } from './baseApi';
import { INITIALIZE_PAYMENT, PAYMENT_STATUS } from '../store/apiUrls';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: baseApi,
  endpoints: (builder) => ({
    getPaymentStatus: builder.mutation({
      query: (request) => {
        let buildUrl = `${PAYMENT_STATUS}?orderId=${request?.orderId}`;
        if (request?.paymentStatus) buildUrl += `&paymentStatus=${request?.paymentStatus}`;
        return {
          url: buildUrl,
          method: 'GET',
        };
      },
    }),
    initializePayment: builder.mutation({
      query: (paymentData) => ({
        url: INITIALIZE_PAYMENT,
        method: 'POST',
        body: paymentData,
      }),
    }),
  }),
});

export const { useGetPaymentStatusMutation, useInitializePaymentMutation } = paymentApi;
