export const formatToINR = (amount: number, formatAsCurrency: boolean = true): string => {
  if (isNaN(amount)) {
    return '₹0';
  }

  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };

  if (formatAsCurrency) {
    options.style = 'currency';
    options.currency = 'INR';
  }

  return new Intl.NumberFormat('en-IN', options).format(amount);
};
