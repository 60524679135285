import React from 'react';
import { Box, Card } from '@mui/material';
import { IFlight } from '../../../type';
import AirlineDetail from './AirlineDetail';
import FlightDetail from './FlightDetail';
import { OAImage } from '../../../components';

const FlightCard = ({
  data,
  onClick,
  tripType,
  isInternational,
  orgCode
}: {
  data: IFlight;
  onClick: (flight: IFlight) => void;
  tripType: 'R' | 'O';
  isInternational?: boolean;
  orgCode?: string;
}) => {
  const domesticReturn = tripType === 'R' && !isInternational;
  return (
    <Card
      sx={{
        p: '1px',
        borderRadius: domesticReturn ? '0px' : '8px',
        border: domesticReturn ? 0 : '1px solid #DEE2E1',
        bgcolor: domesticReturn ? 'transparent' : '#FDFDFD',
        position: 'relative',
        overflow: 'unset',
        // ...(data?.price?.exclusiveFare && !domesticReturn ? { pt: '15px' } : {}),
      }}
    >
      {/* {data?.price?.exclusiveFare && !domesticReturn && (
        <OAImage
          src="niyo-exclusive-badge.svg"
          alt="exclusive"
          sx={{
            position: 'absolute',
            top: '-3px',
            left: '15px',
          }}
        />
      )} */}
      <Box onClick={() => onClick(data)}>
        <AirlineDetail data={data} tripType={tripType} isInternational={isInternational} orgCode={orgCode} />
        <FlightDetail data={data} tripType={tripType} isInternational={isInternational} />
      </Box>
    </Card>
  );
};

export default FlightCard;
