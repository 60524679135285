import { Container, Divider, Typography } from '@mui/material';

const PrimaryContactDetails = ({ contactDetails }: { contactDetails: any }) => {
  return (
    <>
      <Container>
        <Typography variant="body1" sx={{ fontWeight: 600, mb: '16px' }}>
          Primary contact details
        </Typography>
        <Typography variant="body2" sx={{ mb: '8px' }}>
          {contactDetails?.fullName}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {contactDetails?.email}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {contactDetails?.phoneNumber}
        </Typography>
      </Container>
      <Divider sx={{ borderBottomWidth: '4px', borderColor: '#F4F6F5', my: '20px' }} />
    </>
  );
};

export default PrimaryContactDetails;
