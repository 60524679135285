import React from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

interface OARadioGroupProps {
  label: string;
  options: { value: string; label: string }[];
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: any;
}

export const OARadioGroup = ({ label, options, value, onChange, sx }: OARadioGroupProps) => {
  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <FormLabel component="legend" sx={sx}>
        {label}
      </FormLabel>
      <RadioGroup value={value} onChange={onChange}>
        {options.map((option, index) => (
          <React.Fragment key={index}>
            <FormControlLabel
              value={option.value}
              control={<Radio
              />}
              label={option.label} />
            {index !== options?.length - 1 && (
              <Divider sx={{ width: '100%', borderColor: '#DEE2E1', my: '8px' }} />
            )}
          </React.Fragment>
        ))}
      </RadioGroup>
    </FormControl>
  );
};
