import React, { FC, useState, useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import OAChip from '../OAChip';
import ChipWithIcon from '../ChipWithIcon';
import ItemCard from '../ItemCard';
import { useDispatch, useSelector } from '../../../../store';
import {
  addMealSelection,
  removeMealSelection,
  getSmbData,
} from '../../../../store/slices/flightOrderSlice';
import EmptySmb from '../EmptySmb';
import { eventsTracker } from '../../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';

interface MealsPanelProps {
  mealsData: any;
  selectedSegment: { from: string; to: string };
  updateSmbAction: any;
  totalTravellers: number;
}

interface MealInfo {
  price: number;
  code: string;
  description: string;
  paxTypeList: string[];
  refundable: boolean;
  mealType: 'Veg' | 'Non-Veg';
}

interface MealAncillaries {
  mealInfos: MealInfo[];
}

interface OriginDestinationInfo {
  from: string;
  to: string;
}

interface Segment {
  segmentIndex: number;
  originDestinationInfo: OriginDestinationInfo;
  mealAncillaries: MealAncillaries;
}

const MealsPanel: FC<MealsPanelProps> = ({
  mealsData,
  selectedSegment,
  updateSmbAction,
  totalTravellers,
}) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const smbData = useSelector(getSmbData);
  const [mealTypeFilter, setMealTypeFilter] = useState<string | null>(null);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const mealsCardsData = getMealAncillariesByOriginDestination(
    mealsData,
    selectedSegment?.from,
    selectedSegment?.to
  );

  const handleAddMeal = (meal: MealInfo) => {
    const selectedCount = getSelectedMealCount(meal.code);
    if (selectedCount < totalTravellers) {
      const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'SMB',
          ctaAction: 'MealAdd',
          screenDuration: totalScreenDuration?.toString(),
          otherData: {
            code: meal?.code,
            mealType: meal?.mealType,
          },
        },
        posthog
      );
      dispatch(
        addMealSelection({
          from: selectedSegment.from,
          to: selectedSegment.to,
          mealDetails: {
            mealCode: meal.code,
            mealInfo: meal.description,
            price: meal.price,
            mealType: meal?.mealType,
          },
          totalTravellers,
          apiResponse: mealsData,
        })
      );
    }
  };

  const handleRemoveMeal = (meal: MealInfo) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SMB',
        ctaAction: 'MealRemove',
        screenDuration: totalScreenDuration?.toString(),
        otherData: {
          code: meal?.code,
          mealType: meal?.mealType,
        },
      },
      posthog
    );
    dispatch(
      removeMealSelection({
        from: selectedSegment.from,
        to: selectedSegment.to,
        mealCode: meal.code,
        totalTravellers,
      })
    );
  };

  const getSelectedMealCount = (mealCode: string) => {
    if (!smbData || !selectedSegment) return 0;

    return smbData.userSelectedJourneyAncillaries.reduce(
      (count: number, journey: any) =>
        journey.userSelectedSegmentAncillaries.reduce(
          (segmentCount: number, segment: any) =>
            segment.segmentOriginDestinationInfo.from === selectedSegment.from &&
            segment.segmentOriginDestinationInfo.to === selectedSegment.to
              ? segmentCount +
                segment.mealDetails.filter((meal: any) => meal.mealCode === mealCode).length
              : segmentCount,
          count
        ),
      0
    );
  };

  const filteredMeals = useMemo(() => {
    let meals = mealsCardsData?.mealInfos?.slice() || [];
    if (mealTypeFilter) {
      meals = meals.filter((meal: MealInfo) => meal.mealType === mealTypeFilter);
    }
    return meals.sort((a: MealInfo, b: MealInfo) => a.price - b.price);
  }, [mealsCardsData, mealTypeFilter]);

  return (
    <Box>
      {!mealsCardsData?.mealInfos ? (
        <EmptySmb text="Meal selection is not available. But you can select meals during web check-in." />
      ) : (
        <>
          {/* NOTE: hiding veg non-veg tags for TBO because it doesn't give mealType in TBO response */}
          {/* <Box px="16px" bgcolor="white" pb="10px" position="sticky" top="188px" zIndex={10}>
            <OAChip
              label={<ChipWithIcon label="Vegetarian" icon="veg" />}
              onClick={() => setMealTypeFilter(mealTypeFilter === 'Veg' ? null : 'Veg')}
              sx={{
                backgroundColor: mealTypeFilter === 'Veg' ? '#D9FCF3 !important' : 'white',
                borderColor: mealTypeFilter === 'Veg' ? '#00C6A8' : '#DEE2E1',
              }}
            />
            <OAChip
              label={<ChipWithIcon label="Non-vegetarian" icon="non-veg" />}
              onClick={() => setMealTypeFilter(mealTypeFilter === 'Non-Veg' ? null : 'Non-Veg')}
              sx={{
                backgroundColor: mealTypeFilter === 'Non-Veg' ? '#D9FCF3 !important' : 'white',
                borderColor: mealTypeFilter === 'Non-Veg' ? '#00C6A8' : '#DEE2E1',
              }}
            />
          </Box> */}
          <Box pt="15px" px="16px" height="calc(100vh - 352px)" overflow="scroll">
            {filteredMeals.map((meal: MealInfo) => {
              const selectedCount = getSelectedMealCount(meal.code);
              const isSelected = selectedCount > 0;

              return (
                <Box mb="15px" key={meal.code}>
                  <ItemCard
                    cardType="meal"
                    code={meal.code}
                    description={meal.description}
                    price={meal.price}
                    mealType={meal.mealType}
                    paxListType={meal.paxTypeList}
                    refundable={meal.refundable}
                    travellersCount={totalTravellers}
                    onAdd={() => handleAddMeal(meal)}
                    onRemove={() => handleRemoveMeal(meal)}
                    isSelected={isSelected}
                    selectedCount={selectedCount}
                  />
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};

export default MealsPanel;

function getMealAncillariesByOriginDestination(
  segments: any,
  from: string,
  to: string
): MealAncillaries | undefined {
  const segmentAncillaries = segments?.map((segment: any) => segment?.segmentAncillaries);
  const segment = segmentAncillaries?.find((ancillary: any) =>
    ancillary?.some(
      (segment: any) =>
        segment?.originDestinationInfo?.from === from && segment?.originDestinationInfo?.to === to
    )
  );

  return segment?.find(
    (seg: any) => seg?.originDestinationInfo?.from === from && seg?.originDestinationInfo?.to === to
  )?.mealAncillaries;
}
