import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface OAImageProps extends Omit<BoxProps, 'src'> {
  src: string;
  alt: string;
  folder?: string;
}

export const OAImage: React.FC<OAImageProps> = ({ src, folder, sx, ...otherProps }) => {
  const staticS3Url = 'https://oa-flight-assets.s3.ap-south-1.amazonaws.com/';
  const imageUrl = folder ? `${staticS3Url}${folder}/${src}` : `${staticS3Url}${src}`;

  return (
    <>
      <Box
        component="img"
        src={imageUrl}
        sx={{
          maxWidth: '100%',
          ...sx,
        }}
        {...otherProps}
        loading="lazy"
      />
    </>
  );
};
