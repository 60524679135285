// NetworkDetector.tsx
import React, { useState, useEffect, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as NoInternetImg } from './assets/images/no-internet.svg';

interface NetworkDetectorProps {
  children: ReactNode;
}

const NetworkDetector: React.FC<NetworkDetectorProps> = ({ children }) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // Cleanup
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return (
    <>
      {!isOnline && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#FDFDFD',
            zIndex: 99999999999, // Ensure it's above everything else
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
          }}
        >
          <Box>
            <NoInternetImg />
          </Box>
          <Typography
            component="p"
            variant="body2"
            sx={{ textAlign: 'center', width: '75%', color: 'text.secondary' }}
          >
            Please turn on either your wifi or data connection and try again
          </Typography>
        </Box>
      )}
      {children}
    </>
  );
};

export default NetworkDetector;
