import { Box, Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import { OAImage } from '../OAImage';

interface OACheckboxProps {
  name?: string;
  label?: string;
  value?: string;
  checked: boolean;
  onEdit?: () => void;
  onChange?: any;
  isDisabled?: boolean;
  orgCode?: string;
}

export const OACheckbox = ({
  name,
  label,
  value,
  checked,
  onEdit,
  onChange,
  isDisabled,
  orgCode,
}: OACheckboxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            value={value}
            checked={checked}
            onChange={(event) => onChange(event, name)}
            disabled={isDisabled}
          />
        }
        label={<Typography variant="body1">{label}</Typography>}
        sx={{ marginRight: 'auto' }} // This pushes the FormControlLabel to the left and the IconButton to the right
      />
      {onEdit && (
        <IconButton aria-label="edit" onClick={onEdit} sx={{ p: 0 }}>
          <OAImage
            src="edit.svg"
            folder={orgCode === 'ZOLVE' ? 'zolveIcons' : 'icons'}
            alt="edit"
          />
        </IconButton>
      )}
    </Box>
  );
};
