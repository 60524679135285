import { Box, Container, Divider, Typography } from '@mui/material';
import { OAButton, OASwipeableDrawer } from '../../../components';
import { useEffect, useState } from 'react';
import CancellationReasons from './CancellationReasons';
import CancellationPolicy from './CancellationPolicy';
import { eventsTracker } from '../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import OAFlightRoute from '../../../components/OAFlightRoute';
import { useSelector } from '../../../store';
import { getCancellationState } from '../../../store/slices/cancellationSlice';

const CancelBooking = ({ data, orgCode }: any) => {
  const posthog = usePostHog();
  const { cancellationData } = useSelector(getCancellationState);
  const [isCancellationReasonsOpen, setIsCancellationReasonsOpen] = useState<boolean>(false);
  const [isCancellationPolicyOpen, setIsCancellationPolicyOpen] = useState<boolean>(false);
  const [isCancellationPolicyDrawerOpen, setIsCancellationPolicyDrawerOpen] =
    useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());

  const onwardMinirule = data?.flights?.find(
    (flight: any) => flight?.departureType === 'ONWARD'
  )?.minirule;
  const returnMinirule = data?.flights?.find(
    (flight: any) => flight?.departureType === 'RETURN'
  )?.minirule;

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const toggleCancellationReasons = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Booking',
        ctaAction: 'Cancel',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    setIsCancellationReasonsOpen(!isCancellationReasonsOpen);
  };

  const toggleCancellationPolicy = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Booking',
        ctaAction: 'CancelPolicy',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    setIsCancellationPolicyOpen(!isCancellationPolicyOpen);
  };

  const toggleCancelPolicy = () => {
    setIsCancellationPolicyDrawerOpen(!isCancellationPolicyDrawerOpen);
  };

  return (
    <>
      <Container>
        <Typography variant="body1" sx={{ fontWeight: 600, mb: '4px' }}>
          Plan changed?
        </Typography>
        <Typography variant="body3" sx={{ color: 'text.secondary' }}>
          If you want to cancel your ticket for any resson proceed to claim your refund
        </Typography>

        <OAButton
          onClick={toggleCancellationReasons}
          fullWidth
          variant="outlined"
          color="error"
          sx={{ mt: '20px', border: '1px solid #C01100' }}
        >
          {cancellationData?.cancellable ? 'Cancel Booking' : 'Request Cancellation'}
        </OAButton>
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, color: 'primary.main', textAlign: 'center', mt: '20px' }}
          onClick={toggleCancellationPolicy}
        >
          View Cancellation policy
        </Typography>
      </Container>
      <Divider sx={{ borderBottomWidth: '4px', borderColor: '#F4F6F5', my: '20px' }} />
      <OASwipeableDrawer
        title="Why do you want to cancel"
        open={isCancellationReasonsOpen}
        onClose={toggleCancellationReasons}
        onOpen={toggleCancellationReasons}
        isContainer={false}
      >
        <CancellationReasons bookingData={data} orgCode={orgCode} />
      </OASwipeableDrawer>
      <OASwipeableDrawer
        title="Cancellation policy"
        open={isCancellationPolicyOpen}
        onClose={toggleCancellationPolicy}
        onOpen={toggleCancellationPolicy}
        isContainer={false}
      >
        {returnMinirule && (
          <Box
            display="flex"
            alignItems="center"
            color="gray"
            bgcolor="#F4F6F5"
            p="10px 16px"
            mb="15px"
          >
            <OAFlightRoute
              from={data?.flightSearchInfo?.from?.city}
              to={data?.flightSearchInfo?.to?.city}
              icon="flight-take-off.svg"
            />
          </Box>
        )}
        <Container>
          <CancellationPolicy data={onwardMinirule} />
        </Container>
        {returnMinirule && (
          <Box
            display="flex"
            alignItems="center"
            color="gray"
            bgcolor="#F4F6F5"
            p="10px 16px"
            mb="15px"
          >
            <OAFlightRoute
              from={data?.flightSearchInfo?.from?.city}
              to={data?.flightSearchInfo?.to?.city}
              icon="flight-take-off.svg"
            />
          </Box>
        )}
        <Container>
          <CancellationPolicy data={returnMinirule} />
        </Container>
        <Box display="flex" justifyContent="center" mb="16px">
          <OAButton
            onClick={toggleCancelPolicy}
            size="medium"
            sx={{ color: '#009E82', fontSize: '14px' }}
          >
            View terms and conditions
          </OAButton>
        </Box>
      </OASwipeableDrawer>
      <OASwipeableDrawer
        title="Terms & conditions"
        open={isCancellationPolicyDrawerOpen}
        onClose={toggleCancelPolicy}
        onOpen={toggleCancelPolicy}
        maxHeight={96}
      >
        {onwardMinirule?.cancelTermsAndConditions?.map((item: any, index: number) => {
          const htmlRegex = /<[^>]*>/;
          const isHTMLString = htmlRegex.test(item);
          if (isHTMLString) {
            return <div dangerouslySetInnerHTML={{ __html: item }} />;
          } else {
            return (
              <Box key={index} display="flex" justifyContent="space-between" mb="15px">
                <Typography variant="body2" color="gray">
                  {item}
                </Typography>
              </Box>
            );
          }
        })}
      </OASwipeableDrawer>
    </>
  );
};

export default CancelBooking;
