import { Box, Skeleton } from '@mui/material';

interface BookingStatusSkeletonProps {
  width?: number;
  height?: number;
  variant?: any;
  count?: number;
}

export const BookingStatusSkeleton = (props: BookingStatusSkeletonProps) => {
  const { variant = 'text', width = 200, count = 2 } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box>
        {[...Array(count)].map((_, i) => (
          <Skeleton variant={variant} width={width} key={i} />
        ))}
      </Box>
    </Box>
  );
};
