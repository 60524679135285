import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { store, persistor, useDispatch } from './store';
import AppRoutes from './routes';
import { getThemeForOrg } from './config';
import './assets/scss/style.scss';
import { PersistGate } from 'redux-persist/integration/react';
import { OASnackbar } from './components/basic/OASnackbar';
import { cleanupWindowFunctions, initializeWindowFunctions } from './windowFunctions';

import { OALoading } from './components';
import { userLogin } from './utils/userLogin';
import { initFeatureFlags, isMobileWebview, parseObject, isTestEmailForProd } from './utils';
import NetworkDetector from './NetworkDetector';
import { useLazyGetCampaignsQuery } from './services/authApi';
import VisibilityObserver from './VisibilityObserver';
import { setCampaigns, setDeviceInfo } from './store/slices/authSlice';
import { jwtDecode } from 'jwt-decode';
import { createUserLoginRequest } from './utils/createUserLoginRequest';
import DataDogSetup from './DataDogSetup';
import { setStudentFareFeat } from './store/slices/featuresSlice';
import VConsole from 'vconsole';
import ErrorBoundary from './components/ErrorBoundary';

// const vConsole = new VConsole();
interface InitializationWrapperProps {
  children: React.ReactNode; // Explicitly type the children prop
}

export const useInitialization = () => {
  const params = new URLSearchParams(window.location.search);
  const [trigger] = useLazyGetCampaignsQuery({});
  const dispatch = useDispatch();
  const token = params.get('token') ?? 'eyJhbGciOiJIUzI1NiJ9.eyJmaXJzdE5hbWUiOiJWaXNoYWwiLCJsYXN0TmFtZSI6IkJoaW1hbmkiLCJwaG9uZSI6Ijk1NTg2MTExMDEiLCJhcHBVc2VySWQiOiJ2aXNoYWwtdGVzdDEiLCJlbWFpbCI6InZpc2hhbEBvbmFycml2YWwudHJhdmVsIiwianRpIjoiMTMwODliMGMtNTU1NC00MDQwLWEzODMtMmNiZWNjZTg0NjU5IiwiaWF0IjoxNzE5Mzk2NzMwLCJleHAiOjE3NTEwMTkxMzAsImlzcyI6InpvbHZlIiwiYXVkIjoib24tYXJyaXZhbCIsInN1YiI6InZpc2hhbC10ZXN0MSJ9.YObjjcwWGqUevMZu9udoXUTM8Ktr6P_CIHXNY6vGiVk';
  const [isInitialized, setIsInitialized] = useState(false);

  const campaignsAndPosthogHandler = (userPost: any, id: any) => {
    if (userPost?.userId || id) {
      trigger(userPost?.userId || id)
        .then((result) => {
          if (result?.data?.data) {
            dispatch(setCampaigns(result?.data?.data));
          }
        })
        .catch((error) => {
          console.error('Network Error:', error);
        });
    }
  };

  const handleLogin = async (token: string, additionalData = {}) => {
    try {
      const decoded = jwtDecode(token);
      const requestData = { ...decoded, ...additionalData, ...{ token: token } };
      const request = createUserLoginRequest(requestData);

      //init feature falgs
      initFeatureFlags(request?.orgCode ?? request?.organizationCode);
      const userResponse = await userLogin(request);

      if (isTestEmailForProd(userResponse?.data?.email)) {
        // dispatch(setSmbFeat(true));
        //Temporarily added for testing. Once done, we will remove vConsole.
        dispatch(setStudentFareFeat(true));
        const vConsole = new VConsole();
      }
      if (userResponse?.data?.organizationCode !== 'ZOLVE')
        campaignsAndPosthogHandler(userResponse, request?.organizationCode);
    } catch (error) {
      console.error('Login or decoding failed:', error);
    } finally {
      setIsInitialized(true);
    }
  };

  useEffect(() => {
    const initAsync = async () => {
      console.log('Inititalization in progress');
      if (!isMobileWebview() && !window.niyo_user_login) {
        await initializeWindowFunctions();
        console.log('already initialized so skipping');
      }
      if (
        isMobileWebview() &&
        window.niyo_user_login &&
        typeof window.niyo_user_login === 'function'
      ) {
        console.log('login is happening');
        window
          .niyo_user_login()
          .then((res) => {
            console.log('called user login!!!');
            let userObj = parseObject(res);
            handleLogin(userObj?.token, userObj);
          })
          .catch((error) => {
            setIsInitialized(true);
          });
      } else {
        if (token) {
          const additionalData = {
            orgId: '77c3d307-b194-41e0-8f6b-45df37f1db34',
            orgCode: 'ZOLVE',
            token: token,
          };
          handleLogin(token, additionalData);
        } else {
          setIsInitialized(true);
        }
      }
    };
    initAsync();
  }, []);

  return isInitialized;
};

export const InitializationWrapper: React.FC<InitializationWrapperProps> = ({ children }) => {
  const isInitialized = useInitialization();

  if (!isInitialized) {
    console.log('loader is loading');
    return (
      <Box sx={{ height: '100vh' }}>
        <OALoading />
      </Box>
    );
  }

  return <>{children}</>;
};

function App() {
  const params = new URLSearchParams(window.location.search);
  const organizationCode = params.get('orgCode');

  useEffect(() => {
    console.log('reinitialize');
    initializeWindowFunctions();

    return () => {
      cleanupWindowFunctions();
    };
  }, []);

  useEffect(() => {
    // Function to handle the context menu event
    const handleContextMenu = (event: any) => {
      event.preventDefault(); // Prevents the default context menu
    };

    // Add event listener for 'contextmenu', which includes right-clicks and long-presses
    document.addEventListener('contextmenu', handleContextMenu);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <InitializationWrapper>
            <ThemeProvider theme={getThemeForOrg(organizationCode ?? '')}>
              <Suspense>
                <NetworkDetector>
                  <Router>
                    <AppRoutes />
                    <VisibilityObserver />
                    <DataDogSetup /> {/* Niyo only, added condition inside compo. */}
                  </Router>
                </NetworkDetector>
              </Suspense>
              <OASnackbar />
            </ThemeProvider>
          </InitializationWrapper>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
