import { FC } from 'react';
import { Box, SxProps, Typography } from '@mui/material';

interface CoinsBannerProps {
  title?: string;
  subTitle?: string;
  titleSx?: SxProps;
  subTitleSx?: SxProps;
}
const CoinsBanner: FC<CoinsBannerProps> = ({ title, subTitle, titleSx, subTitleSx }) => {
  return (
    <Box sx={{ position: 'absolute', bottom: 60, left: 20 }}>
      <Typography variant="h3" sx={{ ...titleSx }}>
        {title}
      </Typography>
      <Typography variant="h2" sx={{ ...subTitleSx }}>
        {subTitle}
      </Typography>
    </Box>
  );
};

export default CoinsBanner;
