export const containerStyles = (flights: any, flightOptions: any, travelDetail: any, isInternational: boolean, orgCode?: string) => ({
  height:
    flights?.data?.results?.onward?.length > 0
      ? flightOptions?.onwardFlightOptions?.length <= 8
        ? '80vh'
        : 'calc(100vh - 104px)'
      : 'calc(100vh - 56px)',
  overflow: travelDetail?.tripType === 'R' && !isInternational ? 'hidden' : 'scroll',
  bgcolor: orgCode === 'ZOLVE' ? 'white' : '#F4F6F5',
  pb: '16px',
  pt: travelDetail?.tripType === 'R' && !isInternational ? '0px' : '16px',
  px: travelDetail?.tripType === 'R' && !isInternational ? '0px' : '16px',
  scrollbarWidth: 'none', /* For Firefox */
  '&::-webkit-scrollbar': { /* For Chrome, Safari and Edge */ display: 'none' },
});
