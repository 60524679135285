export const capitalizeFirstLetter = (string: string): string => {
  if (typeof string !== 'string' || string?.length === 0) {
    return '';
  }

  const lowerCaseString = string?.replace(/_/g, ' ')?.toLowerCase();

  const words = lowerCaseString?.split(' ');

  const capitalizedWords = words?.map(word => 
    word?.charAt(0)?.toUpperCase() + word?.slice(1)
  );

  return capitalizedWords?.join(' ');
}; 