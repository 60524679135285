import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import {
  OAHeader,
  OAHeaderSubTitle,
  OAHeaderTitle,
  OAImage,
  OAScrollToTop,
  OASwipeableDrawer,
} from '../../../components';
import ContactSupport from './ContactSupport';
import { useGetBookingsListQuery, useGetOrderByIdQuery } from '../../../services/bookingsApi';
import StatusMsgAndBtn from './StatusMsgAndBtn';
import TripDetailsSegments from './TripDetailsSegments';
import TravellerDetails from './TravellerDetails';
import PaymentAndInvoice from './PaymentAndInvoice';
import CancelBooking from './CancelBooking';
import ImportantInfo from './ImportantInfo';
import PrimaryContactDetails from './PrimaryContactDetails';
import RefundDetails from './RefundDetails';
import { bookingStatusLabels } from '../../../constants';
import SomethingWentWrong from '../../Review/SomethingWentWrong';
import { useSelector } from '../../../store';
import { getFeatureFlag } from '../../../store/slices/featuresSlice';
import { isMobileWebview } from '../../../utils';
import { getAuthInfo } from '../../../store/slices/authSlice';
import AddOnsSummary from '../../../components/OASMB/components/AddOnsSummary';
import useIsVisible from '../../../hooks/useIsVisible';

const BookingStatus = () => {
  const { orderId } = useParams();

  const {
    data,
    isLoading,
    isSuccess: isOrderSuccess,
    isError: isErrorOrderById,
    refetch: refetchOrderById,
  } = useGetOrderByIdQuery(orderId);
  const { smbFeat } = useSelector(getFeatureFlag);

  const { refetch: refetchBookingsList } = useGetBookingsListQuery({});
  const { chatbotSupport } = useSelector(getFeatureFlag);
  const { user } = useSelector(getAuthInfo);
  const pageRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(pageRef);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const flight = data?.data?.flights?.[0];
  const segments = flight?.segments ?? [];
  const orgCode = user?.organizationCode;
  const importantInfo = segments?.[0]?.baggageInfo?.baggages?.[0]?.info;

  const headerInfo = {
    from: { city: data?.data?.flightSearchInfo?.from?.city },
    to: { city: data?.data?.flightSearchInfo?.to?.city },
    tripType: data?.data?.tripType,
  };

  const headerSecondaryInfo = {
    travellerCount: data?.data?.travellerCount,
    tripStart: data?.data?.tripStart,
    tripEnd: data?.data?.tripEnd,
    cabinType:
      data?.data?.flights?.[0]?.ticketNumberInfo?.[0]?.cabinType?.toLowerCase() ??
      data?.data?.flightSearchInfo?.cabinType,
    tripType: data?.data?.tripType,
  };

  useEffect(() => {
    if (isErrorOrderById) setIsApiError(true);
  }, [isErrorOrderById]);

  useEffect(() => {
    if (isOrderSuccess) {
      refetchBookingsList();
    }
  }, [isOrderSuccess, refetchBookingsList]);

  const toggleApiError = () => setIsApiError(true);

  const eventFreshChat = () => {
    if (
      isMobileWebview() &&
      window?.niyo_fresh_chat &&
      typeof window.niyo_fresh_chat === 'function'
    ) {
      let appSubStage = `${data?.data?.bookingStatus?.toLowerCase()}`;
      const status = bookingStatusLabels?.find(
        (obj: any) => data?.data?.bookingStatus === obj?.value
      )?.label;
      if (status) {
        appSubStage = status?.toLowerCase();
      }
      const freshChatData = {
        appTag: 'oa_onarrival_mytrips',
        appSpoke: 'onarrival',
        appStage: 'onarrival_mytrips',
        appSubStage: `onarrival_mytrips_${appSubStage}`,
        faqTag: ['oa_onarrival_mytrips'],
      };
      const freshChatDataStringify = JSON.stringify(freshChatData);
      window.niyo_fresh_chat(freshChatDataStringify);
    } else {
      console.log('only allowed in mobile');
    }
  };

  const onwardFlights = data?.data?.flights?.find(
    (flight: any) => flight?.departureType === 'ONWARD'
  );
  const returnFlights = data?.data?.flights?.find(
    (flight: any) => flight?.departureType === 'RETURN'
  );

  const segmentBasedAddOns = () => {};

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (isVisible && ['BOOKING_IN_PROGRESS'].includes(data?.data?.bookingStatus)) {
      intervalId = setInterval(() => {
        refetchOrderById();
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isVisible, data?.data?.bookingStatus]);

  return (
    <Box ref={pageRef}>
      <OAScrollToTop />
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 100,
          bgcolor: 'background.paper',
        }}
      >
        <OAHeader
          title={<OAHeaderTitle travelDetail={headerInfo} />}
          subTitle={<OAHeaderSubTitle travelDetail={headerSecondaryInfo} />}
          loading={isLoading}
          action={
            chatbotSupport ? (
              <Box onClick={eventFreshChat}>
                <OAImage
                  src="chatbot.svg"
                  folder={orgCode === 'ZOLVE' ? 'zolveIcons' : 'icons'}
                  alt="chat"
                />
              </Box>
            ) : null
          }
        />
      </Box>
      <StatusMsgAndBtn order={data?.data} isLoading={isLoading} />
      {(data?.data?.bookingStatus === 'CANCELLED' ||
        data?.data?.bookingStatus === 'BOOKING_FAILED') && <RefundDetails data={data} />}
      <TripDetailsSegments order={data?.data} isLoading={isLoading} />
      <TravellerDetails traveller={data?.data?.travellers} isLoading={isLoading} />
      {data?.data && smbFeat && (
        <>
          {' '}
          <AddOnsSummary order={data?.data} />
          <Divider sx={{ borderBottomWidth: '4px', borderColor: '#F4F6F5', my: '20px' }} />
        </>
      )}
      <PaymentAndInvoice order={data?.data} isLoading={isLoading} />
      {![
        'ITINERARY',
        'CANCELLED',
        'BOOKING_FAILED',
        'BOOKING_IN_PROGRESS',
        'PROCESSING_OFFLINE',
        'PAYMENT_FAILED',
        'CANCELLATION_REQUESTED',
      ]?.includes(data?.data?.bookingStatus) &&
        data?.data?.tripEndDate &&
        new Date(data?.data?.tripEndDate) >= new Date() && (
          <CancelBooking data={data?.data} orgCode={orgCode} />
        )}
      <ImportantInfo info={importantInfo} />
      <PrimaryContactDetails contactDetails={data?.data?.contactDetails} />
      <ContactSupport
        onClick={eventFreshChat}
        bookingId={data?.data?.bookingId}
        orgCode={orgCode}
      />
      <OASwipeableDrawer
        title={<OAImage src="error-icon.svg" folder="icons" alt="Erro" />}
        open={isApiError}
        onOpen={toggleApiError}
        onClose={toggleApiError}
        isContainer={false}
        isError={true}
      >
        <SomethingWentWrong />
      </OASwipeableDrawer>
    </Box>
  );
};

export default BookingStatus;
