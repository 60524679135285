import { createSlice } from "@reduxjs/toolkit";

export interface CancellationProps {
  cancellationData: any;
  selectedCancelReason: any;
  loading: boolean;
  error: any;
}

const initialState: CancellationProps = {
  cancellationData: {},
  selectedCancelReason: {},
  loading: false,
  error: '',
};

export const cancellationSlice = createSlice({
  name: 'cancellation',
  initialState,
  reducers: {
    setCancellationData: (state, action) => {
      state.cancellationData = action.payload;
    },
    setSelectedCancelReason: (state, action) => {
      state.selectedCancelReason = action.payload;
    }
  }
})

export const getCancellationState = (state: { cancellationData: any }) => state.cancellationData;
export const getSelectedCancelReason = (state: { selectedCancelReason: any }) => state.selectedCancelReason;
export const { setCancellationData, setSelectedCancelReason } = cancellationSlice.actions;

export default cancellationSlice.reducer;