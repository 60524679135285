import { Box, Card, Divider, Typography } from "@mui/material";
import { formatDate, formatToINR } from "../../../utils";

const DateChangeInfo = ({ data }: any) => {
  return (
    <>
      {data && Object?.entries(data || {})?.map(([chargeType, charges]: any) => (
        <Card key={chargeType} sx={{ p: '16px', borderRadius: '8px', mb: '20px' }}>
          <Box display='flex' justifyContent='space-between' mb='15px'>
            <Typography variant="caption" fontWeight={600} color="gray">CHANGE BETWEEN</Typography>
            <Typography variant="caption" fontWeight={600} color="gray">CHARGES ({chargeType})</Typography>
          </Box>
          {charges?.map((item: any, index: number) => (
            <>
              <Box key={index} display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant="body2">
                  {index === 0 ? 'From now' : formatDate(item?.start)} - {formatDate(item?.end)}
                </Typography>
                <Box>
                <Typography variant="body2">{formatToINR(item?.totalCharges)}
                </Typography>
                <Typography variant="body4" color='text.secondary'>+Fare difference</Typography>
                </Box>
              </Box>
              {index !== charges?.length - 1 && (
                <Divider sx={{ borderColor: "#DEE2E1", my: '15px' }} />
              )}
            </>
          ))}
        </Card>
      ))}
    </>
  )
}

export default DateChangeInfo;