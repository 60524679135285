import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const DataDogSetup = () => {
  useEffect(() => {
    const isProduction = window.location.href.includes('goniyo.com');
    console.log('DataDogSetup Called! - 1');
    if (isProduction) {
      console.log('DataDogSetup Called! - 2');
      datadogRum.init({
        applicationId: '6b46ab90-2987-43d0-ac40-b87ff13f41fc',
        clientToken: 'pubb3646a109f1fb58960dc561f4c1dd999',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'us5.datadoghq.com',
        service: 'flight-niyo',
        env: 'prod',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }
  }, []);

  return null;
};

export default DataDogSetup;
