import { store } from '../store';
import { getDiscountPrice } from './getDiscountPrice';

// Constants for default values and reward types
const DEFAULT_MULTIPLIER = 0.4;
const REWARD_TYPE_NXREWARD = 'NXREWARD';
const REWARD_TYPE_FIXED = 'FIXED';

export const getEarnCoin = (flightPrice: number) => {
  if (flightPrice <= 0) return 0;

  const state = store.getState();

  const tempCampaigns: any = state.auth?.campaigns;
  const campaigns = tempCampaigns?.eligibleCampaigns;

  const isCoinsApplied = state?.userInput?.isCoinsApplied;
  const coins = state?.userInfo?.coins;
  const discountPrice = isCoinsApplied ? getDiscountPrice(flightPrice, coins) : flightPrice;

  if (campaigns?.length > 0) {
    const { campaignRewardType, multiplierConstant, fixedRewardCoins } = campaigns[0];
    switch (campaignRewardType) {
      case REWARD_TYPE_NXREWARD:
        return Math.ceil(discountPrice * (multiplierConstant || DEFAULT_MULTIPLIER));
      case REWARD_TYPE_FIXED:
        return fixedRewardCoins || 0;
      default:
        return 0;
    }
  }

  return 0;
};
