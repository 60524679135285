import { format, isValid, parseISO } from 'date-fns';

/**
 * Enhanced function to format a date input (Date object or ISO string) to a specified format.
 * If the input date is invalid, returns null.
 *
 * @param {Date | string} date - The date input to format.
 * @param {string} dateFormat - The desired format of the output date string.
 * @returns {string | null} - The formatted date string or null if input is invalid.
 */
export const getFormatedDate = (
  date: Date | string,
  dateFormat: string = 'dd MMM yyyy'
): string | null => {
  let dateObj: Date | null = null;

  // Check if input is a valid Date object or a valid date string and parse it if necessary
  if (typeof date === 'string' && isValid(parseISO(date))) {
    dateObj = parseISO(date);
  } else if (date instanceof Date && isValid(date)) {
    dateObj = date;
  }

  // Format and return the date if valid, otherwise return null
  return dateObj ? format(dateObj, dateFormat) : null;
};
