import React from 'react';
import Chip from '@mui/material/Chip';
import { ChipProps } from '@mui/material/Chip';
import { SxProps } from '@mui/material';

// Define your custom props type here
interface OAChipProps extends ChipProps {
  selected?: boolean;
  sx?: SxProps;
}

// Create the wrapper component
export const OAChip = ({
  selected,
  clickable = true,
  variant = 'outlined',
  color = 'primary',
  sx,
  ...rest
}: OAChipProps) => {
  // Define the styles for when the chip is selected
  const selectedStyles = {
    bgcolor: selected ? 'primary.light' : undefined,
    borderColor: selected ? 'primary.main' : undefined,
  };
  // Merge the selectedStyles with any sx prop passed
  const mergedStyles = {
    ...selectedStyles,
    ...sx, // sx prop will override the selectedStyles if there are conflicts
  };

  // Return the MUI Chip component with the spread props
  return <Chip {...rest} clickable={clickable} variant={variant} color={color} sx={mergedStyles} />;
};
