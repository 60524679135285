import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { OAButton, OAChip, OATextField } from '../../components';
import { useEffect } from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import { getFlightOrderState } from '../../store/slices/flightOrderSlice';
import OADatePopup from './DOBPicker';
import {
  travellersApi,
  useCreateTravellerMutation,
  useGetTravellersQuery,
  useUpdateTravellerMutation,
} from '../../services/travellersApi';
import { useSelector } from '../../store';
import { addMonths, formatDate } from 'date-fns';

type Inputs = {
  firstName: string;
  lastName: string;
  dob: Date | null;
  gender: string;
  residentCountry: string;
  countryCode: string;
  passportNumber?: string;
  passportIssueDate?: Date | null;
  passportExpiry?: Date | null;
  nationality?: string;
  studentId?: string;
};

const GENDER = ['MALE', 'FEMALE'];

const DEFAULT_FORM_VALUES = {
  firstName: '',
  lastName: '',
  dob: null,
  gender: '',
  residentCountry: 'India',
  countryCode: 'IN',
  passportNumber: '',
  passportIssueDate: null,
  passportExpiry: null,
  nationality: 'IN',
  studentId: '',
};

const Add = ({
  traveller,
  closeDrawer,
  setSelectedTraveller,
  onNewTravellerAdded,
  fieldErrors,
  orgCode,
}: any) => {
  const { flightData } = useSelector(getFlightOrderState);
  const [createTraveller] = useCreateTravellerMutation();
  const [updateTraveller] = useUpdateTravellerMutation();
  const { data: travellers } = useGetTravellersQuery({});
  const defaultValues = {
    ...DEFAULT_FORM_VALUES,
    firstName: traveller?.firstName || '',
    lastName: traveller?.lastName || '',
    dob: traveller?.dateOfBirth || null,
    gender: traveller?.gender || '',
    passportNumber: traveller?.passport?.number || '',
    passportExpiry: traveller?.passport?.expiration || null,
    passportIssueDate: traveller?.passport?.issuingDate || null,
    ...(flightData?.flightSearchInfo?.fareGroup === 'STUDENT' && {
      studentId: traveller?.studentId || '',
    }),
  };

  const { reset, control, handleSubmit, setError, clearErrors, trigger, watch } = useForm<Inputs>({
    defaultValues,
  });

  const firstName = watch('firstName');
  const lastName = watch('lastName');

  const validateNameLength = (value: string, fieldName: 'firstName' | 'lastName') => {
    const combinedLength =
      (fieldName === 'firstName' ? value?.length : firstName?.length) +
      (fieldName === 'lastName' ? value?.length : lastName?.length);
    if (combinedLength > 30) {
      return 'Maximum character limit reached';
    }
    return true;
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    let tempFormVal: any = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      dateOfBirth: data?.dob,
      gender: data?.gender,
      residentCountry: 'IN',
      countryCode: 'IN',
      ...(flightData?.flightSearchInfo?.fareGroup === 'STUDENT' && { studentId: data?.studentId }),
    };

    let existingTraveller;
    let existingStudent;

    if (Array?.isArray(travellers?.data)) {
      existingTraveller = travellers?.data?.find(
        (traveler: any) => traveler?.passport?.number === data?.passportNumber
      );
    }

    if (Array?.isArray(travellers?.data)) {
      existingStudent = travellers?.data?.find(
        (traveler: any) => traveler?.studentId === data?.studentId
      );
    }

    if (existingTraveller && existingTraveller?.id !== traveller?.id) {
      setError('passportNumber', {
        type: 'manual',
        message: 'A traveller with this passport number already exists',
      });
      return;
    }

    if (
      flightData?.flightSearchInfo?.fareGroup === 'STUDENT' &&
      existingStudent &&
      existingStudent?.studentId !== traveller?.studentId
    ) {
      setError('studentId', {
        type: 'manual',
        message: 'A traveller with this student ID already exists',
      });
      return;
    }

    // If the flight is international, include passport details
    if (flightData?.international) {
      tempFormVal = {
        ...tempFormVal,
        nationality: 'IN',
        passport: {
          number: data?.passportNumber,
          issuingCountry: 'IN',
          expiration: data?.passportExpiry,
          issuingDate: data?.passportIssueDate,
        },
      };
    }

    if (traveller?.id) {
      updateTraveller({ id: traveller?.id, ...tempFormVal });
      closeDrawer(false);
    } else {
      createTraveller(tempFormVal).then((response: any) => {
        const newTravellerId = response?.data?.data?.id;
        const newTravellerType = response?.data?.data?.type;
        if (newTravellerId && newTravellerType) {
          onNewTravellerAdded(newTravellerId, newTravellerType);
        }
        //@ts-ignore
        travellersApi.util.invalidateTags([{ type: 'Travellers', id: 'LIST' }]);
      });
      closeDrawer(false);
    }

    reset({
      firstName: '',
      lastName: '',
      dob: null,
      gender: '',
      passportNumber: '',
      passportExpiry: null,
      passportIssueDate: null,
      studentId: '',
    });
    setSelectedTraveller(null);
    clearErrors();
  };

  useEffect(() => {
    const defaultValues = {
      firstName: traveller?.firstName || '',
      lastName: traveller?.lastName || '',
      dob: traveller?.dateOfBirth || null,
      gender: traveller?.gender || '',
      passportNumber: traveller?.passport?.number || '',
      passportExpiry: traveller?.passport?.expiration || null,
      passportIssueDate: traveller?.passport?.issuingDate || null,
      ...(flightData?.flightSearchInfo?.fareGroup === 'STUDENT' && {
        studentId: traveller?.studentId || '',
      }),
    };

    reset(defaultValues);
  }, [traveller, reset]);

  useEffect(() => {
    const errorsObj = fieldErrors ? fieldErrors : {};
    Object.keys(errorsObj).forEach((key: any) => {
      setError(key, {
        type: 'manual',
        message: 'This is a required field',
      });
    });
  }, [fieldErrors, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="gender"
        control={control}
        defaultValue=""
        rules={{ required: 'Gender is required ' }}
        render={({ field, fieldState: { error } }) => (
          <Box mb="24px" display="flex" flexDirection="column">
            <Box>
              {GENDER.map((title) => (
                <OAChip
                  key={title}
                  label={title?.toLowerCase()}
                  selected={field?.value === title}
                  onClick={() => {
                    field?.onChange(title);
                    if (title) clearErrors('gender');
                  }}
                  sx={{ textTransform: 'capitalize', mr: '8px' }}
                />
              ))}
            </Box>
            {error && (
              <Typography mt="3px" ml="14px" variant="body3" color="#C01100">
                {error.message}
              </Typography>
            )}
          </Box>
        )}
      />
      <Controller
        name="firstName"
        control={control}
        defaultValue=""
        rules={{
          required: 'First name is required',
          minLength: {
            value: 2,
            message: 'First name must be at least 2 characters',
          },
          maxLength: {
            value: 30,
            message: 'Maximum character limit reached',
          },
          pattern: {
            value: /^[A-Za-z\s]+$/,
            message: 'First name should only contain alphabets and spaces',
          },
          validate: (value) => validateNameLength(value, 'firstName'),
        }}
        render={({ field, fieldState: { error } }) => (
          <OATextField
            {...field}
            label="First & middle name"
            fullWidth
            helperText={
              error?.message ? error?.message : 'First & middle name should be same as on your ID'
            }
            error={error?.message ? true : false}
            sx={{ mb: '24px' }}
            onChange={(e) => {
              field.onChange(e);
              trigger('firstName');
            }}
            variant={orgCode === 'ZOLVE' ? 'outlined' : 'filled'}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        defaultValue=""
        rules={{
          required: 'Last name is required',
          minLength: {
            value: 2,
            message: 'Last name must be at least 2 characters',
          },
          maxLength: {
            value: 30,
            message: 'Maximum character limit reached',
          },
          pattern: {
            value: /^[A-Za-z\s]+$/,
            message: 'Last name should only contain alphabets and spaces',
          },
          validate: (value) => validateNameLength(value, 'lastName'),
        }}
        render={({ field, fieldState: { error } }) => (
          <OATextField
            {...field}
            label="Last name"
            fullWidth
            helperText={error?.message ? error?.message : 'Last name should be same as on your ID'}
            error={error?.message ? true : false}
            sx={{ mb: '24px' }}
            onChange={(e) => {
              field.onChange(e);
              trigger('lastName');
            }}
            variant={orgCode === 'ZOLVE' ? 'outlined' : 'filled'}
          />
        )}
      />
      <Controller
        name="dob"
        control={control}
        rules={{ required: 'Date of birth is required' }}
        render={({ field, fieldState: { error } }) => (
          <OADatePopup
            label="Date of birth of traveller"
            value={field.value ? new Date(field.value) : null}
            onChange={(date: any) => {
              field.onChange(formatDate(date, 'yyyy-MM-dd'));
              trigger('dob');
            }}
            endDate={new Date(new Date().setDate(new Date().getDate() - 3))}
            dateFormat="dd/MM/yyyy"
            sx={{ width: '100%', mb: '24px' }}
            error={!!error}
            helperText={error?.message}
            orgCode={orgCode}
          />
        )}
      />
      {flightData?.international && (
        <>
          <Controller
            name="passportNumber"
            control={control}
            defaultValue={traveller?.passportNumber ?? ''}
            rules={{
              required: 'Passport number is required',
              minLength: {
                value: 8,
                message: 'Passport number must be at least 8 characters',
              },
              pattern: {
                value: /^[A-Za-z]{1}-?\d{7}$/,
                message: 'Please enter valid passport number',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <OATextField
                {...field}
                label="Passport Number"
                fullWidth
                error={!!error}
                helperText={error?.message}
                sx={{ mb: '24px' }}
                onChange={(e) => {
                  field.onChange(e);
                  trigger('passportNumber');
                }}
                variant={orgCode === 'ZOLVE' ? 'outlined' : 'filled'}
              />
            )}
          />
          <Controller
            name="passportIssueDate"
            control={control}
            rules={{ required: 'Passport issue date is required' }}
            render={({ field, fieldState: { error } }) => (
              <OADatePopup
                label="Passport issue date"
                value={field.value ? new Date(field.value) : null}
                onChange={(date: any) => {
                  field.onChange(formatDate(date, 'yyyy-MM-dd'));
                  trigger('passportIssueDate');
                }}
                endDate={new Date()}
                dateFormat="dd/MM/yyyy"
                sx={{ width: '100%', mb: '24px' }}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="passportExpiry"
            control={control}
            rules={{
              required: 'Passport expiry date is required',
              validate: (value: any) => {
                if (!value) {
                  return 'Invalid date format';
                }

                const valueDate: any = new Date(value);
                if (isNaN(valueDate)) {
                  return 'Invalid date format';
                }

                const tripEndDate = new Date(flightData?.tripEndDate);
                const todayDate = new Date();
                const sixMonthsAfterTripEnd = addMonths(tripEndDate, 6);

                if (valueDate?.setHours(0, 0, 0, 0) === todayDate?.setHours(0, 0, 0, 0)) {
                  return 'Your passport has expired';
                }
                if (valueDate < tripEndDate) {
                  return 'Your passport will expire before the travel date';
                }
                if (value < sixMonthsAfterTripEnd) {
                  return 'Passport expiry date should be at least 6 months after trip end date';
                }
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <OADatePopup
                label="Passport expiry date"
                value={field.value ? new Date(field.value) : null}
                onChange={(date: any) => {
                  field.onChange(formatDate(date, 'yyyy-MM-dd'));
                  trigger('passportExpiry');
                }}
                startDate={new Date()}
                dateFormat="dd/MM/yyyy"
                sx={{ width: '100%', mb: '24px' }}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="nationality"
            control={control}
            defaultValue={traveller?.nationality ?? 'IN'}
            rules={{}}
            render={({ field, fieldState: { error } }) => (
              <OATextField
                select
                label="Nationality"
                sx={{ width: '100%' }}
                value="IN"
                onChange={(e) => {
                  field.onChange(e);
                  trigger('nationality');
                }}
                variant={orgCode === 'ZOLVE' ? 'outlined' : 'filled'}
              >
                <MenuItem key="in" value="IN">
                  Indian
                </MenuItem>
              </OATextField>
            )}
          />
        </>
      )}
      {flightData?.flightSearchInfo?.fareGroup === 'STUDENT' && (
        <Controller
          name="studentId"
          control={control}
          defaultValue=""
          rules={{ required: 'Student ID is required' }}
          render={({ field, fieldState: { error } }) => (
            <OATextField
              {...field}
              label="Student ID"
              fullWidth
              error={!!error}
              helperText={error?.message}
              sx={{ mt: flightData?.international ? '24px' : 'revert' }}
              onChange={(e) => {
                field.onChange(e);
                trigger('studentId');
              }}
              variant={orgCode === 'ZOLVE' ? 'outlined' : 'filled'}
            />
          )}
        />
      )}
      <OAButton fullWidth variant="contained" color="secondary" sx={{ my: '24px' }} type="submit">
        {traveller?.id ? 'Save' : 'Add'} Traveller
      </OAButton>
    </form>
  );
};

export default Add;
