import { createApi } from '@reduxjs/toolkit/query/react';
import { EARN_CAMPAIGNS, USER } from '../store/apiUrls';
import { baseApi } from './baseApi';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseApi,
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (request) => ({
        url: `${USER}`,
        method: 'POST',
        body: {
          id: request?.id,
          ...(request?.username ? { fullName: request?.username } : {}),
          ...(request?.mobile ? { phoneNumber: request?.mobile } : {}),
          ...(request?.email ? { email: request?.email } : {}),
        },
        headers: { Authorization: `Bearer ${request?.token}` },
      }),
    }),
    getCampaigns: builder.query({
      query: (userId) => `${EARN_CAMPAIGNS}?userId=${userId}`,
    }),
  }),
});

export const { useCreateUserMutation, useLazyGetCampaignsQuery } = authApi;
