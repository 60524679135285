import { Box, Typography } from "@mui/material";
import { OAButton } from "../../components";

const AirportReview = ({ 
  displayText,
  handleContinue, 
}: { 
  displayText: any;
  handleContinue: () => void,
 }) => {
  return (
    <Box px='20px'>
      <Typography variant="h2" fontWeight={600} mb='10px'>Please review the airport</Typography>
      <Box>{displayText}</Box>
      <OAButton
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleContinue} sx={{ my: '30px' }}>
        Continue
      </OAButton>
    </Box>
  )
}

export default AirportReview;