import { createSlice } from '@reduxjs/toolkit';
export interface AuthProps {
  isLoggedIn: boolean;
  user: any;
  token: any;
  campaigns: any;
  deviceInfo: any;
  visibility: boolean;
}

const initialState = {
  isLoggedIn: false,
  user: null,
  token: null,
  campaigns: null,
  deviceInfo: null,
  visibility: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, token } = action.payload;
      state.isLoggedIn = true;
      state.user = user;
      state.token = token;
    },
    setToken: (state, action) => {
      state.isLoggedIn = true;
      state.token = action.payload;
    },
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setDeviceInfo: (state, action) => {
      state.deviceInfo = action.payload;
    },
    setVisibility: (state, action) => {
      state.visibility = action.payload;
    },
  },
});

// A small helper of user state for `useSelector` function.
export const getAuthInfo = (state: { auth: any }) => state.auth;

export const { setCredentials, setToken, setCampaigns, setDeviceInfo, setVisibility } =
  authSlice.actions;

export default authSlice.reducer;
