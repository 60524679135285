export const parseObject = (input: string) => {
  if (input === null || input === undefined) {
    return null;
  }

  try {
    // Try to parse if the input is a stringified object
    const parsed = typeof input === 'string' ? JSON.parse(input) : input;
    // Check if the parsed result is an object and not an array
    if (parsed !== null && typeof parsed === 'object' && !Array.isArray(parsed)) {
      return parsed;
    }
  } catch (error) {
    // If JSON.parse throws an error, it means input was not a valid stringified object
  }

  return null;
};
