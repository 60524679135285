import React from 'react';
import { Box, Container, Skeleton } from '@mui/material';
import { OACoinBanner } from '../../../../components';

interface CoinBannerSectionProps {
  isLoading: boolean;
  coinAmount: number;
}

const CoinBannerSection: React.FC<CoinBannerSectionProps> = ({ isLoading, coinAmount }) => (
  <Box position="sticky" top="90px" bgcolor="white" zIndex="10">
    <Container>
      {isLoading ? (
        <Skeleton variant="rectangular" height={24} sx={{ mb: '20px' }} />
      ) : (
        <OACoinBanner
          title="Niyo Coins to be earned on this trip"
          subTitle={coinAmount}
          isCurrency={false}
          sx={{ mb: '20px' }}
        />
      )}
    </Container>
  </Box>
);

export default CoinBannerSection;
