import * as React from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { flightTabs } from '../../constants';

interface TabPanelProps {
  selectedTab: string;
  tabValue: (val: string) => void;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props: TabPanelProps) {
  const [value, setValue] = React.useState(props?.selectedTab);

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
    props?.tabValue(newValue);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        variant="fullWidth" // Ensures equal width for each tab
        sx={{
          '.MuiTabs-flexContainer': {
            width: '100%', // Ensures that the container for tabs takes up full width
          },
        }}
      >
        {flightTabs.map((val: any, index: number) => (
          <Tab
            label={
              <Typography
                variant="body2"
                sx={{
                  fontWeight: val?.value === value ? 500 : 400,
                  color: val?.value === value ? '#14191F' : '#5A6068',
                }}
              >
                {val?.label}
              </Typography>
            }
            value={val?.value}
            {...a11yProps(val?.value)}
            sx={{ textTransform: 'none' }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
