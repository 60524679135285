import { Box, Typography } from '@mui/material';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import { ILayovers } from '../../type';

const LayoverInfo = ({ layover }: { layover: ILayovers }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{ my: '16px' }}
    >
      <Box display="flex" alignItems="center">
        <AirlineStopsIcon fontSize="small" sx={{ mr: '4px' }} />
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          Layover
        </Typography>
      </Box>
      <Typography variant="body3" sx={{ color: 'grey.800' }}>
        {layover?.typeDisplay ? <span>{layover?.typeDisplay} &bull; </span> : ``}
        {layover?.duration?.display} layover at {layover?.at}
      </Typography>
    </Box>
  );
};

export default LayoverInfo;
