import { Box, Skeleton, Typography } from '@mui/material';
import { getFormatedDate } from '../utils';

export const OAHeaderSubTitle = ({ travelDetail }: { travelDetail: any }) => {
  const dateFrom =
    travelDetail?.tripStart?.date ?? travelDetail?.tripStart?.datetime
      ? getFormatedDate(
          travelDetail?.tripStart?.zonalDateTime ??
            travelDetail?.tripStart?.date ??
            travelDetail?.tripStart?.datetime,
          'dd MMM'
        )
      : '';
  const dateTo = 
    travelDetail?.tripEnd?.date ?? travelDetail?.tripEnd?.datetime 
      ? getFormatedDate(
          travelDetail?.tripEnd?.zonalDateTime ?? 
            travelDetail?.tripEnd?.date ?? 
            travelDetail?.tripEnd?.datetime, 
          'dd MMM'
        ) : '';

  const travellerCount = travelDetail?.travellerCount;
  const totalTravelers =
    (parseInt(travellerCount?.adult) || 0) +
    (parseInt(travellerCount?.child) || 0) +
    (parseInt(travellerCount?.infant) || 0);

  let cabinType = travelDetail?.cabinType?.toLowerCase();
  // Replace underscores with spaces
  if (cabinType) {
    cabinType = cabinType.replace(/_/g, ' ');
  }

  const isRoundTrip = travelDetail?.tripType === 'R' || travelDetail?.tripType === 'ROUND_TRIP';
  const dateRange = isRoundTrip ? `${dateFrom} - ${dateTo}` : dateFrom;

  return (
    <Box display="flex" alignItems="center">
      <Typography
        variant="body3"
        sx={{ color: 'text.secondary', fontWeight: 400, textTransform: 'capitalize' }}
      >
        {dateFrom ? (
          <>
            {dateRange} • {totalTravelers} Traveller • {cabinType}
          </>
        ) : (
          <Skeleton sx={{ fontSize: '0.75rem', width: '177px' }} />
        )}
      </Typography>
    </Box>
  );
};
