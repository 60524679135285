export const getParamsBody = (params: any) => {
  const tripType = params.get('tripType');
  const cabin = params.get('cabin');
  const adults = params.get('adults');
  const childs = params.get('childs');
  const infants = params.get('infants');
  const from = params.get('from');
  const fromCity = params.get('fromCity');
  const to = params.get('to');
  const toCity = params.get('toCity');
  const fromDate = params.get('fromDate');
  const toDate = params.get('toDate');
  const fareGroup = params.get('fareGroup');

  return {
    tripType,
    cabinType: cabin,
    travellerCount: {
      adult: adults,
      child: childs,
      infant: infants,
    },
    from: { iata: from?.split('&')[0], city: fromCity },
    to: { iata: to, city: toCity },
    tripStart: { date: fromDate },
    ...(toDate && { tripEnd: { date: toDate } }),
    ...(fareGroup && { fareGroup }),
  };
};
