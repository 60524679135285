import axios from 'axios';
import { store } from '../store';
import { setMessage } from '../store/slices/snackbarSlice';
import { v4 as uuidv4 } from 'uuid';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  timeout: 20000,
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // Access the current state directly from the store
    const state: any = store.getState();

    const token = state?.auth?.token;
    const lat = state?.userInfo?.lat || 12.9716;
    const long = state?.userInfo?.long || 77.5946;

    // If there's a token, set the Authorization header
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    config.headers['lat'] = lat;
    config.headers['log'] = long;
    // serialize a small, non-sensitive part of the state to JSON
    const minimalState = {
      lastScreen: state.global.lastScreen,
      paymentInProgress: state.global.paymentInProgress,
    };
    const stateJson = JSON.stringify(minimalState);

    // Encode the serialized state to ensure it's safe to include in a header
    const encodedState = encodeURIComponent(stateJson);

    // Set the encoded state JSON in a custom header
    config.headers['global-state'] = encodedState;

    if (state?.userInfo?.isMock) config.headers['mock'] = true;
    // Generate a UUID and set it as "x-correlation-id"
    const uuid = uuidv4();
    config.headers['x-correlation-id'] = `oa-flight-pwa-${uuid}`;
    return config;
  },
  (error) => Promise.reject(error)
);

// Error message
const getErrorMessage = (error: any) => {
  console.log('getErrorMessage called', error);
  if (
    error?.response?.data?.errorCode &&
    ['513', '512']?.includes(error?.response?.data?.errorCode)
  ) {
    return;
  }
  if (error?.response?.data?.errors?.length) {
    return error.response.data.errors.map((e: any) => e.message).join(', ');
  }
  if (error?.code === 'ERR_BAD_RESPONSE') {
    return error.message;
  }
  if (error?.response) {
    return error.response.data?.detail;
  }
  if (error?.request) {
    return 'No response from server';
  }
  return 'Error setting up request';
};

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    let message = getErrorMessage(error);
    store.dispatch(setMessage(message));
    return Promise.reject(error);
  }
);

export default api;
