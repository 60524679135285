import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, IconButton, Stack, SxProps, Toolbar, Typography } from '@mui/material';
import { BookingStatusSkeleton } from '../pages/MyBooking/BookingStatus/BookingStatusSkeleton';
import { OAImage } from '.';
import { eventsTracker } from '../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { isMobileWebview } from '../utils';
import { useSelector } from '../store';
import { getAuthInfo } from '../store/slices/authSlice';

export const HANDLER_NAMES = {
  GET_NIYO_COINS: 'eventCoins',
  // Add other handler names as needed
};

interface OAHeaderProps {
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  showBackButton?: boolean;
  appBarSx?: SxProps;
  action?: ReactNode;
  loading?: boolean;
  backgroundImageText?: ReactNode;
}

export const OAHeader = ({
  title,
  subTitle,
  showBackButton = true,
  appBarSx,
  action,
  loading = false,
  backgroundImageText,
}: OAHeaderProps) => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { user } = useSelector(getAuthInfo);
  const isHomePage = window.location.pathname === '/' || window.location.pathname === '/home'; // Adjust '/home' as needed
  const handleBackBtnClick = () => {
    // Check if the current URL is the home page or contains a hash
    const isHomePage = window.location.pathname === '/' || window.location.pathname === '/home'; // Adjust '/home' as needed
    const hasHash = window.location.hash !== '';
    const isRedirectToApp = window?.location?.href?.includes('?redirectToApp');
    const isResultsPage = window.location.pathname === '/results';
    const isBookingPage = window?.location?.href?.includes('?review');
    const isBookingStatusPage = window?.location?.href?.includes('?overview');

    if (isHomePage || hasHash || isRedirectToApp) {
      console.log('Go back to app');
      if (isMobileWebview()) {
        eventsTracker(
          {
            flowName: 'Flight',
            screenName: 'Home',
            ctaAction: 'ExitFlight',
            screenDuration: '1',
            UserAction: {
              userAction: 'user has exited flight',
            },
          },
          posthog
        );
        window.back();
      }
    } else if (isBookingStatusPage) {
      navigate(`/my-bookings?review`);
    } else if (isBookingPage) {
      navigate('/');
    } else {
      console.log('Go back in history');
      if (isResultsPage) navigate('/');
      else navigate(-1);
    }
  };

  // Hardware back
  useEffect(() => {
    window.oaBack = () => {
      handleBackBtnClick();
    };
  }, []);
  const orgCode = user?.organizationCode;
  return (
    <AppBar
      position="static"
      sx={{
        ...appBarSx,
        borderBottom: orgCode === 'ZOLVE' ? 'none !important' : '1px solid #E6E6E6 !important',
      }}
    >
      <Toolbar>
        {showBackButton && (
          <IconButton
            edge={false}
            aria-label="back"
            onClick={handleBackBtnClick}
            sx={{
              padding: '16px 12px 16px 20px',
              ml: '-20px',
              color: 'white',
            }}
          >
            {isHomePage ? (
              <OAImage
                src={user?.organizationCode === 'ZOLVE' ? 'arrow-back.svg' : 'one-way-white.svg'}
                folder={orgCode === 'ZOLVE' ? 'zolveIcons' : 'icons'}
                alt="Go back"
              />
            ) : (
              <OAImage
                src="arrow-back.svg"
                folder={orgCode === 'ZOLVE' ? 'zolveIcons' : 'icons'}
                alt="Go back"
              />
            )}
          </IconButton>
        )}

        {!loading ? (
          <Stack direction="column" justifyContent="flex-end" 
            alignItems={!isHomePage && orgCode === 'ZOLVE' ? 'center' :"flex-start"}
            width={!isHomePage && orgCode === 'ZOLVE' ? '100%' : 'auto'}
          >
            {typeof title === 'string' ? (
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                {title}
              </Typography>
            ) : (
              title
            )}

            {typeof subTitle === 'string' ? (
              <Typography variant="body3" sx={{ color: 'grey.800', fontWeight: 400 }}>
                {subTitle}
              </Typography>
            ) : (
              subTitle
            )}
          </Stack>
        ) : (
          <BookingStatusSkeleton />
        )}

        <Box sx={{ flexGrow: 1 }} />
        {action && action}
      </Toolbar>
      {backgroundImageText && backgroundImageText}
    </AppBar>
  );
};
