import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { OAFareSummary, OAHeader, OASwipeableDrawer } from "../../components";
import Add from "./Add";
import { ContactDetails } from "./ContactDetails";

const Traveller = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDrawer = () => setIsOpen(!isOpen);

  const onClickHandler = () => {
    navigate("/summary");
  };

  return (
    <>
      <OAHeader title="Add travellers" />
      <ContactDetails />
      <Divider sx={{ border: "4px solid #F4F6F5", my: "24px" }} />

      <OAFareSummary onClick={onClickHandler} />

      <OASwipeableDrawer
        title="Add Traveller"
        open={isOpen}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
      >
        <Add />
      </OASwipeableDrawer>
    </>
  );
};

export default Traveller;
