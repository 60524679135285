import { Chip } from '@mui/material';
import { FC, ReactNode } from 'react';

interface OAChipProps {
  label: ReactNode;
  selected?: boolean;
  onClick?: () => void;
  sx?: any;
  key?: string;
}
const OAChip: FC<OAChipProps> = ({ key, label, selected, onClick, sx }) => {
  return (
    <Chip
      key={key}
      label={label}
      variant="outlined"
      sx={{
        bgcolor: selected ? '#D9FCF3' : undefined,
        borderColor: selected ? '#00C6A8' : undefined,
        mr: '8px',
        p: '5px 1px',
        ...sx
      }}
      onClick={onClick}
    />
  );
};

export default OAChip;
