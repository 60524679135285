import { Box, Card, Container, Divider, Step, StepLabel, Stepper, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { OAImage, OASwipeableDrawer } from '../../../components';
import RefundBreakup from './RefundBreakup';
import { useSelector } from '../../../store';
import { getCancellationState } from '../../../store/slices/cancellationSlice';
import { useState } from 'react';
import { displayDateTime, getFormatedDate } from '../../../utils';
import { getAuthInfo } from '../../../store/slices/authSlice';

const StatusCircle = ({ color }: { color: string }) => (
  <Box
    sx={{
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: color,
    }}
  />
);

const DynamicIcon = ({ status }: any) => {
  switch (status) {
    case 'COMPLETED':
      return <StatusCircle color="#49A27A" />;
    case 'REFUND_INITIATED':
    case 'REFUND_PROCESSING':
    case 'REFUND_SUCCESS':
      return <StatusCircle color="#49A27A" />;
    case 'REFUND_FAILED':
      return <StatusCircle color="#F1BF4D" />;
    default:
      return <OAImage src="clock.svg" folder="icons" alt="pending" />;
  }
};

const RefundDetails = ({ data }: any) => {
  const { cancellationData } = useSelector(getCancellationState);
  const { user } = useSelector(getAuthInfo);
  const [isRefundBreakupOpen, setIsRefundBreakupOpen] = useState<boolean>(false);
  const orgCode = user?.organizationCode;
  const bgColor = orgCode === 'ZOLVE' ? '#FFFFFF' : '#F4F6F5';
  const textColor = orgCode === 'ZOLVE' ? 'text.primary' : 'grey';
  const secondaryTextColor = orgCode === 'ZOLVE' ? 'text.secondary' : 'grey';
  const paymentSummary = data?.data?.paymentSummary;

  const toggleRefundBreakupDrawer = () => {
    setIsRefundBreakupOpen(!isRefundBreakupOpen);
  };

  const refundLogs = data?.data?.paymentSummary?.logs?.filter((log: any) => log?.type === 'REFUND');
  const isRefundInitiated = refundLogs?.some((log: any) => log?.status === 'REFUND_INITIATED');
  const cancellationRequestLog = data?.data?.logs?.filter(
    (log: any) => log?.status === 'CANCELLED'
  );
  const paymentSuccessfulLog = data?.data?.orderAmount?.paymentSummary?.logs?.filter(
    (log: any) => log?.status === 'PAYMENT_SUCCESSFUL'
  );
  const bookingFailedLog = data?.data?.logs?.filter((log: any) => log?.status === 'BOOKING_FAILED');
  const isRefundFailed = refundLogs?.some((log: any) => log?.status === 'REFUND_FAILED');

  const refundSteps = [
    { key: 'REFUND_INITIATED', label: 'Refund initiated' },
    ...(!isRefundFailed ? [{ key: 'REFUND_PROCESSING', label: 'Refund processing' }] : []),
    ...(isRefundFailed ? [{ key: 'REFUND_FAILED', label: 'Refund pending' }] : []),
    { key: 'REFUND_SUCCESS', label: 'Refund successful' },
  ];

  const getLogDetails = (status: any) => {
    const log = refundLogs?.find((log: any) => log?.status === status);
    return log
      ? { icon: <DynamicIcon status={log?.status} />, date: displayDateTime(log?.createdAt) }
      : { icon: <DynamicIcon status="PENDING" />, date: '--' };
  };

  const formatPaymentMethod = (inputString: any) => {
    // Check if inputString is empty or undefined
    if (!inputString) {
      return inputString;
    }

    return inputString
      .toLowerCase()
      .replace(/_/g, ' ')
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Box sx={{ bgcolor: bgColor, py: orgCode === 'ZOLVE' ? 0 : '15px' }}>
      <Typography
        variant="body2"
        fontWeight={600}
        p="12px 20px"
        color={textColor}
        sx={orgCode === 'ZOLVE' ? { textTransform: 'uppercase' } : {}}
      >
        Refund details
      </Typography>
      <Container sx={{ mb: '10px' }}>
        <Card>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body3" color={textColor}>
              Paid via{' '}
              {paymentSummary?.paymentMode && formatPaymentMethod(paymentSummary?.paymentMode)}
            </Typography>
            <Typography variant="body3" color={textColor}>
              {paymentSummary?.payedAt &&
                getFormatedDate(paymentSummary?.payedAt, 'EEE, dd MMM, yyyy HH:MM')}
            </Typography>
          </Box>
          <Divider sx={{ border: '1px solid #F4F6F5', my: '12px' }} />
          <Box>
            <Stepper activeStep={0} orientation="vertical">
              {cancellationRequestLog?.length > 0 && (
                <Step key={0}>
                  {' '}
                  <StepLabel
                    StepIconProps={{ style: { color: 'green', fontSize: '12px' } }}
                    icon={<StatusCircle color="#49A27A" />}
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Cancellation Initiated</Typography>
                      <Typography variant="caption" color={secondaryTextColor}>
                        {displayDateTime(cancellationRequestLog?.[0]?.createdAt)}
                      </Typography>
                    </Box>
                  </StepLabel>
                </Step>
              )}
              {paymentSuccessfulLog?.length > 0 && !cancellationRequestLog?.length && (
                <Step key={0}>
                  {' '}
                  <StepLabel
                    StepIconProps={{ style: { color: 'green', fontSize: '12px' } }}
                    icon={<StatusCircle color="#49A27A" />}
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Payment Successful</Typography>
                      <Typography variant="caption" color={secondaryTextColor}>
                        {displayDateTime(paymentSuccessfulLog?.[0]?.createdAt)}
                      </Typography>
                    </Box>
                  </StepLabel>
                </Step>
              )}
              {bookingFailedLog?.length > 0 && (
                <Step key={0}>
                  {' '}
                  <StepLabel
                    StepIconProps={{ style: { color: 'green', fontSize: '12px' } }}
                    icon={<StatusCircle color="#C01100" />}
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Booking Failed</Typography>
                      <Typography variant="caption" color={secondaryTextColor}>
                        {displayDateTime(bookingFailedLog?.[0]?.createdAt)}
                      </Typography>
                    </Box>
                  </StepLabel>
                </Step>
              )}
              {refundSteps?.map(({ key, label }) => {
                const { icon, date } = getLogDetails(key);
                return (
                  <Step key={key}>
                    <StepLabel icon={icon}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="body2">{label}</Typography>
                        <Typography variant="caption" color={secondaryTextColor}>
                          {date}
                        </Typography>
                      </Box>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Divider sx={{ border: '1px solid #F4F6F5', my: '12px' }} />
          </Box>
          {isRefundInitiated && (
            <Box display="flex" justifyContent="space-between" onClick={toggleRefundBreakupDrawer}>
              <Typography>Refund Summary</Typography>
              <ChevronRightIcon sx={{ color: 'text.secondary' }} />
            </Box>
          )}
          {isRefundFailed && (
            <Box p="10px 15px" mt="20px" bgcolor="#F4F6F5" borderRadius="8px" display="flex">
              <Box mr="10px" mt="2px">
                <OAImage src="info-icon.svg" folder="icons" alt="info" />
              </Box>
              <Box>
                <Typography variant="body3">
                  Your refund is taking time but rest assured, we're on top of it. Reach out to our
                  support team for any further assistance.
                </Typography>
              </Box>
            </Box>
          )}
        </Card>
      </Container>
      <OASwipeableDrawer
        title={<Typography fontWeight={600}>Refund Summary</Typography>}
        open={isRefundBreakupOpen}
        onOpen={toggleRefundBreakupDrawer}
        onClose={toggleRefundBreakupDrawer}
      >
        <RefundBreakup data={data} cancellationData={cancellationData} />
      </OASwipeableDrawer>
    </Box>
  );
};

export default RefundDetails;
