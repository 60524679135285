import { createSlice } from '@reduxjs/toolkit';

export interface UserInfoState {
  coins: any;
  lat: number;
  long: number;
  isMock: boolean;
}
/**
 * Default state object with initial values
 */
const initialState: UserInfoState = {
  coins: 0,
  lat: 12.9716,
  long: 77.5946,
  isMock: false,
};
/**
 * Create a slice as a reducer containing actions.
 */
export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setLatLong: (state, action) => {
      state.lat = action?.payload?.lat ?? 12.9716;
      state.long = action?.payload?.long ?? 77.5946;
    },
    setMock: (state, action) => {
      state.isMock = action.payload;
    },
    setCoins: (state, action) => {
      state.coins = action.payload;
    },
  },
});

// A small helper of user state for `useSelector` function.
export const getUserInfo = (state: { userInfo: UserInfoState }) => state.userInfo;

export const { setLatLong, setMock, setCoins } = userInfoSlice.actions;

export default userInfoSlice.reducer;
