export const VegIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_5496_6281)">
      <rect width="11.8261" height="12" rx="2" fill="white"></rect>
      <rect width="12" height="12" rx="2" fill="white" stroke="#CC1414" stroke-width="1.5"></rect>
      <circle cx="6" cy="6" r="3" fill="#CC1414"></circle>
      <g clip-path="url(#clip1_5496_6281)">
        <rect width="12" height="12" rx="2" fill="white"></rect>
        <rect width="12" height="12" rx="2" fill="white" stroke="#CC1414" stroke-width="1.5"></rect>
        <circle cx="6" cy="6" r="3" fill="#CC1414"></circle>
        <g clip-path="url(#clip2_5496_6281)">
          <rect width="12" height="12" rx="2" fill="white"></rect>
          <rect
            width="12"
            height="12"
            rx="2"
            fill="white"
            stroke="#00B28A"
            stroke-width="1.5"
          ></rect>
          <circle cx="6" cy="6" r="3" fill="#00B28A"></circle>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5496_6281">
        <rect width="11.8261" height="12" rx="2" fill="white"></rect>
      </clipPath>
      <clipPath id="clip1_5496_6281">
        <rect width="12" height="12" rx="2" fill="white"></rect>
      </clipPath>
      <clipPath id="clip2_5496_6281">
        <rect width="12" height="12" rx="2" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
);
