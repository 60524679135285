import { Box, Skeleton } from '@mui/material';
import { OAChip } from '../../components';

interface TabSwitchSkeletonProps {
  orgCode: string;
}

const TabSwitchSkeleton:React.FC<TabSwitchSkeletonProps> = ({ orgCode }) => {
  return (
    <>
   {orgCode === 'ZOLVE' ? 
    <Box display='flex'>
      <Skeleton variant='rectangular' width="85px" height='32px' sx={{borderRadius: '16px'}} />
      <Skeleton variant='rectangular' width="72px" height='32px' sx={{ml: '12px', borderRadius: '16px'}} />
    </Box>
   :
   <OAChip
      label={
        <Box display="flex" alignItems="center">
          <Skeleton
            variant="rectangular"
            width="300px"
            height="30px"
            sx={{ backgroundColor: '#f4f6f5', borderRadius: '16px' }}
          />
          <Skeleton
            variant="rectangular"
            width="300px"
            height="30px"
            sx={{ backgroundColor: '#f4f6f5', borderRadius: '16px', marginLeft: '10px' }}
          />
        </Box>
      }
      variant="outlined"
      sx={{ width: '100%', height: '40px', backgroundColor: '#ffffff' }}
    />}
    </>
  );
};

export default TabSwitchSkeleton;
