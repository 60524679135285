import { COIN_VALUE_IN_RS, DISCOUNT_PERCENTAGE } from '../constants';

export const getBurnCoin = (flightPrice: number, coins: number) => {
  if (coins <= 0) {
    return 0;
  }

  const discount = Math.floor(coins / COIN_VALUE_IN_RS);
  const discountedPrice = Math.max(flightPrice - discount, 0);
  const actualDiscount = flightPrice - discountedPrice;
  const maxDiscountInCoins = Math.floor(flightPrice * DISCOUNT_PERCENTAGE * COIN_VALUE_IN_RS);
  const actualDiscountInCoins = actualDiscount * COIN_VALUE_IN_RS;

  return Math.min(actualDiscountInCoins, maxDiscountInCoins);
};
