import { FC, useState } from 'react';
import { OAChip, OARadioGroup, OASwipeableDrawer } from '../../../components';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useSelector } from 'react-redux';
import { getSearchFlightState, getSortState } from '../../../store/slices/searchFlightSlice';
import { SORT_OPTIONS } from '../../../constants';
import { Box, Tab, Tabs } from '@mui/material';
import OASwitchComponent from '../../../components/OASwitchComponent';
import { getUserInputState } from '../../../store/slices/userInputSlice';

interface SortProps {
  currentSortTab: number;
  setCurrentSortTab: (value: number) => void;
  onClick: (value: string) => void;
  onClickClearSort: () => void;
  orgCode: string;
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Sort: FC<SortProps> = ({
  currentSortTab,
  setCurrentSortTab,
  onClick,
  onClickClearSort,
  orgCode,
}) => {
  const sortValue = useSelector(getSortState);
  const { data } = useSelector(getSearchFlightState);
  const { travelDetail } = useSelector(getUserInputState);

  const from = travelDetail?.from?.iata;
  const to = travelDetail?.to?.iata;

  const dataOnward = data?.results?.onward;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sortData, setSortData] = useState<any>({
    onward: sortValue,
    return: sortValue,
  });

  const toggleLabes = [`${from} -> ${to}`, `${to} -> ${from}`];

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectClass = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortData((prevState: any) => ({
      ...prevState,
      [currentSortTab === 0 ? 'onward' : 'return']: event.target.value,
    }));
    onClick(event.target.value);
    toggleDrawer();
  };

  const handleToggleSwitch = (value: number) => {
    setCurrentSortTab(value);
  };

  return (
    <>
      <OAChip
        icon={
          <FilterListIcon
            fontSize="small"
            sx={{ color: orgCode === 'ZOLVE' ? 'white' : '#5A6068 !important' }}
          />
        }
        label="Sort"
        onClick={toggleDrawer}
        selected={sortValue}
        disabled={dataOnward?.length > 0 ? false : true}
        sx={{
          ml: '20px',
          bgcolor: orgCode === 'ZOLVE' ? '#141414' : 'primary.light',
          color: orgCode === 'ZOLVE' ? 'white' : 'black',
          borderColor: orgCode === 'ZOLVE' ? '#141414' : 'primary.main',
        }}
      />
      <OASwipeableDrawer
        title={'Sort by'}
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        {travelDetail?.tripType === 'R' && orgCode !== 'ZOLVE' && (
          <Box mb="20px">
            <OASwitchComponent
              value={currentSortTab}
              onChange={handleToggleSwitch}
              labels={toggleLabes}
            />
          </Box>
        )}
        {travelDetail?.tripType === 'R' && orgCode === 'ZOLVE' && (
          <Tabs
            sx={{
              '.MuiTabs-flexContainer': {
                display: 'block !important',
              },
              borderBottom: '1px solid #E2E2E2',
              mb: '20px',
              mx: '-20px',
            }}
            value={currentSortTab}
            aria-label="basic-tabs"
          >
            <Tab
              label={`${from} - ${to}`}
              sx={{
                textTransform: 'none',
              }}
              {...a11yProps(0)}
              onClick={() => handleToggleSwitch(0)}
            />
            <Tab
              label={`${to} - ${from}`}
              sx={{
                textTransform: 'none',
              }}
              {...a11yProps(1)}
              onClick={() => handleToggleSwitch(1)}
            />
          </Tabs>
        )}
        <OARadioGroup
          label=""
          options={SORT_OPTIONS}
          value={currentSortTab === 0 ? sortData?.onward : sortData?.return}
          onChange={handleSelectClass}
        />
        <Box sx={{ height: '24px' }}></Box>
        {/* <OAButton
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ my: '24px' }}
          onClick={handleApplyBtn}
        >
          Apply
        </OAButton> */}
      </OASwipeableDrawer>
    </>
  );
};

export default Sort;
