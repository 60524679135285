import styled from '@emotion/styled';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';

export const Tab = styled(BaseTab)`
  color: #5a6068;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: transparent;
  width: 100%;

  padding: 8px 12px;
  margin: 3px;
  border: none;
  border-radius: 20px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: black;
    color: white;
  }

  &:focus {
    color: #fff;
    outline: 'none';
  }

  &.${tabClasses.selected} {
    background-color: #0e1226;
    color: #fdfdfd;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  transition: 'left 0.9s ease';
`;

export const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  
  background-color: #FDFDFD;
  border-radius: 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: none;
  border: 1px solid #E6EAE9
  `
);
