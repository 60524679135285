import { FC, useState, useEffect } from 'react';

interface OACountdownProps {
  duration: number;
  onComplete: () => void;
}

export const OACountdown: FC<OACountdownProps> = ({ duration, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    // Exit early when we reach 0
    if (timeLeft <= 0) {
      onComplete();
      return;
    }

    // Save intervalId to clear the interval when the
    // component re-renders or unmounts
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // Add timeLeft as a dependency to re-run the effect
    // when we update it
  }, [timeLeft, onComplete]);

  // Convert timeLeft to minutes and seconds
  const minutes = Math.floor(timeLeft / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (timeLeft % 60).toString().padStart(2, '0');

  return (
    <>
      Time left: {minutes} : {seconds} seconds
    </>
  );
};
