import { Box, Skeleton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';

interface OverlayIconProps {
  isSelected: boolean;
  isLoading: boolean;
}

const OverlayIcon: FC<OverlayIconProps> = ({ 
  isSelected,
  isLoading
}) => {
  return (
    <Box
      sx={{
        borderRadius: '3px',
        ...(!isLoading ? { bgcolor: isSelected ? '#009E82' : '#FDFDFD' } : {}),
        width: 16,
        height: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...(!isLoading ? { border: '1px solid' } : {}),
        ...(!isLoading ? { borderColor: isSelected ? '#009E82' : '#DEE2E1' } : {}),
        position: 'absolute',
        top: '-6px',
        right: '-6px',
      }}
    >
     {isLoading ? (
        <Skeleton variant="rectangular" height={16} width={16} />
      ) : isSelected ? (
        <DoneIcon sx={{ fontSize: '1rem', color: '#FDFDFD', height: '16px', width: '16px' }} />
      ) : (
        <AddIcon sx={{ fontSize: '1rem', color: '#009E82', height: '16px', width: '16px' }} />
      )}
    </Box>
  );
};

export default OverlayIcon;
