import { createApi } from '@reduxjs/toolkit/query/react';
import { FARE_AND_MINI_RULE, GET_FARE_PRICE_CALENDAR, SEARCH_FLIGHT } from '../store/apiUrls';
import { baseApi } from './baseApi';

export const flightSearchApi = createApi({
  reducerPath: 'flightSearchApi',
  baseQuery: baseApi,
  endpoints: (builder) => ({
    flightSearch: builder.query({
      query: (body) => ({ url: SEARCH_FLIGHT, method: 'POST', body }),
    }),
    fetchFareAndMiniRule: builder.query({
      query: (body) => ({ url: FARE_AND_MINI_RULE, method: 'POST', body }),
    }),
    getFarePrice: builder.mutation({
      query: (body) => ({ url: GET_FARE_PRICE_CALENDAR, method: 'POST', body }),
    }),
  }),
});

export const { useFlightSearchQuery, useLazyFetchFareAndMiniRuleQuery, useGetFarePriceMutation } =
  flightSearchApi;
