import { COIN_VALUE_IN_RS, DISCOUNT_PERCENTAGE } from '../constants';

export const getDiscountPrice = (flightPrice: number, coins: number) => {
  if (coins <= 0) {
    return flightPrice;
  }

  const coinRsValue = Math.floor(coins / COIN_VALUE_IN_RS);
  const discountedPrice = Math.max(flightPrice - coinRsValue, 0);

  const maxDiscountedPrice = Math.ceil(flightPrice * (1 - DISCOUNT_PERCENTAGE));
  return Math.max(discountedPrice, maxDiscountedPrice);
};
