import { FC } from 'react';
import { FormGroup } from '@mui/material';
import FilterItem from './FilterItem';

interface FilterListProps {
  data: any;
  name: string;
  isImgShow?: boolean;
  filterData: any;
  filtersCount: any;
  countOfStops: any;
  countOfAirports: any;
  onChange?: any;
}

const FilterList: FC<FilterListProps> = ({
  data,
  name,
  filterData,
  isImgShow = false,
  onChange,
  filtersCount,
  countOfStops,
  countOfAirports,
}) => {
  const airIndiaMergedCount =
    (filtersCount['Air India Express'] || 0) + (filtersCount['Airasia_india'] || 0);
  return (
    <FormGroup>
      {data?.map((obj: any, index: number) => {
        const isDisabled =
          name === 'airlines'
            ? !(obj?.merged ? airIndiaMergedCount : filtersCount[obj?.label] || 0)
            : name === 'stops'
            ? !(obj?.value === '1+' ? countOfStops['2'] : countOfStops[obj?.value])
            : name === 'airports'
            ? !countOfAirports[obj?.label]
            : false;
        const checked = filterData?.[name]?.includes(obj?.value);
        return (
          <FilterItem
            obj={obj}
            index={index}
            isDisabled={isDisabled}
            name={name}
            onChange={onChange}
            countOfStops={countOfStops}
            filtersCount={filtersCount}
            countOfAirports={countOfAirports}
            airIndiaMergedCount={airIndiaMergedCount}
            checked={checked}
            isImgShow={isImgShow}
          />
        );
      })}
    </FormGroup>
  );
};

export default FilterList;
