import { Box } from '@mui/material';
import { OAImage } from '../../../components';

const LabelContent = ({ label, orgCode }: { label: string, orgCode: string | undefined; }) => (
  <Box display="flex" alignItems="center">
    {orgCode !== 'ZOLVE' && <OAImage src="insert-invitation.svg" folder="icons" alt="calendar" sx={{ mr: '10px' }} />}
    {label}
  </Box>
);

export default LabelContent;
