import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import Seat from "./Seat";

interface SeatDetail {
  columnId: string;
  price: number;
  isAvailable: boolean;
  seatCharacteristics: string[];
  isFree: boolean;
  isRefundable: boolean;
  seatCoordinates: {
    ycoordinate: number;
    xcoordinate: number;
  };
  seatNumber: string;
  paxTypes: string[];
}

interface RowProps {
  row: {
    rowCharacteristics: string;
    rowId: string;
    seatDetails: SeatDetail[];
  };
  totalColumns: number;
  totalTravellers: number;
  updateSmbAction: any;
  selectedSmbData: any;
  selectedSegment: { from: string; to: string };
  apiResponse: any;
  priceColorArray?: any;
  showColumnNames: boolean;
  allColumns: string[];
}

const Row: React.FC<RowProps> = ({
  row,
  totalColumns,
  totalTravellers,
  updateSmbAction,
  selectedSmbData,
  selectedSegment,
  apiResponse,
  priceColorArray,
  showColumnNames,
  allColumns
}) => {

  const formattedSeats = useMemo(() => {
    const availableColumnIds = new Set(row.seatDetails.map(seat => seat.columnId));
    
    return allColumns.map((columnId) => {
      if (!availableColumnIds.has(columnId)) {
        return {
          columnId,
          price: 0,
          isAvailable: false,
          seatCharacteristics: [],
          isFree: false,
          isRefundable: false,
          seatCoordinates: { ycoordinate: 0, xcoordinate: 0 },
          seatNumber: '',
          paxTypes: [],
        };
      }
      return row.seatDetails.find((seat) => seat.columnId === columnId) || {
        columnId,
        price: 0,
        isAvailable: false,
        seatCharacteristics: [],
        isFree: false,
        isRefundable: false,
        seatCoordinates: { ycoordinate: 0, xcoordinate: 0 },
        seatNumber: '',
        paxTypes: [],
      };
    });
  }, [allColumns, row.seatDetails]);

  const chunkSize = useMemo(() => {
    if (totalColumns <= 3) return totalColumns;
    if (totalColumns <= 6) return Math.ceil(totalColumns / 2);
    return Math.ceil(totalColumns / 3);
  }, [totalColumns]);
  
  const seatChunks = useMemo(() => {
    return formattedSeats.reduce((resultArray: any[], item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
  }, [formattedSeats, chunkSize]);
  
  const isEmergencyExitRow = row.rowCharacteristics === "EMERGENCY_ROW";

  return (
    <Box position="relative">
      {showColumnNames && (
        <Box display="flex" justifyContent="space-between" alignItems="center" mx="22px" mb="4px">
          {seatChunks.map((chunk: any, chunkIndex: number) => (
            <React.Fragment key={`column-names-${chunkIndex}`}>
              {chunk.map((seat: any) => (
                <Typography
                  key={`column-name-${seat.columnId}`}
                  variant="body3"
                  fontWeight={600}
                  minWidth="32px"
                  textAlign="center"
                  sx={{ textAlign: "center" }}
                >
                  {seat.columnId}
                </Typography>
              ))}
              {chunkIndex < seatChunks.length - 1 && (
                <Typography
                  variant="body3"
                  mx="8px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontWeight={600}
                  sx={{ minWidth: "32px", textAlign: "center" }}
                >
                  
                </Typography>
              )}
            </React.Fragment>
          ))}
        </Box>
      )}
      <Box 
        display="flex" 
        justifyContent="space-between" 
        alignItems="center" 
        mx="20px" 
        my={isEmergencyExitRow ? "30px" : "10px"}
        position="relative"
      >
        {isEmergencyExitRow && (
          <>
            <Typography
              variant="body4"
              sx={{
                position: "absolute",
                left: "-38px",
                top: "50%",
                transform: 'translateY(-50%) rotate(-90deg)',
                width: "60px",
                textAlign: "center",
                color: '#8C9097'
              }}
            >
              EXIT
            </Typography>
            <Typography
              variant="body4"
              sx={{
                position: "absolute",
                right: "-38px",
                top: "50%",
                transform: 'translateY(-50%) rotate(90deg)',
                width: "60px",
                textAlign: "center",
                color: '#8C9097'
              }}
            >
              EXIT
            </Typography>
          </>
        )}
        {seatChunks.map((chunk: any, chunkIndex: number) => (
          <React.Fragment key={chunkIndex}>
            {chunk.map((seat: any) => (
              <Seat 
                key={`${seat.columnId}-${row.rowId}`}
                seat={seat} 
                totalTravellers={totalTravellers} 
                updateSmbAction={updateSmbAction}
                selectedSegment={selectedSegment}
                apiResponse={apiResponse}
                priceColorArray={priceColorArray}
              />
            ))}
            {chunkIndex < seatChunks.length - 1 && (
              <Typography
                variant="body3"
                mx="8px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight={600}
                sx={{ minWidth: "20px", textAlign: "center" }}
              >
                {row.rowId}
              </Typography>
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(Row);