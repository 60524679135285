import { FC } from 'react';
import { Box, Card, Fab, Skeleton, Typography, styled } from '@mui/material';
import OverlayIcon from './OverlayIcon';
import { OAImage } from '../../../components';

const CATEGORY = {
  Seats: {
    color: '#13387E',
    icon: 'smb-seat-icon.svg',
    bgColor: '#E6EEFF',
  },
  Meals: {
    color: '#8E3214',
    icon: 'smb-meal-icon.svg',
    bgColor: '#FFEACE',
  },
  Baggage: {
    color: '#6B1299',
    icon: 'smb-baggage-icon.svg',
    bgColor: '#F0E2FF',
  },
};

const ShimmerFab = styled(Fab)({
  backgroundColor: '#009E82 !important',
  p: '5px 10px',
  width: '100%',
  textTransform: 'none',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '24px',
  boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '-4.5em',
    height: '100%',
    width: '3em',
    backgroundColor: 'rgba(255,255,255,0.5)',
    // animation: 'shimmer 1.5s infinite',
    // animationDelay: '2s',
  },
  // '@keyframes shimmer': {
  //   '0%': {
  //     transform: 'skewX(-45deg) translateX(0)',
  //   },
  //   '100%': {
  //     transform: 'skewX(-45deg) translateX(13.5em)',
  //   },
  // },
});

interface CardItemProps {
  title: 'Seats' | 'Meals' | 'Baggage';
  subTitle: string;
  isSelected: boolean;
  isLoading: boolean;
  itemCount: number;
  totalItemsToBeAdded: number;
}
const CardItem: FC<CardItemProps> = ({
  title,
  subTitle,
  isSelected,
  isLoading,
  itemCount,
  totalItemsToBeAdded,
}) => {
  return (
    <Box sx={{ position: 'relative'}}>
      <OverlayIcon isSelected={isSelected} isLoading={isLoading} />
      {/* {isLoading ? (
        <Skeleton variant="rectangular" height="34px" sx={{ borderRadius: '24px' }} />
      ) : (
        <ShimmerFab variant="extended" size="small" color="primary">
          {CATEGORY[title].icon}
          <Typography variant="body3" sx={{ ml: '6px', letterSpacing: '0.2px' }}>
            {title}
          </Typography>
        </ShimmerFab>
      )} */}

      <Card sx={{ p: '8px' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography
              sx={{
                fontSize: '0.75rem',
                fontWeight: 600,
                lineHeight: '20px',
                color: CATEGORY[title].color,
              }}
            >
              {isLoading ? <Skeleton variant="text" width={40} /> : title}
            </Typography>
            <Typography
              sx={{ fontSize: '0.625rem', fontWeight: 400, lineHeight: '15px', color: '#5A6068' }}
            >
              {isLoading ? <Skeleton variant="text" width={50} /> : isSelected ? `${itemCount} / ${totalItemsToBeAdded} added` : subTitle}
            </Typography>
          </Box>
          <Box>
            {isLoading ? (
              <Skeleton variant="rectangular" width={24} height={24} />
            ) : (
              <OAImage src={CATEGORY[title].icon} alt="seat" />
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default CardItem;
