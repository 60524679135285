import { Box, FormControlLabel, Typography, styled } from '@mui/material';
import { OAAirlineLogo, OACheckbox } from '../../../components';
import React, { useState } from 'react';

const CustomFormControlLabel = styled(FormControlLabel)({
  justifyContent: 'space-between',
  width: '100%',
  margin: 0,
});

const CustomLabelContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const LabelWithImage = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const FilterItem = React.memo(
  ({
    obj,
    index,
    isDisabled,
    name,
    onChange,
    countOfStops,
    filtersCount,
    countOfAirports,
    airIndiaMergedCount,
    checked: checkedProp,
    isImgShow,
  }: any) => {
    const [checked, setChecked] = useState<boolean>(checkedProp);

    const handleChange = (event: any) => {
      setChecked(event.target.checked);
      // Delay the execution of the global state update to allow UI to respond quickly
      setTimeout(() => {
        onChange?.(event, name);
      }, 100);
    };
    return (
      <Box>
        {isDisabled && name === 'airlines' ? null : (
          <CustomFormControlLabel
            sx={{ mb: '12px' }}
            key={index}
            control={
              <CustomLabelContent>
                {obj?.info && (
                  <Typography
                    variant="body3"
                    component="span"
                    sx={{
                      fontWeight: 600,
                      color: 'text.secondary',
                    }}
                  >
                    {obj?.value === 'MID_DAY'
                      ? '12 PM - 4 PM'
                      : obj?.value === 'EVENING'
                      ? '4 PM - 8 PM'
                      : obj?.info}
                  </Typography>
                )}
                <Box display="flex" alignItems="center">
                  {name === 'airlines' && (
                    <Typography variant="body3" color="text.secondary">
                      {obj?.merged ? airIndiaMergedCount : filtersCount[obj?.label] || 0}
                    </Typography>
                  )}
                  {name === 'stops' && (
                    <Typography variant="body3" color={'text.secondary'}>
                      {(obj?.value === '1+' ? countOfStops['2'] : countOfStops[obj?.value]) ?? 0}
                    </Typography>
                  )}
                  {name === 'airports' && (
                    <Typography variant="body3">{countOfAirports[obj?.label] ?? 0}</Typography>
                  )}
                  <Box ml="6px">
                    <OACheckbox
                      name={name}
                      value={obj?.value}
                      checked={checked || false}
                      onChange={handleChange}
                    />
                  </Box>
                </Box>
              </CustomLabelContent>
            }
            label={
              <LabelWithImage>
                {isImgShow && (
                  <OAAirlineLogo
                    airlineCode={obj?.value}
                    sx={{
                      width: '24px',
                      height: '24px',
                      marginRight: '8px',
                    }}
                  />
                )}
                <Typography variant="body2" component="span" mr="8px" color="text.secondary">
                  {obj?.label}
                </Typography>
              </LabelWithImage>
            }
            labelPlacement="start"
          />
        )}
      </Box>
    );
  }
);

export default FilterItem;
