import React, { useCallback, memo, useMemo } from 'react';
import { Box } from "@mui/material";
import Row from "./Row";
import EmptySmb from '../EmptySmb';

interface DeckProps {
  seatMap: any;
  selectedSegment: { from: string, to: string };
  totalTravellers: number;
  updateSmbAction: any;
  selectedSmbData: any;
  priceColorArray?: any;
}

const Deck: React.FC<DeckProps> = ({ 
  seatMap, 
  selectedSegment, 
  totalTravellers, 
  updateSmbAction,
  selectedSmbData,
  priceColorArray
}) => {
  const getSeatMapBasedOnSegment = useCallback((smbData: any[], selectedSegment: { from: string, to: string }) => {
    const segmentAncillariesArray = smbData
      ?.filter((item: any) => item?.segmentAncillaries)
      ?.map((item: any) => item?.segmentAncillaries)
      ?.flat();

    const seatAncillaries = segmentAncillariesArray?.find((segmentAncillary: any) =>
      segmentAncillary?.originDestinationInfo?.from === selectedSegment?.from &&
      segmentAncillary?.originDestinationInfo?.to === selectedSegment?.to
    );

    return seatAncillaries?.seatAncillaries?.aircraftLayout;
  }, []);

  const seatMapBasedOnSegment = useMemo(() => getSeatMapBasedOnSegment(seatMap, selectedSegment), [selectedSegment]);
  
  const compartments = seatMapBasedOnSegment?.lowerDeck?.compartments || {};
  const rows: any = Object.values(compartments)?.flatMap((compartment: any) => compartment?.rows || []);
  const groupedRows = useMemo(() => groupRows(rows), [selectedSegment]);
  
  // extracting all unique column IDs from the seat details
  const allColumns = useMemo(() => {
    const columnsSet = new Set<string>();
    rows?.forEach((row: any) => {
      row?.seatDetails?.forEach((seat: any) => {
        columnsSet?.add(seat.columnId);
      });
    });
    return Array?.from(columnsSet).sort();
  }, [rows]);  

  const totalColumns = allColumns?.length || 6; // Default to 6 if no rows
  
  return ( 
    <>
      {!seatMapBasedOnSegment ? 
        <EmptySmb text='Seat selection is not available. But you can select seats during web check-in.' />
      : 
        <Box
          pt="50px"
          width={totalColumns <= 9 ? 'revert' : '100%'}
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            mx: totalColumns <= 9 ? '30px' : '20px',
            '@media (max-width: 600px)': {
              mx: '10px',
            },
          }}
          borderRight="1px solid #8C9097"
          borderLeft="1px solid #8C9097"
          bgcolor='white'
          overflow='scroll'
        >
          {groupedRows?.map((group, index) => (
            <Box key={index} mb={index < groupedRows.length - 1 ? '10px' : '0'}>
              {group?.map((row: any, rowIndex: number) => (
                <Row 
                  key={row?.rowId} 
                  row={row} 
                  totalColumns={totalColumns} 
                  totalTravellers={totalTravellers} 
                  updateSmbAction={updateSmbAction}
                  selectedSmbData={selectedSmbData}
                  selectedSegment={selectedSegment}
                  apiResponse={seatMap}
                  priceColorArray={priceColorArray}
                  showColumnNames={index === 0 && rowIndex === 0}
                  allColumns={allColumns}
                />
              ))}
            </Box>
          ))}
        </Box>
      }
    </>
  );
}

const groupRows = (rows: any[], groupSize: number = 1) => {
  const groupedRows = [];
  for (let i = 0; i < rows.length; i += groupSize) {
    groupedRows.push(rows.slice(i, i + groupSize));
  }
  return groupedRows;
};

export default memo(Deck);