import { Box, Button, Typography } from "@mui/material";
import { getBaggageCount, getMealCount, getSeatCount } from "../../../store/slices/flightOrderSlice";
import { formatToINR } from "../../../utils";

interface BottomBarProps {
  totalPrice: any;
  addOnType: 'Seat' | 'Meal' | 'Baggage'
  totalAddOnCount: number;
  smbData: any;
  discountPrice?: any;
  isDiscountApplied?: boolean;
  onClick: () => void;
  selectedSegment: { from: string, to: string }
}

const BottomBar: React.FC<BottomBarProps> = ({ 
  totalPrice,  
  addOnType,
  totalAddOnCount,
  smbData,
  discountPrice,
  isDiscountApplied,
  onClick,
  selectedSegment
}) => {
  const getCount = () => {
    switch (addOnType) {
      case 'Seat':
        return getSeatCount(smbData, selectedSegment);
      case 'Meal':
        return getMealCount(smbData, selectedSegment);
      case 'Baggage':
        return getBaggageCount(smbData, selectedSegment);
      default:
        return 0;
    }
  };

  const count = getCount();
  
  return (
    <Box position='sticky' bottom={0} display='flex' flexDirection='column' width='100%' bgcolor='white'>
      <Box p='5px 18px' bgcolor='#FFF4CE' display='flex'>
      {addOnType}(s): <Typography ml='5px' fontWeight={600}>({count}/{totalAddOnCount})</Typography>
      </Box>
      <Box display='flex' justifyContent='space-between' p='10px 20px'>
        <Box display='flex' alignItems='center'>
          {isDiscountApplied && <Typography sx={{textDecoration: 'line-through', color: '#5A6068'}}>₹{totalPrice}</Typography>}
          <Typography variant="h2" ml={isDiscountApplied ? '6px' : 'revert'}>{isDiscountApplied ? formatToINR(discountPrice) : formatToINR(totalPrice)}</Typography>
        </Box>
        <Box>
          <Button onClick={onClick} sx={{bgcolor: 'black !important'}} variant="contained">Continue</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default BottomBar;