import { Box, Skeleton } from '@mui/material';

const BookingCardSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: '16px',
        borderBottom: '4px solid #F4F6F5',
      }}
    >
      <Box>
        <Skeleton variant="text" width={64} />
        <Box display="flex" alignItems="center">
          <Skeleton variant="text" width={200} />
        </Box>
        <Box>
          <Skeleton variant="text" width={64} />
        </Box>
      </Box>
    </Box>
  );
};

export default BookingCardSkeleton;
