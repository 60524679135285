import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import OAFlightAvatar from '../../../../components/OAFlightAvatar';
import { formatToINR, getDiscountPrice } from '../../../../utils';
import { OAButton } from '../../../../components';

interface FlightPriceSummaryProps {
  returnSegments: any[];
  isInternational: boolean;
  uniqueAirlinesOnward: any;
  uniqueAirlinesReturn: any;
  onwardPrice: string;
  returnPrice: string;
  totalPrice: number;
  isCoinsApplied: boolean;
  coins: number;
  handleCreateFlightOrder: () => void;
}

const FlightPriceSummary: React.FC<FlightPriceSummaryProps> = ({
  returnSegments,
  isInternational,
  uniqueAirlinesOnward,
  uniqueAirlinesReturn,
  onwardPrice,
  returnPrice,
  totalPrice,
  isCoinsApplied,
  coins,
  handleCreateFlightOrder,
}) => (
  <Box
    position="sticky"
    sx={{
      bottom: 0,
      bgcolor: '#fff',
      borderTop: '1px solid #E0DFE1',
      p: '16px 20px 24px 20px',
      mt: '16px',
    }}
  >
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={6}>
        {returnSegments?.length > 0 && !isInternational && (
          <Typography variant="caption" color="#5A6068" display="flex">
            <OAFlightAvatar
              data={uniqueAirlinesOnward}
              sx={{
                marginRight: '-8px',
                position: 'revert',
                height: '15px',
                width: '15px',
              }}
            />
            <Typography variant="caption" ml="12px" color="#5A6068">
              {onwardPrice} |{' '}
            </Typography>
            <OAFlightAvatar
              data={uniqueAirlinesReturn}
              sx={{
                marginRight: '-8px',
                ml: '4px',
                position: 'revert',
                height: '15px',
                width: '15px',
              }}
            />
            <Typography variant="caption" ml="12px" color="#5A6068">
              {returnPrice ?? 'Add return'}
            </Typography>
          </Typography>
        )}
        <Box display="flex" alignItems="center">
          {isCoinsApplied && coins > 9 && (
            <Typography
              variant="body2"
              component="span"
              sx={{ color: 'text.secondary', textDecoration: 'line-through' }}
            >
              {formatToINR(totalPrice)}
            </Typography>
          )}
          <Typography variant="h2" component="span" ml="6px">
            {isCoinsApplied
              ? formatToINR(getDiscountPrice(totalPrice, coins))
              : formatToINR(totalPrice)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <OAButton fullWidth variant="contained" color="secondary" onClick={handleCreateFlightOrder}>
          Proceed
        </OAButton>
      </Grid>
    </Grid>
  </Box>
);

export default FlightPriceSummary;
