import { FC } from 'react';
import { Box, Card, Container, Skeleton, Typography } from '@mui/material';
import { ISegment } from '../../../type';
import PnrDisplay from '../../../components/OAFlightSummary/PnrDisplay';
import OAFlightRoute from '../../../components/OAFlightRoute';
import { OAFlightSummary } from '../../../components';
import { useSelector } from '../../../store';
import { getAuthInfo } from '../../../store/slices/authSlice';

interface TripDetailsSegmentsProps {
  order: any;
  isLoading: boolean;
}
const TripDetailsSegments: FC<TripDetailsSegmentsProps> = ({ order, isLoading }) => {
  const { user } = useSelector(getAuthInfo);
  const onWardFlights = order?.flights?.find((flight: any) => flight?.departureType === 'ONWARD');
  const returnFlights = order?.flights?.find((flight: any) => flight?.departureType === 'RETURN');

  const onwardPnrNumber = onWardFlights?.pnrNumber;
  const returnPnrNumber = returnFlights?.pnrNumber;

  return (
    <Box sx={{ bgcolor: user?.organizationCode === 'ZOLVE' ? '#FFFFFF' : '#F4F6F5', py: '15px' }}>
      <Container sx={{ mb: '10px' }}>
        <Box
          display="flex"
          justifyContent="space-between"
          color="gray"
          bgcolor={user?.organizationCode === 'ZOLVE' ? '#FFFFFF' : '#F4F6F5'}
          alignItems="center"
          mb="10px"
        >
          <OAFlightRoute
            from={order?.flightSearchInfo?.from?.city}
            to={order?.flightSearchInfo?.to?.city}
            icon="flight-take-off.svg"
            orgCode={user?.organizationCode}
          />
          <Box>
            {order?.flights?.[0]?.minirule ? (
              <Typography
                variant="body3"
                p="5px 10px"
                fontWeight={600}
                borderRadius="4px"
                bgcolor={
                  order?.flights?.[0]?.minirule?.refundable
                    ? user?.organizationCode === 'ZOLVE'
                      ? '#EEF3FE'
                      : '#DBF5ED'
                    : '#DEE2E1'
                }
                color={
                  order?.flights?.[0]?.minirule?.refundable
                    ? user?.organizationCode === 'ZOLVE'
                      ? '#276EF1'
                      : '#194244'
                    : '#5A6068'
                }
                sx={{
                  borderRadius: '46px',
                  ...(user?.organizationCode === 'ZOLVE' ? { textTransform: 'uppercase' } : {}),
                }}
              >
                {order?.flights?.[0]?.minirule?.refundable ? 'Refundable' : 'Non-refundable'}
              </Typography>
            ) : (
              <Skeleton width="100px" />
            )}
          </Box>
        </Box>
        {isLoading ? (
          <Skeleton variant="rectangular" sx={{ height: '82px', width: '100%' }} />
        ) : onwardPnrNumber ? (
          <PnrDisplay pnrDetails={onwardPnrNumber} />
        ) : order?.bookingStatus === 'BOOKING_IN_PROGRESS' ? (
          <Card>
            <Typography
              component="p"
              variant="body3"
              sx={{ color: 'text.secondary', py: '12px', textAlign: 'center' }}
            >
              Booking confirmed! PNR details are on the way
            </Typography>
          </Card>
        ) : null}
      </Container>
      {onWardFlights?.segments?.map((segment: ISegment) => (
        <OAFlightSummary
          selectedFlight={order}
          layovers={onWardFlights?.layovers}
          data={segment}
          loading={isLoading}
        />
      ))}
      {returnFlights?.segments?.length > 0 && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="gray"
            bgcolor="#F4F6F5"
            p="10px 20px"
            mt="15px"
          >
            <OAFlightRoute
              from={order?.flightSearchInfo?.to?.city}
              to={order?.flightSearchInfo?.from?.city}
              icon="flight-land.svg"
            />
            <Box>
              {order?.flights?.[1]?.minirule ? (
                <Typography
                  variant="body3"
                  p="5px 10px"
                  fontWeight={600}
                  borderRadius="4px"
                  bgcolor={order?.flights?.[1]?.minirule?.refundable ? '#DBF5ED' : '#DEE2E1'}
                  color={order?.flights?.[1]?.minirule?.refundable ? '#194244' : '#5A6068'}
                  sx={{ borderRadius: '46px' }}
                >
                  {order?.flights?.[1]?.minirule?.refundable ? 'Refundable' : 'Non-refundable'}
                </Typography>
              ) : (
                <Skeleton width="100px" />
              )}
            </Box>
          </Box>
          <Container sx={{ mb: '10px' }}>
            {isLoading ? (
              <Skeleton variant="rectangular" sx={{ height: '82px', width: '100%' }} />
            ) : returnPnrNumber ? (
              <PnrDisplay pnrDetails={returnPnrNumber} />
            ) : order?.bookingStatus === 'BOOKING_IN_PROGRESS' ? (
              <Card>
                <Typography
                  component="p"
                  variant="body3"
                  sx={{ color: 'text.secondary', py: '12px', textAlign: 'center' }}
                >
                  Booking confirmed! PNR details are on the way
                </Typography>
              </Card>
            ) : null}
          </Container>
        </>
      )}
      {returnFlights?.segments?.map((segment: ISegment) => (
        <OAFlightSummary
          selectedFlight={order}
          layovers={returnFlights?.layovers}
          data={segment}
          loading={isLoading}
        />
      ))}
    </Box>
  );
};

export default TripDetailsSegments;
