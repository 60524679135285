import Lottie from 'react-lottie';
import animationData from './empty.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const OALoadingLottie = () => {
  return <Lottie options={defaultOptions} height={120} width={120} />;
};
