import { FC, SyntheticEvent } from 'react';
import { Box, Fade, IconButton, Snackbar, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessage } from '../../store/slices/snackbarSlice';
import { OAImage } from '../OAImage';
import { getAuthInfo } from '../../store/slices/authSlice';
interface SnackbarProps {
  isOpen?: boolean;
  onClose?: () => void;
  message?: string;
}

export const OASnackbar: FC<SnackbarProps> = ({ isOpen, onClose, message }) => {
  const snackbarMessage = useSelector((state: any) => state.snackbar.message);
  const { user } = useSelector(getAuthInfo);
  const dispatch = useDispatch();

  const handleClose: (event: SyntheticEvent | Event, reason?: string) => void = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearMessage());
  };

  return (
    <Snackbar
      open={!!snackbarMessage}
      autoHideDuration={3000}
      onClose={handleClose}
      message={
        <Box display="flex" alignItems="center">
          {user?.organizationCode === 'ZOLVE' && (
            <OAImage
              src="zolve-alert.svg"
              alt="coins"
              sx={{ mx: '5px', height: '24px', width: '24px', mr: '12px' }}
            />
          )}

          <Typography
            variant={user?.organizationCode === 'ZOLVE' ? 'body2' : 'body2'}
            sx={{
              color: 'primary.contrastText',
              // fontWeight: user?.organizationCode === 'ZOLVE' ? 600 : 400,
            }}
          >
            {snackbarMessage}
          </Typography>
        </Box>
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={Fade}
      sx={{
        bottom: 84, // Also adjust the bottom margin to ensure consistency
      }}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          {user?.organizationCode === 'ZOLVE' ? (
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                color: 'primary.contrastText',
                textDecoration: 'underline',
                mr: '5px',
              }}
            >
              OK
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ fontWeight: 500, color: 'primary.main' }}>
              Okay
            </Typography>
          )}
        </IconButton>
      }
    ></Snackbar>
  );
};
