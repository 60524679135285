import { Box, Grid, Stack, Typography } from '@mui/material';
import { ISegment } from '../../type';
import { BookingStatusSkeleton } from '../../pages/MyBooking/BookingStatus/BookingStatusSkeleton';
import { OAImage } from '../OAImage';

const FlightTimeline = ({
  segment,
  loading = false,
  isConfirmItinerary,
  totalSegments,
}: {
  segment: ISegment;
  loading: boolean;
  isConfirmItinerary: boolean;
  totalSegments?: number;
}) => {
  const departure = segment?.departure;
  const arrival = segment?.arrival;
  const flightDuration = segment?.flightDuration;

  return (
    <Box>
      {loading ? (
        <Box margin="10px 24px auto">
          <BookingStatusSkeleton width={200} count={3} />
        </Box>
      ) : (
        <Box>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', ml: 0, mt: '12px', mb: isConfirmItinerary ? 0 : '4px' }}
          >
            <Grid xs={3}>
              <Typography variant="body1" sx={{ letterSpacing: '0.15px', whiteSpace: 'nowrap' }}>
                {departure?.time} {departure?.airport?.iata}
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ m: 0, width: '100%' }}
              >
                <Typography variant="body4" sx={{ color: 'text.secondary' }}>
                  {flightDuration?.display}
                </Typography>
                <OAImage
                  src="line-flight-card-lg.svg"
                  alt="line"
                  sx={{ width: '100%', height: '6px', maxWidth: '75px' }}
                />
                {totalSegments === 1 && (
                  <Typography
                    variant="body4"
                    sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
                  >
                    Non-stop
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid xs={3} display="flex" justifyContent="flex-end">
              <Typography variant="body1" sx={{ letterSpacing: '0.15px', whiteSpace: 'nowrap' }}>
                {arrival?.airport?.iata} {arrival?.time}
              </Typography>
            </Grid>
          </Grid>
          {!isConfirmItinerary && (
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              sx={{ width: '100%', ml: 0, mt: 0 }}
            >
              <Grid xs={6}>
                <Typography
                  variant="body3"
                  sx={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    pr: '8px',
                  }}
                >
                  {departure?.airport?.name}
                </Typography>
                <Typography variant="body3" sx={{ display: 'block' }}>
                  {departure?.terminal && `Terminal ${departure?.terminal}`}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography
                  variant="body3"
                  sx={{
                    display: 'block',
                    textAlign: 'end',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {arrival?.airport?.name}
                </Typography>
                <Typography variant="body3" sx={{ display: 'block', textAlign: 'end' }}>
                  {arrival?.terminal && `Terminal ${arrival?.terminal}`}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FlightTimeline;
