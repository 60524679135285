import { Container, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useSelector } from '../../../store';
import { getAuthInfo } from '../../../store/slices/authSlice';

const ImportantInfo = ({ info }: { info: string }) => {
  const { user } = useSelector(getAuthInfo);
  const orgCode = user?.organizationCode;
  return (
    <>
      <Container>
        <Typography
          component="span"
          variant="body3"
          sx={{
            bgcolor: orgCode === 'ZOLVE' ? 'text.secondary' : 'orange.main',
            p: '6px 10px',
            borderRadius: '23px',
            color: orgCode === 'ZOLVE' ? '#FFFFFF' : 'orange.light',
          }}
        >
          Important information
        </Typography>
        <List component="ul">
          <ListItem sx={{ p: 0 }}>
            <ListItemText
              primary={
                <Typography variant="body3" sx={{ color: 'text.secondary' }}>
                  Check travel guidelines and baggage information below: Carry no more than 1
                  check-in baggage and 1 hand baggage per passenger. If violated, airline may levy
                  extra charges. Wearing a mask/face cover is no longer mandatory. However, all
                  travellers are advised to do so as a precautionary measure.
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ p: 0 }}>
            <ListItemText
              primary={
                <Typography variant="body3" sx={{ color: 'text.secondary', mt: '10px' }}>
                  Valid ID proof needed: Carry a valid photo identification proof (Driver Licence,
                  Aadhar Card or any other Governments recognised photo identification)
                </Typography>
              }
            />
          </ListItem>
        </List>
        {/* <Typography component="p" variant="body3" sx={{ color: 'text.secondary', mt: '10px' }}>
          {info}
        </Typography> */}
      </Container>
      <Divider sx={{ borderBottomWidth: '4px', borderColor: '#F4F6F5', my: '20px' }} />
    </>
  );
};

export default ImportantInfo;
