import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Flight from '../pages/Flight';
import NotFound from '../pages/Error/NotFound';
import SearchResult from '../pages/SearchResult';
import Review from '../pages/Review';
import Traveller from '../pages/Traveller';
import MyBookings from '../pages/MyBooking';
import BookingStatus from '../pages/MyBooking/BookingStatus';
import Cancel from '../pages/MyBooking/BookingStatus/Cancel';

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log('Current path is:', location.pathname);

  // redirect url getting from native
  useEffect(() => {
    window.oa_client_redirect_to = (url: any) => {
      console.log('Redirect to page', url);
      console.log('Redirect to refresh', url?.includes('refresh'));
      if (!url?.includes('refresh')) navigate(url + '?redirectToApp');
    };
  }, []);

  return (
    <Routes>
      <Route path="/">
        <Route index element={<Flight />} />
        <Route path="results" element={<SearchResult />} />
        <Route path="review" element={<Review />} />
        <Route path="traveller" element={<Traveller />} />
        <Route path="my-bookings" element={<MyBookings />} />
        <Route path="booking-status/:orderId" element={<BookingStatus />} />
        <Route path="booking-status/:orderId/cancel" element={<Cancel />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;