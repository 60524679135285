const DEFAULT_USER_ID = '1234567890';
const DEFAULT_MOBILE = '9558611101';
const DEFAULT_EMAIL = 'vishal@onarrival.travel';

export const createUserLoginRequest = (user: any) => {
  console.log('user', user);

  const firstName = user?.firstName ?? '';
  const lastName = user?.lastName ?? '';
  const username = user.username || `${firstName} ${lastName}`.trim();

  return {
    id: user?.id ?? user?.appUserId ?? DEFAULT_USER_ID,
    ...(username && { username }),
    mobile: user?.mobile ?? user?.phone ?? DEFAULT_MOBILE,
    email: user?.email ?? DEFAULT_EMAIL,
    token: user?.token,
    ...(user?.orgId && { organizationId: user.orgId }),
    ...(user?.orgCode && { organizationCode: user.orgCode }),
  };
};
