import React from 'react';
import { Box } from '@mui/material';
import { OASwipeableDrawer } from '../../../../components';
import SearchParameters from '../../../Flight/SearchParameters';

interface EditSearchDrawerProps {
  isEditDrawerOpen: boolean;
  toggleEditDrawer: () => void;
  isUpdatingResults: boolean;
  setIsResultsLoading: (loading: boolean) => void;
  setFilterData: (data: any) => void;
  filterData: any;
  setCurrentDirection: (direction: number) => void;
}

const EditSearchDrawer: React.FC<EditSearchDrawerProps> = ({
  isEditDrawerOpen,
  toggleEditDrawer,
  isUpdatingResults,
  setIsResultsLoading,
  setFilterData,
  filterData,
  setCurrentDirection,
}) => (
  <OASwipeableDrawer
    title="Edit search"
    open={isEditDrawerOpen}
    onOpen={toggleEditDrawer}
    onClose={toggleEditDrawer}
  >
    <Box sx={{ mb: '24px' }}>
      <SearchParameters
        isEditing
        isUpdatingResults={isUpdatingResults}
        setIsResultsLoading={setIsResultsLoading}
        toggleDrawer={toggleEditDrawer}
        isEditDrawerOpen={isEditDrawerOpen}
        setFilterData={setFilterData}
        filterData={filterData}
        setCurrentDirection={setCurrentDirection}
      />
    </Box>
  </OASwipeableDrawer>
);

export default EditSearchDrawer;
