import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { IFlight } from '../../../type';
import { formatToINR, getBurnCoin, getDiscountPrice, getEarnCoin } from '../../../utils';
import { getUserInputState } from '../../../store/slices/userInputSlice';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../store/slices/userInfoSlice';
import { maxBy, minBy } from 'lodash';
import { getFeatureFlag } from '../../../store/slices/featuresSlice';
import OACoinBannerSecondary from '../../../components/OACoinBannerSecondary';
import { OAImage } from '../../../components';

interface FlightDetailProps {
  data: IFlight;
  tripType: 'R' | 'O';
  isInternational?: boolean;
}

const FlightDetail: FC<FlightDetailProps> = ({ data, tripType, isInternational }) => {
  const { isCoinsApplied } = useSelector(getUserInputState);
  const { coins } = useSelector(getUserInfo);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);

  const onwardSegments = data?.segments.filter((segment) => segment.departureType === 'ONWARD');
  const returnSegments = data?.segments.filter((segment) => segment.departureType === 'RETURN');

  const minOnwardObj = minBy(onwardSegments, 'segmentId');
  const maxOnwardObj = maxBy(onwardSegments, 'segmentId');
  const onwardDepartureTime = minOnwardObj?.departure?.time;
  const onwardDeparturePlace = minOnwardObj?.departure?.airport?.iata;
  const onwardArrivalTime = maxOnwardObj?.arrival?.time;
  const onwardArrivalPlace = maxOnwardObj?.arrival?.airport?.iata;

  const minReturnObj = minBy(returnSegments, 'segmentId');
  const maxReturnObj = maxBy(returnSegments, 'segmentId');
  const returnDepartureTime = minReturnObj?.departure?.time;
  const returnDeparturePlace = minReturnObj?.departure?.airport?.iata;
  const returnArrivalTime = maxReturnObj?.arrival?.time;
  const returnArrivalPlace = maxReturnObj?.arrival?.airport?.iata;

  const minObj = minBy(data?.segments, 'segmentId');
  const maxObj = maxBy(data?.segments, 'segmentId');
  const departureTime = minObj?.departure?.time;
  const departurePlace = minObj?.departure?.airport?.iata;
  const arrivalTime = maxObj?.arrival?.time;
  const arrivalPlace = maxObj?.arrival?.airport?.iata;
  const discountPrice = getDiscountPrice(data?.price?.value, coins);
  const internationalReturn = tripType === 'R' && isInternational;
  const domesticReturn = tripType === 'R' && !isInternational;

  const savedMoney = data?.price?.value - discountPrice;
  const earnCoin = getEarnCoin(data?.price?.value ?? 0);
  const coinsToBeBurned = getBurnCoin(data?.price?.value, coins);
  const isDiscountedPrice = discountPrice < data?.price?.value;

  const earnCoinLabel = (
    <Box display="flex" alignItems="center">
      {domesticReturn ? (
        <>
          <Typography variant="caption">Earning</Typography>
          <Typography mx="4px" fontWeight={600} variant="caption">
            {earnCoin}
          </Typography>
          <Typography variant="caption">coins</Typography>
        </>
      ) : (
        <>
          <Typography variant="caption">Earn</Typography>
          <Typography mx="4px" fontWeight={600} variant="caption">
            {earnCoin}
          </Typography>
          <Typography variant="caption">Niyo coins on this trip</Typography>
        </>
      )}
    </Box>
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={domesticReturn ? '10px' : '15px'}
        mb={domesticReturn ? '10px' : '15px'}
      >
        <Box
          display="flex"
          alignItems={isInternational ? 'center' : 'start'}
          flexDirection="column"
          width={domesticReturn ? '100%' : 'revert'}
        >
          <Box display="flex" width="100%">
            <Stack
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{ m: domesticReturn ? '0 4px 0 0' : '0 10px 0 0' }}
            >
              <Typography variant={tripType === 'O' || isInternational ? 'body1' : 'body2'}>
                {isInternational ? onwardDepartureTime : departureTime}
              </Typography>
              <Typography variant="body4">
                {isInternational ? onwardDeparturePlace : departurePlace}
              </Typography>
            </Stack>
            <Stack
              width="100%"
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ m: 0 }}
            >
              <Typography variant="body4" sx={{ color: 'text.secondary' }}>
                {domesticReturn
                  ? data?.returnDurationInfo?.display ?? data?.durationInfo?.display
                  : data?.durationInfo?.display}
              </Typography>
              <OAImage
                src="line-flight-card-lg.svg"
                alt="line"
                sx={{ width: '68px', height: '6px' }}
              />
              <Typography
                variant="body4"
                sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
              >
                {data?.stops?.display}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{ m: domesticReturn ? '0 0 0 4px' : '0 0 0 10px' }}
            >
              <Typography
                display="flex"
                alignItems="center"
                variant={tripType === 'O' || isInternational ? 'body1' : 'body2'}
              >
                {isInternational ? onwardArrivalTime : arrivalTime}{' '}
                {data?.arrivalDayOffset > 0 && (
                  <Typography
                    variant="body3"
                    sx={{ ml: '4px', color: 'error.main', fontWeight: 500 }}
                  >
                    +{data?.arrivalDayOffset}
                  </Typography>
                )}
              </Typography>
              <Typography variant="body4">
                {isInternational ? onwardArrivalPlace : arrivalPlace}
              </Typography>
            </Stack>
          </Box>
          {internationalReturn && (
            <Box display="flex" mt="15px">
              <Stack
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-start"
                sx={{ m: domesticReturn ? '0 4px 0 0' : '0 10px 0 0' }}
              >
                <Typography variant={tripType === 'R' && !isInternational ? 'body2' : 'body1'}>
                  {isInternational ? returnDepartureTime : departureTime}
                </Typography>
                {internationalReturn && (
                  <Typography variant="body4">
                    {isInternational ? returnDeparturePlace : departurePlace}
                  </Typography>
                )}
              </Stack>
              {internationalReturn && (
                <Stack direction="column" justifyContent="center" alignItems="center" sx={{ m: 0 }}>
                  <Typography variant="body4" sx={{ color: 'text.secondary' }}>
                    {data?.returnDurationInfo?.display}
                  </Typography>
                  <OAImage
                    src="line-flight-card-lg.svg"
                    alt="line"
                    sx={{ width: '68px', height: '6px' }}
                  />
                  <Typography
                    variant="body4"
                    sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
                  >
                    {data?.returnStops?.display ?? data?.stops?.display}
                  </Typography>
                </Stack>
              )}
              <Stack
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-start"
                sx={{ m: domesticReturn ? '0 0 0 4px' : '0 0 0 10px' }}
              >
                <Typography
                  display="flex"
                  alignItems="center"
                  variant={domesticReturn ? 'body1' : 'body2'}
                >
                  {isInternational ? returnArrivalTime : arrivalTime}{' '}
                  {data?.arrivalDayOffset > 0 && (
                    <Typography
                      variant="body3"
                      sx={{ ml: '4px', color: 'error.main', fontWeight: 500 }}
                    >
                      +{data?.arrivalDayOffset}
                    </Typography>
                  )}
                </Typography>
                {internationalReturn && (
                  <Typography variant="body4">
                    {isInternational ? returnArrivalPlace : arrivalPlace}
                  </Typography>
                )}
              </Stack>
            </Box>
          )}
        </Box>
        {(isInternational || tripType === 'O') && (
          <Box>
            <Box display="flex" flexDirection={'column'} alignItems="end">
              {isCoinsApplied && isDiscountedPrice && (
                <Typography
                  variant="body4"
                  sx={{ color: 'text.secondary', textDecoration: 'line-through' }}
                >
                  {data?.price?.display}
                </Typography>
              )}
              <Typography
                variant="body1"
                sx={{
                  color: isCoinsApplied
                    ? discountPrice > 0
                      ? 'secondary.main'
                      : 'success.main'
                    : 'text.primary',
                  fontWeight: 600,
                }}
              >
                {isCoinsApplied
                  ? discountPrice > 0
                    ? formatToINR(discountPrice)
                    : 'Free'
                  : data?.price?.display}
              </Typography>
            </Box>
            {data?.seat && (
              <Typography
                variant={domesticReturn ? 'body4' : 'body3'}
                sx={{ color: 'error.main', fontWeight: 400 }}
              >
                {data?.seat}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {((isCoinsApplied && coins > 0 && burnCoinsFeat) || earnCoin > 0) && !domesticReturn && (
        <OACoinBannerSecondary
          isEarnCoins={earnCoin > 0}
          isToggleOn={isCoinsApplied}
          title={
            isCoinsApplied
              ? discountPrice > 0
                ? coinsToBeBurned > 0 && (
                    <Box display="flex" alignItems="center">
                      Redeeming{' '}
                      <Typography ml="4px" variant="caption" fontWeight={600}>
                        {coinsToBeBurned}
                      </Typography>{' '}
                      <OAImage
                        src="3xNiyo_coin.png"
                        alt="coins"
                        sx={{ mx: '5px', height: '18px', width: '18px' }}
                      />{' '}
                      {!domesticReturn && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body2">to save</Typography>
                          <Typography ml="4px" variant="caption" fontWeight={600}>
                            {formatToINR(savedMoney)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )
                : 'Flying free using'
              : discountPrice > 0
              ? earnCoin > 0
                ? earnCoinLabel
                : null
              : coinsToBeBurned > 0 && (
                  <Box display="flex" alignItems="center">
                    <OAImage src="tick.svg" folder="icons" alt="tick" sx={{ mr: '4px' }} />{' '}
                    Redeeming{' '}
                    <Typography ml="4px" variant="caption" fontWeight={600}>
                      {coinsToBeBurned}
                    </Typography>{' '}
                    <OAImage
                      src="3xNiyo_coin.png"
                      alt="coins"
                      sx={{ mx: '5px', height: '18px', width: '18px' }}
                    />{' '}
                    {!domesticReturn && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">to save</Typography>
                        <Typography ml="4px" variant="caption" fontWeight={600}>
                          {formatToINR(savedMoney)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )
          }
          color={
            isCoinsApplied ? (coinsToBeBurned > 0 ? 'primary' : '') : earnCoin > 0 ? 'yellow' : ''
          }
          sx={{
            borderTopRightRadius: '0px',
            borderTopLeftRadius: '0px',
            borderBottomRightRadius: domesticReturn ? '0px' : '10px',
            borderBottomLeftRadius: domesticReturn ? '0px' : '10px',
            p: domesticReturn ? '5px 10px' : '5px 15px',
          }}
        />
      )}
    </>
  );
};

export default FlightDetail;
