import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { v4 as uuidv4 } from 'uuid';
import { store } from '../store';
import { setToken } from '../store/slices/authSlice';
import { eventLogError, isMobileWebview } from '../utils';
import PostHog from 'posthog-js';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    // Access the current state of the store
    const state: any = getState();
    const user = state?.auth?.user;
    const token = state?.auth?.token;
    const lat = state?.userInfo?.lat || 12.9716;
    const long = state?.userInfo?.long || 77.5946;

    // If there's a token, set the Authorization header
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (user?.organizationId) {
      headers.set('organizationId', user?.organizationId);
    }

    if (user?.organizationCode) {
      headers.set('organizationCode', user?.organizationCode?.toUpperCase());
    }

    headers.set('lat', lat);
    headers.set('log', long);

    if (state?.userInfo?.isMock) headers.set('mock', 'true');

    // Generate a UUID and set it as "x-correlation-id"
    const uuid = uuidv4();
    headers.set('x-correlation-id', `oa-flight-pwa-${uuid}`);

    const minimalState = {
      lastScreen: state.global.lastScreen,
      paymentInProgress: state.global.paymentInProgress,
    };
    const stateJson = JSON.stringify(minimalState);

    // Encode the serialized state to ensure it's safe to include in a header
    const encodedState = encodeURIComponent(stateJson);

    headers.set('global-state', encodedState);
    return headers;
  },
});

const createErrorMessage = (response: any, id: string) => {
  const statusCode = response?.status; // Get the status code
  // Check if data exists and has a message, otherwise set a default message
  const mainMessage =
    response?.data && response?.data?.message ? response?.data?.message : 'No message available';
  // Check if data exists and has errors, and that errors array is not empty
  const errorMessage =
    response?.data && response?.data?.errors && response?.data?.errors?.length > 0
      ? response?.data?.errors?.[0]?.message
      : 'No error details';

  // Concatenate the required string with comma separation
  return `${id}, ${statusCode}, ${mainMessage}, ${errorMessage}`;
};
const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result: any = await baseQuery(args, api, extraOptions);
  if (result?.error) {
    const state: any = api.getState();
    const apiEndpoint = api?.endpoint ?? '';
    const capitalized = apiEndpoint.charAt(0).toUpperCase() + apiEndpoint.slice(1);
    const posthogEventData = {
      flowName: 'Flight',
      screenName: 'Error',
      ctaAction: capitalized,
      screenDuration: '1',
      otherData: {
        errorSeen: window.location.href,
        userInfo: state?.auth?.user,
        request: args,
        error: result?.error,
      },
    };
    eventLogError(
      {
        screen: api?.endpoint,
        error: createErrorMessage(result?.error, state?.auth?.user?.id),
      },
      posthogEventData,
      PostHog
    );
  }

  // Check for a specific condition to determine if re-authorization is needed (e.g., 401 status code)
  if (result?.error && (result?.error?.status === 401 || result?.error?.originalStatus === 401)) {
    console.log('401->result', result);
    if (
      isMobileWebview() &&
      window.niyo_refresh_token &&
      typeof window.niyo_refresh_token === 'function'
    ) {
      console.log('login is happening');
      const refreshResult = await window.niyo_refresh_token();
      console.log('refreshResult --->>>>>', refreshResult);
      if (refreshResult) {
        store.dispatch(setToken(refreshResult));

        const newArgs =
          typeof args === 'string'
            ? args
            : {
                ...args,
                headers: {
                  ...args.headers,
                  Authorization: `Bearer ${refreshResult}`,
                },
              };

        // Directly return the result of the retry
        return baseQuery(newArgs, api, extraOptions);
      }
    }
  }
  return result;
};

export const baseApi = baseQueryWithReAuth;
