import { Container, Skeleton } from '@mui/material';

const FilterAndSortSkeleton = () => {
  return (
    <Container sx={{ py: '8px', display: 'flex' }}>
      <Skeleton variant="rectangular" width="95px" height="32px" sx={{ borderRadius: '30px' }} />
      <Skeleton
        variant="rectangular"
        width="95px"
        height="32px"
        sx={{ borderRadius: '30px', ml: '8px' }}
      />
      <Skeleton
        variant="rectangular"
        width="95px"
        height="32px"
        sx={{ borderRadius: '30px', ml: '8px' }}
      />
    </Container>
  );
};

export default FilterAndSortSkeleton;
