import { ISegment } from "../type";
import { OAAirlineLogo } from "./OAAirlineLogo";

const OAFlightAvatar = ({ data, sx }: any) => {
  return (
    <>
      {data?.map((seg: ISegment) => (
        <OAAirlineLogo airlineCode={seg?.airline?.code}
          sx={{
            border: '1px solid #DEE2E1 !important',
            position: 'revert',
            ...sx
          }}
        />
      ))}
    </>
  )
}

export default OAFlightAvatar;