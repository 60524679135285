import { createSlice } from "@reduxjs/toolkit";

export interface AirportSearchState {
  airportSuggestions: any[];
  fromAirportSuggestions: any[];
  toAirportSuggestions: any[];
  loadingAirports: boolean;
}

const initialState: any = {
  airportSuggestions: [],
  fromAirportSuggestions: [],
  toAirportSuggestions: [],
  loadingAirports: false,
} as const;


export const airportSearchSlice = createSlice({
  name: "airportSearch",
  initialState,
  reducers: {
    clearAirportSuggestions: (state) => {
      state.airportSuggestions = [];
    },
    setRecentAirportSuggestions: (state, action) => {
      state.recentAirportSuggestions = action.payload;
    },
    addFromAirportSuggestion: (state, action) => {
      state.fromAirportSuggestions.push(action.payload);
    },
    addToAirportSuggestion: (state, action) => {
      state.toAirportSuggestions.push(action.payload);
    },
    setAirportSuggestions: (state, action) => {
      state.airportSuggestions = action.payload;
    }
  },
});

export const getAirportSearchState = (state: { airportSearch: AirportSearchState }) =>
  state.airportSearch;

export const { 
  clearAirportSuggestions, 
  setRecentAirportSuggestions, 
  addFromAirportSuggestion,
  addToAirportSuggestion,
  setAirportSuggestions  
} = airportSearchSlice.actions;

export default airportSearchSlice.reducer;