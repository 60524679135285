import { Box, Typography } from '@mui/material';
import React from 'react';

interface TripChipProps {
  from: string;
  to: string;
  airline?: string;
}

const TripChip: React.FC<TripChipProps> = ({ from, to, airline='airline-fallback' }) => {
  return (
    <Box display="flex" alignItems="center" gap="5px">
      <img
        src={`https://oa-flight-assets.s3.ap-south-1.amazonaws.com/airline-logos/${airline}.svg`}
        alt="6E"
        style={{ width: '20px', height: '20px' }}
      />
      <Typography
        sx={{
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '0.2px',
        }}
      >
        {from}
      </Typography>
      <img
        src="https://oa-flight-assets.s3.ap-south-1.amazonaws.com/icons/arrow-back.svg"
        alt="6E"
        style={{ transform: 'rotate(180deg)', width: '11px' }}
      />
      <Typography
        sx={{
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '0.2px',
        }}
      >
        {to}
      </Typography>
    </Box>
  );
};

export default TripChip;
