import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { OAImage } from '../OAImage';

interface AncillaryRowProps {
  label: string;
  value: string;
  icon: string;
}
const AncillaryRow: FC<AncillaryRowProps> = ({ label, value, icon }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" my="10px">
      <Box display="flex" alignItems="center">
        <OAImage src={icon} folder="icons" alt="seat" />
        <Typography variant="body3" color="text.secondary" ml="8px">
          {label}
        </Typography>
      </Box>
      <Typography
        variant="body3"
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: '100%',
          textOverflow: 'ellipsis',
          ml: '25px',
          textAlign: 'right',
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default AncillaryRow;
