import { useEffect, useState, RefObject } from 'react';

function useIsVisible(ref: RefObject<Element>): boolean {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry?.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (ref?.current) {
      observer.observe(ref?.current);
    }

    return () => {
      if (ref?.current) {
        observer.unobserve(ref?.current);
      }
    };
  }, [ref]);

  return isVisible;
}

export default useIsVisible;