import { Box, Container, Typography } from '@mui/material';
import * as React from 'react';
import { OAImage } from './OAImage';
import { eventsTracker } from '../utils/ctEventsTracking';
import { PostHog } from 'posthog-js';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

class ErrorBoundary extends React.Component<Props, State> {
  private posthog: PostHog;

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
    this.posthog = new PostHog();
  }

  static getDerivedStateFromError(error: Error) {
    // updating state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: React.ErrorInfo) {
    // log the error to an error reporting service
    console.error({error, errorInfo});

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Error',
        ctaAction: 'WhiteScreen',
        screenDuration: '1',
        otherData: {
          error: error.message,
        },
      },
      this.posthog
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container
          sx={{
            height: '100vh',
            overflow: 'scroll',
            bgcolor: '#F4F6F5',
            py: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%', // Take full height of the container
              textAlign: 'center', // Center the text
            }}
          >
            <OAImage src="flight-unavailable.svg" alt="Error" />
            <Typography mt='10px' variant='body2' fontWeight={500}>Something went wrong</Typography>
            <Typography mt='10px' variant='caption' color='text.secondary'>Please try again by restarting the app</Typography>
          </Box>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;