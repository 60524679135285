export const getCurrentTabString = (currentTab: number) => {
  switch (currentTab) {
    case 1:
      return 'Seat';
    case 2:
      return 'Meal';
    case 3:
      return 'Baggage';
    default:
      return '';
  }
};
