import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export enum PaymentStatus {
  Idle = 'idle',
  InProgress = 'inProgress',
  Failed = 'failed',
  Succeeded = 'succeeded'
}

// Define the shape of your global state
interface GlobalState {
  lastScreen: string;
  paymentInProgress: boolean;
  onGoingOrderId: string;
  paymentStatus: PaymentStatus;
}

// Set the initial state
const initialState: GlobalState = {
  lastScreen: '',
  paymentInProgress: false,
  paymentStatus: PaymentStatus.Idle,
  onGoingOrderId: ''
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // Reducer to set the last accessed screen
    setLastScreen: (state, action: PayloadAction<string>) => {
      state.lastScreen = action.payload;
    },
    // Reducer to set whether payment is in progress
    setPaymentInProgress: (state, action: PayloadAction<boolean>) => {
      state.paymentInProgress = action.payload;
    },
    // Reducer to set the payment status
    setPaymentStatus: (state, action: PayloadAction<PaymentStatus>) => {
      state.paymentStatus = action.payload;
    },

    setOnGoingOrderId: (state, action) => {
      state.onGoingOrderId = action.payload;
    },
  },
});

// Selectors
export const getLastScreen = (state: { global: GlobalState }) => state.global.lastScreen;
export const getPaymentInProgress = (state: { global: GlobalState }) => state.global.paymentInProgress;
export const getPaymentStatus = (state: { global: GlobalState }) => state.global.paymentStatus;

export const getOngoingOrderId = (state: { global: GlobalState }) => state.global.onGoingOrderId;

// Export actions
export const { setLastScreen, setPaymentInProgress, setPaymentStatus,setOnGoingOrderId   } = globalSlice.actions;

// Export the reducer
export default globalSlice.reducer;
