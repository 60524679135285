import { FC } from 'react';
import {
  Box,
  Container,
  IconButton,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
} from '@mui/material';
import { OAImage } from '../OAImage';

const SwipeIcon = () => (
  <Box display="flex" justifyContent="center">
    <Box
      sx={{
        bgcolor: 'grey.700',
        width: '40px',
        height: '5px',
        borderRadius: '3px',
        mt: '10px',
      }}
    ></Box>
  </Box>
);

interface OASwipeableDrawerProps extends Omit<SwipeableDrawerProps, 'onOpen' | 'onClose'> {
  onClose: () => void;
  onOpen: () => void;
  title: any;
  isContainer?: boolean;
  height?: number;
  maxHeight?: number;
  isError?: boolean;
}

export const OASwipeableDrawer: FC<OASwipeableDrawerProps> = ({
  title,
  isContainer = true,
  height,
  maxHeight,
  onClose,
  children,
  isError = false,
  ...rest
}) => (
  <SwipeableDrawer
    {...rest}
    transitionDuration={300}
    disableSwipeToOpen
    anchor="bottom"
    onClose={onClose}
    onTouchStart={(event: any) => {
      event.nativeEvent.defaultMuiPrevented = isError ? true : false;
    }}
    PaperProps={{
      style: {
        height: height ? height + '%' : 'auto',
        maxHeight: maxHeight ? maxHeight + '%' : 'auto',
      },
    }}
  >
    <Container sx={{ position: 'sticky', top: 0, zIndex: 10, bgcolor: '#FFFFFF' }}>
      <SwipeIcon />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: '24px', mb: '14px' }}
      >
        <Typography variant="h2">{title}</Typography>
        {!isError && (
          <IconButton aria-label="close" onClick={onClose} size="large">
            <OAImage src="close.svg" folder="icons" alt="close" />
          </IconButton>
        )}
      </Box>
    </Container>
    {isContainer ? <Container>{children}</Container> : children}
  </SwipeableDrawer>
);
