import { Container, Typography } from '@mui/material';
import { OAButton } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from '../../store';
import { setError } from '../../store/slices/flightOrderSlice';

const ErrorPaymentDone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = () => {
    dispatch(setError(null));
    navigate(`/my-bookings`);
  };

  return (
    <Container sx={{ pb: '24px' }}>
      <Typography variant="h6" mb="10px">
        Already Paid
      </Typography>
      <Typography>
        Oops! Payment is already successful for this booking, please check your booking in my trips.
      </Typography>
      <OAButton
        variant="contained"
        color="secondary"
        fullWidth
        sx={{ mt: '24px' }}
        onClick={handleNavigate}
      >
        Go to My Trips
      </OAButton>
    </Container>
  );
};

export default ErrorPaymentDone;
