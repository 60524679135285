import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userInputSlice from './slices/userInputSlice';
import { useDispatch as useDispatchBase, useSelector as useSelectorBase } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import globalReducer from './slices/globalSlice';
import airportSearchSlice from './slices/airportSearchSlice';
import searchFlightSlice from './slices/searchFlightSlice';
import snackbarSlice from './slices/snackbarSlice';
import userInfoSlice from './slices/userInfoSlice';
import flightOrderSlice from './slices/flightOrderSlice';
import { flightSearchApi } from '../services/flightSearchApi';
import { getBookingsApi } from '../services/bookingsApi';
import { airportDataApi } from '../services/airportDataApi';
import { travellersApi } from '../services/travellersApi';
import { authApi } from '../services/authApi';
import authSlice from './slices/authSlice';
import { paymentApi } from '../services/paymentApi';
import cancellationSlice from './slices/cancellationSlice';
import featuresSlice from './slices/featuresSlice';
import { createOrderApi } from '../services/createOrderApi';

// a persist config specifically for the airportSearch slice
const airportSearchPersistConfig = {
  key: 'airportSearch',
  storage: storage,
  whitelist: ['fromAirportSuggestions', 'toAirportSuggestions'], // only these fields will be persisted
};

const userInputPersistConfig = {
  key: 'userInput',
  storage: storage,
  whitelist: ['traveller', 'travelDetail'],
};

const flightOrderPersistConfig = {
  key: 'flightData',
  storage: storage,
  whitelist: ['flightData'],
};

// persistReducer to enhance airportSearch reducer
const persistedAirportSearchReducer = persistReducer(
  airportSearchPersistConfig,
  airportSearchSlice
);

const persistedUserInputReducer = persistReducer(userInputPersistConfig, userInputSlice);

const persistedFlightOrderReducer = persistReducer(flightOrderPersistConfig, flightOrderSlice);

const rootReducer = combineReducers({
  global: globalReducer,
  userInput: persistedUserInputReducer,
  flightList: searchFlightSlice,
  airportSearch: persistedAirportSearchReducer,
  snackbar: snackbarSlice,
  userInfo: userInfoSlice,
  flightData: persistedFlightOrderReducer,
  auth: authSlice,
  features: featuresSlice,
  cancellationData: cancellationSlice,
  [getBookingsApi.reducerPath]: getBookingsApi.reducer,
  [flightSearchApi.reducerPath]: flightSearchApi.reducer,
  [airportDataApi.reducerPath]: airportDataApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [travellersApi.reducerPath]: travellersApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [createOrderApi.reducerPath]: createOrderApi.reducer
});

// store configuration
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      airportDataApi.middleware,
      flightSearchApi.middleware,
      getBookingsApi.middleware,
      paymentApi.middleware,
      travellersApi.middleware,
      authApi.middleware,
      createOrderApi.middleware
    ),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: { users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Since we use typescript, lets utilize `useDispatch`
export const useDispatch = () => useDispatchBase<AppDispatch>();

// And utilize `useSelector`
export const useSelector = <TSelected = unknown>(
  selector: (state: RootState) => TSelected
): TSelected => useSelectorBase<RootState, TSelected>(selector);
