import { ISegment } from "../type";

export const getUniqueAirlines = (segments?: ISegment[]): ISegment[] => {
  if (!segments) return [];

  return segments.reduce((acc: ISegment[], currentSegment: ISegment) => {
    // adding the segment if its airline code hasn't been added to the accumulator yet
    if (!acc.find(seg => seg.airline.code === currentSegment.airline.code)) {
      acc.push(currentSegment);
    }

    // checking for presence of specific airline codes
    const hasI5 = acc.some(segment => segment.airline.code === 'I5');
    const hasIX = acc.some(segment => segment.airline.code === 'IX');

    // If both 'I5' and 'IX' are present, filtering out 'I5'
    if (hasI5 && hasIX) {
      return acc.filter(segment => segment.airline.code !== 'I5');
    }
    return acc;
  }, []);
}