import React from 'react';
import { Box, Container } from '@mui/material';
import OAFlightRoute from '../../../../components/OAFlightRoute';
import { OAFlightSummary } from '../../../../components';
import { ISegment } from '../../../../type';

interface FlightSegmentsProps {
  segments: ISegment[];
  travelDetail: any;
  isConfirmFlight: boolean;
  handleCreateFlightOrder: () => void;
  searchId: string;
  selectedFlight: any;
  isInternational: boolean;
  direction: 'onward' | 'return';
  miniRuleData?: any;
  isMiniRuleLoading?: boolean;
  orgCode?: string;
}

const FlightSegments: React.FC<FlightSegmentsProps> = ({
  segments,
  travelDetail,
  isConfirmFlight,
  handleCreateFlightOrder,
  searchId,
  selectedFlight,
  isInternational,
  direction,
  miniRuleData,
  isMiniRuleLoading,
  orgCode
}) => (
  <Box>
    <Container sx={{ mb: '15px' }}>
      <Box>
        <OAFlightRoute
          from={direction === 'onward' ? travelDetail?.from?.city : travelDetail?.to?.city}
          to={direction === 'onward' ? travelDetail?.to?.city : travelDetail?.from?.city}
          icon={direction === 'onward' ? 'flight-take-off.svg' : 'flight-land.svg'}
          orgCode={orgCode}
        />
      </Box>
    </Container>
    {segments?.map((segment: ISegment) => (
      <Box key={segment?.id} mb="16px">
        <OAFlightSummary
          tripType={travelDetail?.tripType}
          isConform={isConfirmFlight}
          data={segment}
          layovers={
            direction === 'onward' || isInternational
              ? selectedFlight?.onward?.layovers
              : selectedFlight?.return?.layovers
          }
          onClick={handleCreateFlightOrder}
          searchId={searchId}
          selectedFlight={selectedFlight}
          isInternational={isInternational}
          direction={direction}
          miniRuleData={miniRuleData}
          isMiniRuleLoading={isMiniRuleLoading}
          orgCode={orgCode}
        />
      </Box>
    ))}
  </Box>
);

export default FlightSegments;
