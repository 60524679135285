import { Box, Typography } from "@mui/material";
import { OAImage } from "./OAImage";
import React from "react";

interface EmptySmbProps {
  text: string;
}

const EmptySmb: React.FC<EmptySmbProps> = ({ text }) => {
  return (
    <Box  height='calc(100vh - 304px)' sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    }}>
    <OAImage src='person-with-phone.svg' alt="empty" />
    <Typography color='#5A6068' variant='body2' mt='10px'>{text}</Typography>
    </Box>
  )
}

export default EmptySmb;
