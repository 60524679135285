import { FC, ReactNode } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface OAButtonProps extends ButtonProps {
  children: ReactNode;
  loading?: boolean;
}

export const OAButton: FC<OAButtonProps> = ({ children, loading, ...rest }) => {
  return (
    <Button {...rest} disabled={loading || rest.disabled}>
      {children}
      {loading && (
        <CircularProgress
          size={20}
          sx={{
            position: 'absolute',
            right: '16px',
            color: '#FDFDFD',
          }}
        />
      )}
    </Button>
  );
};
