import { Box, Skeleton, Typography } from '@mui/material';
import { OAImage } from '.';

export const OAHeaderTitle = ({ travelDetail, params, orgCode }: { travelDetail: any; params?: any, orgCode?: string; }) => {
  const from = params?.from?.city ?? travelDetail?.from?.city;
  const to = params?.to?.city ?? travelDetail?.to?.city;
  
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" sx={{ letterSpacing: '0.15px' }} fontWeight={orgCode === 'ZOLVE' ? 600 : 400}>
        {from ?? <Skeleton sx={{ fontSize: '1rem', width: '60px' }} />}
      </Typography>
      <OAImage src={travelDetail?.tripType === 'R' || travelDetail?.tripType === 'ROUND_TRIP' ? 'two-way-arrow.svg' :"one-way-arrow.svg"} folder="icons" alt="One way" sx={{ mx: '4px' }} />
      <Typography variant="body1" sx={{ letterSpacing: '0.15px' }} fontWeight={orgCode === 'ZOLVE' ? 600 : 400}>
        {to ?? <Skeleton sx={{ fontSize: '1rem', width: '60px' }} />}
      </Typography>
    </Box>
  );
};
