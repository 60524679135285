import React from 'react';

interface SvgIconProps {
  primaryColor: string;
  secondaryColor: string;
  isAvailable: boolean;
  isSelected: boolean;
}

const SvgIcon: React.FC<SvgIconProps> = ({ primaryColor, secondaryColor, isAvailable, isSelected }) => {
  return isSelected ? (
    <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 10.2112C1 5.69894 4.65793 2.04102 9.17021 2.04102H24.8298C29.3421 2.04102 33 5.69894 33 10.2112V29.8873C33 32.5195 30.8662 34.6533 28.234 34.6533H5.76596C3.13379 34.6533 1 32.5195 1 29.8873V10.2112Z" fill="#00C6A8" />
      <path d="M1 9.17021C1 4.65793 4.65793 1 9.17021 1H24.8298C29.3421 1 33 4.65793 33 9.17021V28.8463C33 31.4785 30.8662 33.6122 28.234 33.6122H5.76596C3.13379 33.6122 1 31.4785 1 28.8463V9.17021Z" fill="#00C6A8" stroke="#FDFDFD" strokeWidth="0.340426" />
      <path d="M25 12L15.375 21.625L11 17.25" stroke="#FDFDFD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="7.97881" y="29.2728" width="18.383" height="5.55753" rx="2.77877" fill="#00C6A8" stroke="#FDFDFD" strokeWidth="0.340426" />
    </svg>
  ) : (
    <>
      {isAvailable ? (
        <svg width="34" height="35" viewBox="0 0 34 35" fill={primaryColor} stroke={secondaryColor} xmlns="http://www.w3.org/2000/svg" >
          <path
            d="M1 10.2112C1 5.69894 4.65793 2.04102 9.17021 2.04102H24.8298C29.3421 2.04102 33 5.69894 33 10.2112V29.8873C33 32.5195 30.8662 34.6533 28.234 34.6533H5.76596C3.13379 34.6533 1 32.5195 1 29.8873V10.2112Z"
            fill={primaryColor}
            stroke={secondaryColor}
          />
          <path
            d="M1 9.17021C1 4.65793 4.65793 1 9.17021 1H24.8298C29.3421 1 33 4.65793 33 9.17021V28.8463C33 31.4785 30.8662 33.6122 28.234 33.6122H5.76596C3.13379 33.6122 1 31.4785 1 28.8463V9.17021Z"
            fill={primaryColor}
            stroke={secondaryColor}
            strokeWidth="0.340426"
          />
          <rect
            x="7.97881"
            y="29.2718"
            width="18.383"
            height="5.55753"
            rx="2.77877"
            fill={primaryColor}
            stroke={secondaryColor}
            strokeWidth="0.340426"
          />
          <path
            d="M1 10.2112C1 5.69894 4.65793 2.04102 9.17021 2.04102H24.8298C29.3421 2.04102 33 5.69894 33 10.2112V29.8873C33 32.5195 30.8662 34.6533 28.234 34.6533H5.76596C3.13379 34.6533 1 32.5195 1 29.8873V10.2112Z"
            fill={primaryColor}
            stroke={secondaryColor}
            strokeWidth="0.340426"
          />
          <path
            d="M1 9.17021C1 4.65793 4.65793 1 9.17021 1H24.8298C29.3421 1 33 4.65793 33 9.17021V28.8463C33 31.4785 30.8662 33.6122 28.234 33.6122H5.76596C3.13379 33.6122 1 31.4785 1 28.8463V9.17021Z"
            fill={primaryColor}
            stroke={secondaryColor}
            strokeWidth="0.340426"
          />
          <rect
            x="7.97881"
            y="29.2728"
            width="18.383"
            height="5.55753"
            rx="2.77877"
            fill={primaryColor}
            stroke={secondaryColor}
            strokeWidth="0.340426"
          />
        </svg>
      ) : (
        <>
          <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10.2112C1 5.69894 4.65793 2.04102 9.17021 2.04102H24.8298C29.3421 2.04102 33 5.69894 33 10.2112V29.8873C33 32.5195 30.8662 34.6533 28.234 34.6533H5.76596C3.13379 34.6533 1 32.5195 1 29.8873V10.2112Z" fill="#8C9097" />
            <path d="M1 9.17021C1 4.65793 4.65793 1 9.17021 1H24.8298C29.3421 1 33 4.65793 33 9.17021V28.8463C33 31.4785 30.8662 33.6122 28.234 33.6122H5.76596C3.13379 33.6122 1 31.4785 1 28.8463V9.17021Z" fill="#E6EAE9" stroke="#8C9097" stroke-width="0.340426" />
            <rect x="7.97881" y="29.2728" width="18.383" height="5.55753" rx="2.77877" fill="#E6EAE9" stroke="#8C9097" stroke-width="0.340426" />
            <path d="M30.6464 33.3536C30.8417 33.5488 31.1583 33.5488 31.3536 33.3536C31.5488 33.1583 31.5488 32.8417 31.3536 32.6464L30.6464 33.3536ZM2.14645 4.85355L30.6464 33.3536L31.3536 32.6464L2.85355 4.14645L2.14645 4.85355Z" fill="#8C9097" />
            <path d="M31.3536 4.85355C31.5488 4.65829 31.5488 4.34171 31.3536 4.14645C31.1583 3.95118 30.8417 3.95118 30.6464 4.14645L31.3536 4.85355ZM2.85355 33.3536L31.3536 4.85355L30.6464 4.14645L2.14645 32.6464L2.85355 33.3536Z" fill="#8C9097" />
          </svg>

        </>
      )}
    </>
  )
};

export default SvgIcon;
