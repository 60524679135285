import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export const OATextField = ({ InputProps, variant = 'filled', ...rest }: TextFieldProps) => {
  const customInputProps = {
    ...InputProps,
    onFocus: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {},
  };
  return <TextField {...rest} variant={variant} autoComplete="off" InputProps={customInputProps} />;
};
