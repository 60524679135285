import React, { FC } from 'react';
import { OAButton, OADayPicker, OASwipeableDrawer } from '../../../components';
import { Box, Grid, IconButton } from '@mui/material';
import DateInputField from './DateInputField';
import CancelIcon from '@mui/icons-material/Cancel';

interface DateDrawerProps {
  isOpen: boolean;
  title: string;
  localTravelDetail: any;
  selectedDate: any;
  selectedType: 'departure' | 'return';
  prices?: any;
  handleDateChange: (dt: any) => void;
  toggleDrawer: (value: any, isBottom?: boolean) => void;
  handleCancelReturn: () => void;
  handleApplyDate: () => void;
  handleReturn: () => void;
  setPrices: any;
  getFarePrice: any;
  orgCode?: string;
}
const DateDrawer: FC<DateDrawerProps> = ({
  isOpen,
  title,
  localTravelDetail,
  selectedDate,
  selectedType,
  prices,
  handleDateChange,
  toggleDrawer,
  handleCancelReturn,
  handleApplyDate,
  handleReturn,
  setPrices,
  getFarePrice,
  orgCode,
}) => {
  return (
    <OASwipeableDrawer
      title={title}
      open={isOpen}
      onOpen={() => toggleDrawer(null, true)}
      onClose={() => toggleDrawer(null, false)}
      height={90}
      isContainer={false}
    >
      <OADayPicker
        mode={localTravelDetail?.tripType === 'O' ? 'single' : 'range'}
        onDateSelect={handleDateChange}
        selected={selectedDate}
        prices={prices}
        orgCode={orgCode}
      />
      <Box
        position="sticky"
        sx={{
          bottom: 0,
          bgcolor: '#fff',
          borderTop: '1px solid #E0DFE1',
          p: '16px 20px 24px 20px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateInputField
              value={selectedDate?.from ? selectedDate?.from : selectedDate}
              label="departure"
              onClick={() => {
                const draft = {
                  cabinType: localTravelDetail?.cabinType,
                  travellerCount: localTravelDetail?.travellerCount,
                  from: localTravelDetail?.from,
                  to: localTravelDetail?.to,
                  isReturn: false,
                };
                getFarePrice(draft).then((res: any) => {
                  setPrices(res?.data?.data?.results);
                });
                toggleDrawer('departure', true);
              }}
              focused={selectedType === 'departure'}
              orgCode={orgCode}
            />
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ position: 'relative' }}>
              {selectedDate?.to && (
                <IconButton
                  onClick={handleCancelReturn}
                  disableRipple
                  sx={{
                    position: 'absolute',
                    top: orgCode === 'ZOLVE' ? 9 : 17,
                    right: 3,
                    zIndex: 1,
                    color: orgCode === 'ZOLVE' ? '#AFAFAF' : 'grey.800',
                  }}
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              )}
              <DateInputField
                value={selectedDate?.to ? selectedDate?.to : null}
                label="return"
                onClick={() => {
                  const draft = {
                    cabinType: localTravelDetail?.cabinType,
                    travellerCount: localTravelDetail?.travellerCount,
                    from: localTravelDetail?.from,
                    to: localTravelDetail?.to,
                    isReturn: true,
                  };
                  getFarePrice(draft).then((res: any) => {
                    setPrices(res?.data?.data?.results);
                  });
                  toggleDrawer('return', true);
                  handleReturn();
                }}
                focused={selectedType === 'return'}
                orgCode={orgCode}
              />
            </Box>
          </Grid>
        </Grid>
        <OAButton
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleApplyDate}
          sx={{ mt: '20px' }}
        >
          Select Date
        </OAButton>
      </Box>
    </OASwipeableDrawer>
  );
};

export default React.memo(DateDrawer);