import { FC } from 'react';
import { Avatar, SxProps, Theme } from '@mui/material';

interface OAAirlineLogoProps {
  airlineCode: string;
  sx?: SxProps<Theme>;
}
export const OAAirlineLogo: FC<OAAirlineLogoProps> = ({ airlineCode, sx }) => {
  const defaultSx: SxProps<Theme> = {
    width: '20px',
    height: '20px',
  };

  return (
    <Avatar
      sx={{ ...defaultSx, ...sx }}
      alt={airlineCode}
      src={`https://oa-flight-assets.s3.ap-south-1.amazonaws.com/airline-logos/${airlineCode}.svg`}
    />
  );
};
