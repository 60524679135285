export const NonVegIcon = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none">
    <g clip-path="url(#clip0_5496_6260)">
      <rect y="0.581421" width="12" height="11.7209" rx="2" fill="white"></rect>
      <rect
        y="0.581421"
        width="12"
        height="12"
        rx="2"
        fill="white"
        stroke="#CC1414"
        stroke-width="1.5"
      ></rect>
      <circle cx="6" cy="6.58142" r="3" fill="#CC1414"></circle>
    </g>
    <defs>
      <clipPath id="clip0_5496_6260">
        <rect y="0.581421" width="12" height="11.7209" rx="2" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
);
