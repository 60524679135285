import React from 'react';
import { Grid, Box } from '@mui/material';
import FlightCard from './FlightCard';
import { IFlight } from '../../type';
import { FixedSizeList } from 'react-window';
import { useSelector } from '../../store';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { getUserInfo } from '../../store/slices/userInfoSlice';
import { getAuthInfo } from '../../store/slices/authSlice';
import { getUserInputState } from '../../store/slices/userInputSlice';
interface FlightsListProps {
  flights: IFlight[];
  onFlightSelect: (flight: IFlight, type?: string) => void;
  tripType: 'R' | 'O';
  isInternational: boolean;
  selectedFlight: IFlight;
}

const style = {
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none', // Hides scrollbar for WebKit browsers (Chrome, Safari, etc.)
  },
  '-ms-overflow-style': 'none', // Hides scrollbar for IE and Edge
  'scrollbar-width': 'none', // Hides scrollbar for Firefox
};

const FlightsList: React.FC<FlightsListProps> = ({
  flights,
  onFlightSelect,
  tripType,
  isInternational,
}) => {
  const { isCoinsApplied } = useSelector(getUserInputState);
  const { coins } = useSelector(getUserInfo);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);
  const { campaigns } = useSelector(getAuthInfo);

  const Row = ({ index, style }: any) => {
    const flight = flights[index];
    return (
      <div style={style}>
        <Box key={flight?.id} sx={{ marginBottom: '15px' }}>
          <FlightCard
            data={flight}
            onClick={() => onFlightSelect(flight)}
            tripType={tripType}
            isInternational={isInternational}
          />
        </Box>
      </div>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ height: '100%', overflowY: 'auto' }} mb="35px">
        {isInternational && tripType === 'R' ? (
          <FixedSizeList
            style={style}
            height={1500}
            width="100%"
            itemSize={
              campaigns?.eligibleCampaigns?.length > 0 ||
              (isCoinsApplied && coins > 0 && burnCoinsFeat)
                ? 205
                : 175
            }
            itemCount={flights.length}
          >
            {Row}
          </FixedSizeList>
        ) : (
          flights?.map((flight) => (
            <Box key={flight?.id} sx={{ marginBottom: '15px' }}>
              <FlightCard
                data={flight}
                onClick={() => onFlightSelect(flight)}
                tripType={tripType}
                isInternational={isInternational}
              />
            </Box>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default FlightsList;
