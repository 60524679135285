import { Box, Skeleton, Typography } from '@mui/material';
import { convertStrToDate, getAirlineName, getFormatedDate } from '../../utils';
import { BE_DATE, FE_CARD_HEADER } from '../../constants';
import { OAAirlineLogo } from '../OAAirlineLogo';

const truncateString = (str: string, maxLength: number) => {
  return str.length > maxLength ? str.slice(0, maxLength - 3) + '...' : str;
};

const AirlineDetailAndDate = ({ data, loading = false }: { data: any; loading: boolean }) => {
  const airline = data?.airline;
  const departure: any = data?.departure?.date ? convertStrToDate(data?.departure?.date, BE_DATE) : null;
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <OAAirlineLogo airlineCode={airline?.code} />
        {loading ? (
          <Skeleton variant="text" width={100} />
        ) : (
          <Typography variant="body3" sx={{ ml: '5px', color: 'grey.800' }}>
            {airline?.name ? truncateString(getAirlineName(airline), 15) : ''} • {airline?.code}-
            {airline?.flightNumber}
          </Typography>
        )}
      </Box>
      {loading ? (
        <Skeleton variant="text" width={100} />
      ) : (
        <Typography variant="body3" sx={{ color: 'text.primary' }}>
          {data ? getFormatedDate(departure, FE_CARD_HEADER) : ''}
        </Typography>
      )}{' '}
    </Box>
  );
};

export default AirlineDetailAndDate;
