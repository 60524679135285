import { classifyDepartureTime } from "../../utils";

export const countSupplierValues = (responses: any[]): { [key: string]: number } => {
  const countMap: { [key: string]: number } = {};
  responses?.forEach((response) => {
    response?.segments?.forEach((segment: any) => {
      const supplier = segment?.airline?.name;
      if (supplier) {
        countMap[supplier] = (countMap[supplier] || 0) + 1;
      }
    });
  });
  return countMap;
};

export const countFlightStops = (responses: any[]): { [key: string]: number } => {
  const countMap: { [key: string]: number } = {};
  responses?.forEach((response) => {
    if ('stops' in response && 'value' in response.stops) {
      const stops = response.stops.value;
      countMap[stops] = (countMap[stops] || 0) + 1;
    }
  });
  return countMap;
};

export const countAirports = (response: any[]): { [key: string]: number } => {
  const countMap: { [key: string]: number } = {};
  response?.forEach((res) => {
    res?.segments?.forEach((segment: any) => {
      const departureAirport = segment?.departure?.airport?.name;
      if (departureAirport) {
        countMap[departureAirport] = (countMap[departureAirport] || 0) + 1;
      }

      const arrivalAirport = segment?.arrival?.airport?.name;
      if (arrivalAirport) {
        countMap[arrivalAirport] = (countMap[arrivalAirport] || 0) + 1;
      }
    });
  });
  return countMap;
};

export const countDeparture = (response: any[]): { [key: string]: number } => {
  const countMap: { [key: string]: number } = {};
  response?.forEach((res) => {
    res?.segments?.forEach((segment: any) => {
      const departureTime = segment?.departure?.time;
      if (departureTime) {
        countMap[classifyDepartureTime(departureTime)] = (countMap[classifyDepartureTime(departureTime)] || 0) + 1;
      }
    });
  });
  return countMap;      
}