import { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import {
  OAButton,
  OAFlightSummary,
  OAHeader,
  OAImage,
  OALoadingOverlay,
  OASwipeableDrawer,
} from '../../../components';
import { useDispatch, useSelector } from '../../../store';
import { getCancellationState } from '../../../store/slices/cancellationSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useCancelBookingMutation, useGetOrderByIdQuery } from '../../../services/bookingsApi';
import RefundBreakup from './RefundBreakup';
import { setMessage } from '../../../store/slices/snackbarSlice';
import { eventsTracker } from '../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { ISegment } from '../../../type';
import OAFlightRoute from '../../../components/OAFlightRoute';
import RefundHowItWork from './RefundHowItWork';

const Cancel = () => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const { cancellationData, selectedCancelReason } = useSelector(getCancellationState);
  const [cancelBooking, { isLoading }] = useCancelBookingMutation();
  const [startTime, setStartTime] = useState(Date.now());

  const navigate = useNavigate();
  const { orderId } = useParams();
  const { data } = useGetOrderByIdQuery<any>(orderId ?? '');
  const { refetch } = useGetOrderByIdQuery(orderId);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);
  const [isCancelConfirmed, setIsCancelConfirmed] = useState<boolean>(false);
  const cancellable = cancellationData?.cancellable;

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const toggleCancelAlert = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'CancelBooking',
        ctaAction: 'Cancel',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    setIsCancelDialogOpen(!isCancelDialogOpen);
  };

  const handleCancelFlight = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'CancelBooking',
        ctaAction: 'Confirm',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    const draft = {
      bookingId: data?.data?.id,
      selectedCancelReason: {
        reason: selectedCancelReason?.display,
        code: selectedCancelReason?.value,
      },
    };
    cancelBooking(draft).then((res: any) => {
      if (res?.error) {
        let errorMessage = null;
        if (res?.error?.data?.errors?.length > 0) {
          errorMessage = res?.error?.data?.errors?.map((obj: any) => obj?.message)?.join(', ');
        } else if (res?.error?.data?.errors?.length === 0 && res?.error?.data?.message) {
          errorMessage = res?.error?.data?.message;
        }
        dispatch(setMessage(errorMessage ?? 'Something went wrong, try again.'));
        refetch().then((res) => navigate(`/booking-status/${orderId}?overview`));
      } else {
        dispatch(
          setMessage(
            cancellable
              ? 'Your order has been cancelled'
              : 'Your order has been requested for cancellation'
          )
        );
        refetch().then((res) => navigate(`/booking-status/${orderId}?overview`));
      }
    });
  };

  const redirectToStatus = () => {
    setIsCancelConfirmed(false);
    refetch().then((res) => navigate(`/booking-status/${orderId}?overview`));
  };

  const onwardFlightSegments = data?.data?.flights?.find(
    (flight: any) => flight?.departureType === 'ONWARD'
  )?.segments;
  const returnFlightSegments = data?.data?.flights?.find(
    (flight: any) => flight?.departureType === 'RETURN'
  )?.segments;
  const onwardFlightLayovers = data?.data?.flights?.find(
    (flight: any) => flight?.departureType === 'ONWARD'
  )?.layovers;
  const returnFlightLayovers = data?.data?.flights?.find(
    (flight: any) => flight?.departureType === 'RETURN'
  )?.layovers;
  console.log('flight', onwardFlightSegments);

  return (
    <>
      {isLoading && <OALoadingOverlay />}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 100,
          bgcolor: 'background.paper',
        }}
      >
        <OAHeader title="Review & Cancel" />
      </Box>
      <Box mt="16px">
        {returnFlightSegments?.length > 0 && (
          <Container sx={{ mb: '10px', mt: '20px' }}>
            <OAFlightRoute
              from={data?.data?.flightSearchInfo?.from?.city}
              to={data?.data?.flightSearchInfo?.to?.city}
              icon="flight-take-off.svg"
            />
          </Container>
        )}
        {onwardFlightSegments?.map((segment: ISegment) => (
          <Box mb="16px" key={segment?.id}>
            <OAFlightSummary
              data={segment}
              layovers={onwardFlightLayovers}
              isConfirmItinerary={true}
            />
          </Box>
        ))}
        {returnFlightSegments?.length > 0 && (
          <Container sx={{ mb: '10px', mt: '20px' }}>
            <OAFlightRoute
              from={data?.data?.flightSearchInfo?.to?.city}
              to={data?.data?.flightSearchInfo?.from?.city}
              icon="flight-land.svg"
            />
          </Container>
        )}
        {returnFlightSegments?.map((segment: ISegment) => (
          <Box mb="16px" key={segment?.id}>
            <OAFlightSummary
              data={segment}
              layovers={returnFlightLayovers}
              isConfirmItinerary={true}
            />
          </Box>
        ))}
      </Box>

      <Box mt="20px" px="16px" mb="80px">
        <Typography fontWeight={600} mb="15px">
          {cancellable ? 'Refund breakup' : 'How this works'}
        </Typography>
        {cancellable ? (
          <RefundBreakup data={data} cancellationData={cancellationData} />
        ) : (
          <RefundHowItWork />
        )}
      </Box>

      <Box px="16px" position="fixed" bottom="0" pb="16px" left="0" right="0">
        <OAButton
          variant="contained"
          color="secondary"
          sx={{ width: '100%' }}
          onClick={toggleCancelAlert}
          fullWidth
        >
          {cancellable ? 'Cancel Ticket' : 'Request Cancellation'}
        </OAButton>
      </Box>
      <OASwipeableDrawer
        title={<OAImage src="error-icon.svg" folder="icons" alt="error" />}
        open={isCancelDialogOpen}
        onClose={toggleCancelAlert}
        onOpen={toggleCancelAlert}
        isContainer={false}
      >
        <Box px="16px" mb="16px">
          <Typography variant="h6">Confirm Cancellation?</Typography>
          <Typography>You are cancelling your flight</Typography>
          {!cancellable && (
            <Typography mt="10px">
              Our support team will contact you shortly to assist you further via
              phone/email/whatsapp
            </Typography>
          )}
          <Box mt="30px">
            <OAButton onClick={handleCancelFlight} variant="contained" color="secondary" fullWidth>
              Confirm
            </OAButton>
          </Box>
        </Box>
      </OASwipeableDrawer>
      <OASwipeableDrawer
        title={<OAImage src="success-icon.svg" folder="icons" alt="error" />}
        open={isCancelConfirmed}
        onClose={() => {}}
        onOpen={() => {}}
        isContainer={false}
        isError={true}
      >
        <Box px="16px" mb="16px">
          <Typography variant="h6">Cancellation requested successfully!</Typography>
          <Typography variant="body2" mt="10px">
            Your flight cancellation request has been received
          </Typography>
          <Typography variant="body2" mt="8px">
            Our support team will contact you shortly to assist you further via phone/email/whatsapp
          </Typography>
          <Box mt="30px">
            <OAButton onClick={redirectToStatus} variant="contained" color="secondary" fullWidth>
              Okay
            </OAButton>
          </Box>
        </Box>
      </OASwipeableDrawer>
    </>
  );
};

export default Cancel;
